import React, {Component} from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withAuth0 } from '@auth0/auth0-react';
import EditMealDataForm from "../components/editMealDataForm";

import {
    getAllFoodTypesWithOutChildren,
} from '../actions/foodTypes';

import {
    getMealData,
    updateMealData,
    deleteMealData,
    getKjMealData,
    resetMealDataSuccessState
} from '../actions/mealsData';

import {
    Container,
    Row,
    Col, Alert
} from "react-bootstrap";
import Spinner from "../components/spinner"


class EditMealData extends Component {
    constructor(props) {
        super(props);
        // default state
        this.state = {
            mealDataUpdateSuccess: false,
        };
    }

    async componentDidMount(): void{
        console.log(this.props);
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getMealData(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getKjMealData(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        // this.props.getAllDayEvents(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getAllFoodTypesWithOutChildren(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.updateMealDataSuccess && prevState.mealDataUpdateSuccess === false) {
            this.setState({ mealDataUpdateSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    async deleteMealData (id) {
        console.log(id);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.deleteMealData(await getAccessTokenSilently(), id);
        this.props.push(`/meal/${this.props.mealData.mealId}`); // might need to get value before delete
    }

    async updateMealData(id, state) {
        console.log('update');
        console.log(state);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.updateMealData(await getAccessTokenSilently(), id, state, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    cancelEdit() {
        console.log('cancel');
        this.props.push(`/meal/${this.props.mealData.mealId}`);
    }

    showForm() {
        if (this.props.isFetchingMealData || this.props.isFetchingAllFoodTypes ) {
            return (<Spinner/>);
        } else if (this.props.mealData) {
            return (
                <div>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Edit MealData #{this.props.mealData.id}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EditMealDataForm
                                mealData={this.props.mealData}
                                kjMealData={this.props.kjMealData}
                                isFetchingKjMealData={this.props.isFetchingKjMealData}
                                deleteMealData={(id) => this.deleteMealData(id)}
                                updateMealData={(id, state) => this.updateMealData(id, state)}
                                cancelEdit = {() => this.cancelEdit()}
                                allFoodTypes = {this.props.allFoodTypes}
                            />
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (<div>error</div>);
        }

    }

    async hideMessages() {
        console.log("hideMessagesThree");
        this.setState({
            mealDataUpdateSuccess: false,
        });
        this.props.resetMealDataSuccessState();
        const {getAccessTokenSilently} = this.props.auth0;  //TODO uncertain does need?
        this.props.getKjMealData(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    render() {
        console.log("butfuck");
        console.log(this.props);
        let editSuccess = <div/>;
        if (this.state.mealDataUpdateSuccess) {
            editSuccess = <Alert variant='success' >Update Success</Alert>;
        }
        return (
            <Container>
                { this.showForm() }
                <Row style={{ paddingTop: '1em' }}>
                    <Col>
                        { editSuccess }
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ mealData, allFoodTypes  }, ownProps) => ({
   allFoodTypes: allFoodTypes.allFoodTypes,
    isFetchingAllFoodTypes: allFoodTypes.isFetchingAllFoodTypes,

    kjMealData: mealData.kjMealData,
    reloadKjMealData: mealData.reloadKjMealData,
    isFetchingKjMealData: mealData.isFetchingKjMealData,
    mealData: mealData.mealData,
    isFetchingMealData: mealData.isFetchingMealData,
    updateMealDataSuccess: mealData.updateMealDataSuccess
});

export default withAuth0(connect(mapStateToProps, {
    push,
    // getAllDayEvents,
    getAllFoodTypesWithOutChildren,
    getKjMealData,
    getMealData,
    updateMealData,
    deleteMealData,
    resetMealDataSuccessState
})(EditMealData));
