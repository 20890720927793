import {
    GET_KJ_CONSUMPTION_DATA_REQUEST,
    GET_KJ_CONSUMPTION_DATA_RECEIVE,
    GET_KJ_CONSUMPTION_DATA_ERROR,

    GET_CONSUMPTION_DATA_REQUEST,
    GET_CONSUMPTION_DATA_RECEIVE,
    GET_CONSUMPTION_DATA_ERROR,

    ADD_CONSUMPTION_DATA_REQUEST,
    ADD_CONSUMPTION_DATA_RECEIVE,
    ADD_CONSUMPTION_DATA_ERROR,

    DELETE_CONSUMPTION_DATA_REQUEST,
    DELETE_CONSUMPTION_DATA_RECEIVE,
    DELETE_CONSUMPTION_DATA_ERROR,

    UPDATE_CONSUMPTION_DATA_REQUEST,
    UPDATE_CONSUMPTION_DATA_RECEIVE,
    UPDATE_CONSUMPTION_DATA_ERROR,

    CONSUMPTION_DATA_CONFIRM_RESET,

    GET_CONSUMPTION_DATA_KJ_SUMMARY_REQUEST,
    GET_CONSUMPTION_DATA_KJ_SUMMARY_RECEIVE,
    GET_CONSUMPTION_DATA_KJ_SUMMARY_ERROR,

    GET_CONSUMPTION_DATA_SUMMARY_REQUEST,
    GET_CONSUMPTION_DATA_SUMMARY_RECEIVE,
    GET_CONSUMPTION_DATA_SUMMARY_ERROR,

    GET_CONSUMPTION_DAYS_WITH_DATA_REQUEST,
    GET_CONSUMPTION_DAYS_WITH_DATA_RECEIVE,
    GET_CONSUMPTION_DAYS_WITH_DATA_ERROR,
} from '../constants/ActionTypes';


// Reload variable only needed because following another components state indirectly!!!
const initialState = {
    isFetchingKjConsumptionData: false,
    kjConsumptionData: null,
    errorKjConsumptionData: null,

    isFetchingConsumptionData: false,
    consumptionData: null,
    errorConsumptionData: null,
    
    isAdding: false,
    addConsumptionDataSuccess: false,
    errorAddConsumptionDataSuccess: null,

    isDeleting: false,
    deleteConsumptionDataSuccess: false,
    errorDeleteConsumptionDataSuccess: null,

    isUpdating: false,
    updateConsumptionDataSuccess: false,
    errorUpdateConsumptionDataSuccess: null,

    isFetchingConsumptionDataSummary: false,
    consumptionDataSummary: null,
    reloadConsumptionDataSummary: false,
    errorConsumptionDataSummary: null,

    isFetchingConsumptionDataKjSummary: false,
    consumptionDataKjSummary: null,
    reloadConsumptionDataKjSummary: false,
    errorConsumptionDataKjSummary: null,

    isFetchingConsumptionDaysWithData: false,
    consumptionDaysWithData: null,
    reloadConsumptionDaysWithData: false,
    errorConsumptionDaysWithData: null,


};


export default (state = initialState, { type, payload, error }) => {
    switch (type) {

        case GET_KJ_CONSUMPTION_DATA_REQUEST:
            return {
                ...state,
                isFetchingKjConsumptionData: true,
            };
        case GET_KJ_CONSUMPTION_DATA_RECEIVE:
            return {
                ...state,
                isFetchingKjConsumptionData: false,
                kjConsumptionData: payload,
            };
        case GET_KJ_CONSUMPTION_DATA_ERROR:
            return {
                ...state,
                // Should have isDeleting and isAdding too? :/
                isFetchingKjConsumptionData: false,
                kjConsumptionData: null,
                errorKjConsumptionData: error,
            };

        case GET_CONSUMPTION_DATA_REQUEST:
            return {
                ...state,
                isFetchingConsumptionData: true,
            };
        case GET_CONSUMPTION_DATA_RECEIVE:
            return {
                ...state,
                isFetchingConsumptionData: false,
                consumptionData: payload,
            };
        case GET_CONSUMPTION_DATA_ERROR:
            return {
                ...state,
                // Should have isDeleting and isAdding too? :/
                isFetchingConsumptionData: false,
                consumptionData: null,
                errorConsumptionData: error,
            };

        case ADD_CONSUMPTION_DATA_REQUEST:
            return {
                ...state,
                isAdding: true,
            };
        case ADD_CONSUMPTION_DATA_RECEIVE:
            return {
                ...state,
                isAdding: false,
                addConsumptionDataSuccess: true,
            };
        case ADD_CONSUMPTION_DATA_ERROR:
            return {
                ...state,
                isAdding: false,
                addConsumptionDataSuccess: false,
                errorAddConsumptionDataSuccess: error,
            };

        case DELETE_CONSUMPTION_DATA_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_CONSUMPTION_DATA_RECEIVE:
            return {
                ...state,
                isDeleting: false,
                deleteConsumptionDataSuccess: true,
            };
        case DELETE_CONSUMPTION_DATA_ERROR:
            return {
                ...state,
                isDeleting: false,
                deleteConsumptionDataSuccess: false,
                errorDeleteConsumptionDataSuccess: error,
            };

        case UPDATE_CONSUMPTION_DATA_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case UPDATE_CONSUMPTION_DATA_RECEIVE:
            return {
                ...state,
                isUpdating: false,
                consumptionData: payload,
                updateConsumptionDataSuccess: true,
            };
        case UPDATE_CONSUMPTION_DATA_ERROR:
            return {
                ...state,
                isUpdating: false,
                consumptionData: null,
                errorUpdateConsumptionDataSuccess: error,
            };

        case GET_CONSUMPTION_DATA_SUMMARY_REQUEST:
            return {
                ...state,
                isFetchingConsumptionDataSummary: true,
            };
        case GET_CONSUMPTION_DATA_SUMMARY_RECEIVE:
            return {
                ...state,
                isFetchingConsumptionDataSummary: false,
                reloadConsumptionDataSummary: false,
                consumptionDataSummary: payload,
            };
        case GET_CONSUMPTION_DATA_SUMMARY_ERROR:
            return {
                ...state,
                // Should have isDeleting and isAdding too? :/
                isFetchingConsumptionDataSummary: false,
                reloadConsumptionDataSummary: false,
                consumptionDataSummary: null,
                errorConsumptionDataSummary: error,
            };

        case GET_CONSUMPTION_DATA_KJ_SUMMARY_REQUEST:
            return {
                ...state,
                isFetchingConsumptionDataKjSummary: true,
            };
        case GET_CONSUMPTION_DATA_KJ_SUMMARY_RECEIVE:
            return {
                ...state,
                isFetchingConsumptionDataKjSummary: false,
                reloadConsumptionDataKjSummary: false,
                consumptionDataKjSummary: payload,
            };
        case GET_CONSUMPTION_DATA_KJ_SUMMARY_ERROR:
            return {
                ...state,
                // Should have isDeleting and isAdding too? :/
                isFetchingConsumptionDataKjSummary: false,
                reloadConsumptionDataKjSummary: false,
                consumptionDataKjSummary: null,
                errorConsumptionDataKjSummary: error,
            };



        case GET_CONSUMPTION_DAYS_WITH_DATA_REQUEST:
            return {
                ...state,
                isFetchingConsumptionDaysWithData: true,
            };
        case GET_CONSUMPTION_DAYS_WITH_DATA_RECEIVE:
            return {
                ...state,
                isFetchingConsumptionDaysWithData: false,
                reloadConsumptionDaysWithData: false,
                consumptionDaysWithData: payload,
            };
        case GET_CONSUMPTION_DAYS_WITH_DATA_ERROR:
            return {
                ...state,
                isFetchingConsumptionDaysWithData: false,
                reloadConsumptionDaysWithData: false,
                consumptionDaysWithData: null,
                errorConsumptionDaysWithData: error,
            };





        case CONSUMPTION_DATA_CONFIRM_RESET:
            return {
                ...state,
                addConsumptionDataSuccess: false,
                deleteConsumptionDataSuccess: false,
                updateConsumptionDataSuccess: false,
                reloadConsumptionDataSummary: true,
                reloadConsumptionDataKjSummary: true,
                reloadConsumptionDaysWithData: true
            };

        default:
            return state;
    }
};
