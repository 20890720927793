import React, {Component} from 'react';
import { connect } from 'react-redux';
import EditEventTypeForm from '../components/editEventTypeForm';
import { push } from 'connected-react-router';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getContainer,
    deleteContainer,
    updateContainer,
    resetContainerSuccessState
} from '../actions/containers';

import {
    Container,
    Row,
    Col, Alert
} from "react-bootstrap";
import PropTypes from "prop-types";
import Spinner from "../components/spinner"
import EditContainerForm from "../components/editContainerForm";

class EditContainer extends Component {
    constructor(props) {
        super(props);
        // default state
        this.state = {
            containerUpdateSuccess: false,
        };
    }

    async componentDidMount(): void{
        console.log(this.props);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.getContainer(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        console.log(this.props.container);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.updateContainerSuccess && prevState.containerUpdateSuccess === false) {
            this.setState({ containerUpdateSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    async deleteContainer(id) {
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.deleteContainer(await getAccessTokenSilently(), id);
        this.props.push(`/containers`);
    }

    async updateContainer(id, state) {
        console.log('update');
        console.log(state);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.updateContainer(await getAccessTokenSilently(), id, state);
    }

    cancelEdit() {
        console.log('cancel');
        this.props.push(`/containers`);
    }

    showForm() {
        if (this.props.isFetching) {
            return (<Spinner/>);
        } else if (this.props.container) {
            return (
                <div>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Edit EventType #{this.props.container.id}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EditContainerForm
                                container={this.props.container}
                                deleteContainer={(id) => this.deleteContainer(id)}
                                updateContainer={(id, state) => this.updateContainer(id, state)}
                                cancelEdit = {() => this.cancelEdit()}
                            />
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (<div>error</div>);
        }

    }

    hideMessages() {
        this.setState({
            containerUpdateSuccess: false,
        });
        this.props.resetContainerSuccessState();
    }

    render() {
        let editSuccess = <div/>;
        if (this.state.containerUpdateSuccess) {
            editSuccess = <Alert variant='success' >Update Success</Alert>;
        }
        return (
            <Container>
                { this.showForm() }
                <Row style={{ paddingTop: '1em' }}>
                    <Col>
                        { editSuccess }
                    </Col>
                </Row>
            </Container>
        );
    }
}

EditContainer.propTypes = {
    getContainer: PropTypes.func.isRequired,
    deleteContainer: PropTypes.func.isRequired,
    updateContainer: PropTypes.func.isRequired,
    resetContainerSuccessState: PropTypes.func.isRequired
}

const mapStateToProps = ({ container }, ownProps) => ({
    container: container.container,
    isFetching: container.isFetching,
    updateContainerSuccess: container.updateContainerSuccess
});

export default withAuth0(connect(mapStateToProps, {
    push,
    getContainer,
    deleteContainer,
    updateContainer,
    resetContainerSuccessState,
})(EditContainer));
