import React, {Component} from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withAuth0 } from "@auth0/auth0-react";

import {
    addMeal,
} from '../actions/meals';

import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import PropTypes from "prop-types";
import AddMealForm from "../components/addMealForm";


class AddMeal extends Component {

    async addMeal(state) {
        const {getAccessTokenSilently} = this.props.auth0;

        console.log('add');
        console.log(state);
        this.props.addMeal(await getAccessTokenSilently(), state);
        this.props.push(`/meals`);
    }

    cancelAdd() {
        console.log('cancel');
        this.props.push(`/meals`);
    }

    render() {
        return (
            <div>
                <Container>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Add Meal
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AddMealForm
                                addMeal={(state) => this.addMeal(state)}
                                cancelAdd = {() => this.cancelAdd()}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

AddMeal.propTypes = {
    addMeal: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({
});

export default withAuth0(connect(mapStateToProps, {
    push,
    addMeal,
})(AddMeal));
