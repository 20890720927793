import {
    ALL_MEALS_REQUEST,
    ALL_MEALS_RECEIVE,
    ALL_MEALS_ERROR,
    ADD_MEAL_REQUEST,
    ADD_MEAL_RECEIVE,
    ADD_MEAL_ERROR,
} from '../constants/ActionTypes';

const initialState = {
    allMeals: null,
    meal: null,
    error: null,

    isFetching: false,
    isAdding: null,

    addMealSuccess: false,
};

export default (state = initialState, { type, payload, error }) => {
    console.log("Meals-Doinka");
    switch (type) {
        case ALL_MEALS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case ALL_MEALS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                allMeals: payload,
            };
        case ALL_MEALS_ERROR:
            return {
                ...state,
                isFetching: false,
                error,
            };

        case ADD_MEAL_REQUEST:
            return {
                ...state,
                isAdding: true,
            };
        case ADD_MEAL_RECEIVE:
            return {
                ...state,
                isAdding: false,
                addMealSuccess: true,
            };
        case ADD_MEAL_ERROR:
            return {
                ...state,
                isAdding: false,
                meal: null,
                error,
            };

        default:
            return state;
    }
};
