import React from 'react';

export default () =>
    <div>
        <span>
            Oh snap.... <br />
            You made the pony sad :( <br />
            there is nothing here. <br />
            Try again <br />
        </span>
    </div>;