import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import allFoodTypes from './allFoodTypes';
import allMeals from "./allMeals";
import allDayEvents from './allDayEvents';
import allContainers from './allContainers';
import allConsumptionData from "./allConsumptionData";
import foodType from './foodType';
import foodTypeGroups from './foodTypeGroups';
import foodTypeGroup from './foodTypeGroup'
import dayEvent from './dayEvent';
import meal from './meal';
import mealData from './mealData';
import consumptionData from './consumptionData';
import dayBrowser from "./dayBrowser";
import container from "./containers"
import userSettings from "./userSettings";

const createRootReducer = (history) => combineReducers({
    allDayEvents,
    allMeals,
    allFoodTypes,
    allContainers,
    allConsumptionData,
    foodType,
    foodTypeGroups,
    foodTypeGroup,
    dayEvent,
    meal,
    mealData,
    consumptionData,
    dayBrowser,
    container,
    userSettings,
    router: connectRouter(history)
})

export default createRootReducer;