import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, NavItem,  Nav, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

class HeaderNavBar extends Component {

    render() {
        let leftNavBar = (<Nav className="mr-auto">
            <Button
                variant="link"
                style={{ color: '#FFFFFF'}}
                onClick={() => this.props.login()}
            >Login</Button>
        </Nav>);
        if (this.props.loggedIn) {
            leftNavBar = (<Nav className="mr-auto">
                <LinkContainer eventkey={1} to="/">
                    <NavItem className="nav-link">home</NavItem>
                </LinkContainer>
                <LinkContainer eventkey={1} to="/dayLogs">
                    <NavItem className="nav-link">DayLogs</NavItem>
                </LinkContainer>
                <LinkContainer eventkey={2} to="/foodItems">
                    <NavItem className="nav-link">FoodItems</NavItem>
                </LinkContainer>
                <LinkContainer eventkey={3} to="/meals">
                    <NavItem className="nav-link">Meals</NavItem>
                </LinkContainer>
                <LinkContainer eventkey={4} to="/dayEvents">
                    <NavItem className="nav-link">DayEvents</NavItem>
                </LinkContainer>
                <LinkContainer eventkey={5} to="/foodTypeGroups">
                    <NavItem className="nav-link">FoodTypeGroups</NavItem>
                </LinkContainer>
                <LinkContainer eventkey={5} to="/containers">
                    <NavItem className="nav-link">Containers</NavItem>
                </LinkContainer>
                <LinkContainer eventkey={6} to="/userSettings">
                    <NavItem className="nav-link">Profile</NavItem>
                </LinkContainer>
                <LinkContainer eventkey={7} to="/shitface">
                    <NavItem className="nav-link">broken link</NavItem>
                </LinkContainer>
            </Nav>);
        }

        let rightNavBar = (<div />);
        if (this.props.loggedIn) {
            rightNavBar = (<Nav>
                <span>
                    <img
                        src={ this.props.userImage }
                        style={{ height: '40px', width: '40px' }}
                    />
                    <Button
                        variant="link"
                        style={{ color: '#FFFFFF'}}
                        onClick={() => this.props.logout()}
                    >Logout</Button>
                </span>
            </Nav>);
        }


        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand>
                    <img
                        alt=""
                        src={`${process.env.PUBLIC_URL}/logo192.png`}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    Kj MooPONYMOO!!!
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    { leftNavBar }
                    { rightNavBar }
                </Navbar.Collapse>
            </Navbar>);
    }
}

HeaderNavBar.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    userImage: PropTypes.string.isRequired
}

export default HeaderNavBar;