import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Table, Alert, Button, Col, Row, Container} from 'react-bootstrap'
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getAllMeals, resetMealSuccessState
} from '../actions/meals';
import MealTableRow from "../components/mealTableRow";
import Spinner from "../components/spinner";

class Meals extends Component {
    constructor() {
        super();
        this.state = {
            mealDeleteSuccess: false,
            mealAddSuccess: false
        };
    }

    async componentDidMount() {
        console.log("wtf is happening?")
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getAllMeals(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        console.log('deleteMealSucces?')
        console.log(this.props.deleteMealSuccess)
        console.log(prevState.mealDeleteSuccess)
        if (this.props.deleteMealSuccess && prevState.mealDeleteSuccess === false) {
            this.setState({ mealDeleteSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }

        if (this.props.addMealSuccess && prevState.mealAddSuccess === false) {
            this.setState({ mealAddSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    openMeal (id) {
        console.log(id);
        this.props.push(`/meal/${id}`);
    }

    addMeal (id) {
        console.log(id);
        this.props.push(`/meals/add`);
    }

    showResults() {
        let meals = (<div>nothing</div>);
        if (this.props.isFetching) {
            meals = (<Spinner/>);
        } else if (this.props.allMeals) {
            meals = (
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>TotalWeight</th>
                        <th>Date added</th>
                        <th>Date updated</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.props.allMeals.map((meal) => (
                        <MealTableRow
                            meal={meal}
                            key={meal.id}
                            buttonFunc={() => this.openMeal(meal.id)}
                            buttonText={'Open'}
                        />)) }
                    </tbody>
                </Table>
            )

        }
        return meals;
    }

    async hideMessages() {
        this.setState({
            mealDeleteSuccess: false,
            mealAddSuccess: false,
        });
        this.props.resetMealSuccessState();
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getAllMeals(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    render() {
        console.log(this.props);
        let deleteSuccess = <div/>;
        if (this.state.mealDeleteSuccess) {
            deleteSuccess = <Alert  variant='success' >Delete Success</Alert>;
        }

        let addSuccess = <div/>;
        if (this.state.mealAddSuccess) {
            addSuccess = <Alert  variant='success' >Add Success</Alert>;
        }

        return (
            <div>
                <Container>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Meals
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ padding: '0.75em', borderTop: '1px solid #dee2e6' }}>
                                <Button
                                    onClick={e => {this.addMeal()}}
                                >
                                    Add new Meal
                                </Button>
                            </div>
                            {this.showResults()}
                            <div style={{ padding: '0.75em', borderTop: '1px solid #dee2e6' }}>
                                <Button
                                    onClick={e => {this.addMeal()}}
                                >
                                    Add new Meal
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '1em' }}>
                        <Col>
                            {deleteSuccess}
                            {addSuccess}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Meals.propTypes = {
    getAllMeals: PropTypes.func.isRequired,
    resetMealSuccessState: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}

const mapStateToProps = ({ allMeals, meal }, ownProps) => ({
    allMeals: allMeals.allMeals,
    isFetching: allMeals.isFetching,
    addMealSuccess: meal.addMealSuccess,
    deleteMealSuccess: meal.deleteMealSuccess,
});

export default withAuth0(connect(mapStateToProps, {
    getAllMeals,
    resetMealSuccessState,
    push
})(Meals));
