import {
    ALL_CONSUMPTION_DATA_REQUEST,
    ALL_CONSUMPTION_DATA_RECEIVE,
    ALL_CONSUMPTION_DATA_ERROR,

    ALL_KJ_CONSUMPTION_DATA_REQUEST,
    ALL_KJ_CONSUMPTION_DATA_RECEIVE,
    ALL_KJ_CONSUMPTION_DATA_ERROR,
} from '../constants/ActionTypes';

const initialState = {
    isFetching: false,
    allConsumptionData: null,
    error: null,

    isFetchingKjConsumptionData: false,
    allKjConsumptionData: null,
    errorKjConsumptionData: null
};


export default (state = initialState, { type, payload, error }) => {
    switch (type) {
        case ALL_CONSUMPTION_DATA_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case ALL_CONSUMPTION_DATA_RECEIVE:
            return {
                ...state,
                isFetching: false,
                allConsumptionData: payload,
                error: null
            };
        case ALL_CONSUMPTION_DATA_ERROR:
            return {
                ...state,
                isFetching: false,
                allConsumptionData: null,
                error,
            };

        case ALL_KJ_CONSUMPTION_DATA_REQUEST:
            return {
                ...state,
                isFetchingKjConsumptionData: true,
            };
        case ALL_KJ_CONSUMPTION_DATA_RECEIVE:
            return {
                ...state,
                isFetchingKjConsumptionData: false,
                allKjConsumptionData: payload,
                errorKjConsumptionData: null
            };
        case ALL_KJ_CONSUMPTION_DATA_ERROR:
            return {
                ...state,
                isFetchingKjConsumptionData: false,
                allKjConsumptionData: null,
                errorKjConsumptionData: error
            };
        default:
            return state;
    }
};
