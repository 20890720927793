import React, {Component} from 'react';
import { connect } from 'react-redux';
import EditUserSettingsTypeForm from '../components/editUserSettingsTypeForm';
import { push } from 'connected-react-router';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getUserSettings,
    updateUserSettings,
    resetUserSettingsSuccessState
} from '../actions/userSettings';

import {
    Container,
    Row,
    Col, Alert
} from "react-bootstrap";
import PropTypes from "prop-types";
import Spinner from "../components/spinner"

class UserSetting extends Component {
    constructor(props) {
        super(props);
        // default state
        this.state = {
            userSettingsUpdateSuccess: false,
        };
    }

    async componentDidMount(): void{
        console.log(this.props);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.getUserSettings(await getAccessTokenSilently());
        console.log(this.props.userSettings);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        console.log('mootest1');
        console.log(this.props.updateUserSettingsSuccess);
        console.log(prevState.userSettingsUpdateSuccess);
        console.log('mootest1.1');
        if (this.props.updateUserSettingsSuccess && prevState.userSettingsUpdateSuccess === false) {
            console.log('mootest2');
            this.setState({ userSettingsUpdateSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    async updateUserSettings(state) {
        console.log('update');
        console.log(state);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.updateUserSettings(await getAccessTokenSilently(), state);
    }

    showForm() {
        if (this.props.isFetching) {
            return (<Spinner/>);
        } else if (this.props.userSettings) {
            return (
                <div>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Profile
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EditUserSettingsTypeForm
                                userSettings={this.props.userSettings}
                                updateUserSettings={(state) => this.updateUserSettings(state)}
                            />
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (<div>error</div>);
        }

    }

    hideMessages() {
        console.log('mootest3');
        this.setState({
            userSettingsUpdateSuccess: false,
        });
        this.props.resetUserSettingsSuccessState();
    }

    render() {
        let editSuccess = <div/>;
        if (this.state.userSettingsUpdateSuccess) {
            editSuccess = <Alert variant='success' >Update Success</Alert>;
        }
        return (
            <Container>
                { this.showForm() }
                <Row style={{ paddingTop: '1em' }}>
                    <Col>
                        { editSuccess }
                    </Col>
                </Row>
            </Container>
        );
    }
}

UserSetting.propTypes = {
    getUserSettings: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    resetUserSettingsSuccessState: PropTypes.func.isRequired
}

const mapStateToProps = ({ userSettings }, ownProps) => ({
    userSettings: userSettings.userSettings,
    isFetching: userSettings.isFetching,
    updateUserSettingsSuccess: userSettings.updateUserSettingsSuccess
});

export default withAuth0(connect(mapStateToProps, {
    push,
    getUserSettings,
    updateUserSettings,
    resetUserSettingsSuccessState,
})(UserSetting));
