import {applyMiddleware, createStore} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
// WTF createMiddleware DOESN"T FUCKING WORK!!! FFS
import { apiMiddleware } from 'redux-api-middleware';
import thunkMiddleware from 'redux-thunk'

import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import monitorReducersEnhancer from './enhancers/monitorReducers'
//import loggerMiddleware from './middleware/logger'
import logger from 'redux-logger'
import createRootReducer  from './reducers'

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
    const middlewares = [routerMiddleware(history), apiMiddleware, thunkMiddleware, logger]
    //console.log(middlewares);
    // if (process.env.NODE_ENV === 'development') {
    //     middlewares.push(secretMiddleware)
    // }
    const middlewareEnhancer = applyMiddleware(...middlewares)
    //console.log(middlewareEnhancer);

    const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
    const composedEnhancers = composeWithDevTools(...enhancers)

    const store = createStore(createRootReducer(history), preloadedState, composedEnhancers)
    console.log(store);

    // if (process.env.NODE_ENV !== 'production' && module.hot) {
    //     module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
    // }

    return store;
}

