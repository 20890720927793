import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap'

const FoodTypeGroupTableRow = ({ FoodTypeGroup, editFoodTypeGroup }) =>
    <tr style={{ fontSize: '1em', padding: '0.25em' }}>
        <td style={{ color: '#18605A' }}>
            {FoodTypeGroup.foodTypeGroupId}
        </td>
        <td>
            {FoodTypeGroup.name}
        </td>
        <td>
            {FoodTypeGroup.description}
        </td>
        <td>
            {FoodTypeGroup.createdAt}
        </td>
        <td>
            {FoodTypeGroup.updatedAt}
        </td>
        <td>
            <Button onClick={editFoodTypeGroup}>Edit</Button>
        </td>
    </tr>;

FoodTypeGroupTableRow.propTypes = {
    FoodTypeGroup: PropTypes.object.isRequired,
    editFoodTypeGroup: PropTypes.func.isRequired,
};

export default FoodTypeGroupTableRow;
