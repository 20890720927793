import React from 'react';
import { render } from 'react-dom'
import './index.css';
import "bootstrap-slider/dist/css/bootstrap-slider.css"
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from 'react-redux';
import App from './containers/App'
import configureStore, { history } from './configureStore'
import { Auth0Provider } from "@auth0/auth0-react";

import { ConnectedRouter} from 'connected-react-router'

// connectRouter, routerMiddleware,

console.log(process.env);
console.log(window.location.origin);

const store = configureStore()

const renderApp = () =>
    render(
        <Auth0Provider
            domain={`${process.env.REACT_APP_DOMAIN}`}
            clientId={`${process.env.REACT_APP_CLIENT_ID}`}
            redirectUri={window.location.origin.toLowerCase()} // toLowerCase needed for firefox because it insistes on upper case Localhost, and this breaks Auth0
            audience={`${process.env.REACT_APP_AUDIENCE}`}
            //scope="read:messages"
        >
            <Provider store={store}>
                {/* ConnectedRouter will use the store from Provider automatically */}
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>
        </Auth0Provider>,
        document.getElementById('root')
    )


// if (process.env.NODE_ENV !== 'production' && module.hot) {
//     module.hot.accept('./containers/App', renderApp)
// }

renderApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
