import React, {Component} from 'react';
import { connect } from 'react-redux';
import EditFoodItemForm from '../components/editFoodTypeForm';
import { push } from 'connected-react-router';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getFoodType,
    deleteFoodType,
    updateFoodType,
    updateFoodTypeGroups,
    resetFoodTypeSuccessState
} from '../actions/foodTypes';

import {
    getFoodTypeGroups
} from '../actions/foodTypeGroup';

import {
    Container,
    Row,
    Col, Alert
} from "react-bootstrap";
import PropTypes from "prop-types";
import Spinner from "../components/spinner"

class EditFoodItem extends Component {
    constructor(props) {
        super(props);
        // default state
        this.state = {
            foodItemUpdateSuccess: false,
        };
    }

    async componentDidMount(): void{
        console.log(this.props);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.getFoodType(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        await this.props.getFoodTypeGroups(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        console.log(this.props.foodType);
        console.log(this.props.foodTypeGroups);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.updateFoodTypeSuccess && prevState.foodItemUpdateSuccess === false) {
            this.setState({ foodItemUpdateSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    async deleteFoodItem(id) {
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.deleteFoodType(await getAccessTokenSilently(), id);
        this.props.push(`/foodItems`);
    }

    async updateFoodItem(id, state) {
        console.log('update');
        console.log(state);
        const returnState = {};
        returnState.foodItemsId = state.foodItemsId;
        returnState.createdAt = state.createdAt;
        returnState.updatedAt = state.updatedAt;
        returnState.name = state.name;
        returnState.kjPerHundredGrams = state.kjPerHundredGrams;
        returnState.kjPerServe = state.kjPerServe;
        returnState.category = state.category;
        returnState.description = state.description;

        const returnFoodItemFoodTypeGroups = [];
        state.foodGroupTypeIds.forEach((foodGroupTypeId, index) => {
            const join = {};
            join.foodItemsId = state.foodItemsId;
            join.foodTypeGroupId = parseInt(foodGroupTypeId);
            returnFoodItemFoodTypeGroups[index] = join;
        })

        console.log(returnState);
        console.log(returnFoodItemFoodTypeGroups);

        const {getAccessTokenSilently} = this.props.auth0;
        this.props.updateFoodType(await getAccessTokenSilently(), id, returnState);
        this.props.updateFoodTypeGroups(await getAccessTokenSilently(), id, returnFoodItemFoodTypeGroups)
    }

    cancelEdit() {
        console.log('cancel');
        this.props.push(`/foodItems`);
    }

    showForm() {
        if ( this.props.isFetchingFoodType || this.props.isFetchingFoodTypeGroups ) {
            return (<Spinner/>);
        } else if ( this.props.foodType && this.props.foodTypeGroups ) {
            return (
                <div>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Edit FoodItem #{this.props.foodType.foodItemsId}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EditFoodItemForm
                                foodType={this.props.foodType}
                                foodTypeGroups={this.props.foodTypeGroups}
                                deleteFoodItem={(id) => this.deleteFoodItem(id)}
                                updateFoodItem={(id, state) => this.updateFoodItem(id, state)}
                                cancelEdit = {() => this.cancelEdit()}
                            />
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (<div>error</div>);
        }

    }

    async hideMessages() {
        this.setState({
            foodItemUpdateSuccess: false,
        });
        this.props.resetFoodTypeSuccessState();
        // Refresh data so that badges will rerender
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.getFoodType(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    render() {
        let editSuccess = <div/>;
        if (this.state.foodItemUpdateSuccess) {
            editSuccess = <Alert variant='success' >Update Success</Alert>;
        }
        return (
            <Container>
                { this.showForm() }
                <Row style={{ paddingTop: '1em' }}>
                    <Col>
                        { editSuccess }
                    </Col>
                </Row>
            </Container>
        );
    }
}

EditFoodItem.propTypes = {
    getFoodTypeGroups: PropTypes.func.isRequired,
    getFoodType: PropTypes.func.isRequired,
    deleteFoodType: PropTypes.func.isRequired,
    updateFoodType: PropTypes.func.isRequired,
    updateFoodTypeGroups: PropTypes.func.isRequired,
    resetFoodTypeSuccessState: PropTypes.func.isRequired
}

const mapStateToProps = ({ foodType, foodTypeGroups }, ownProps) => ({
    foodType: foodType.foodType,
    isFetchingFoodType: foodType.isFetching,
    updateFoodTypeSuccess: foodType.updateFoodTypeSuccess,
    isFetchingFoodTypeGroups: foodTypeGroups.isFetching,
    foodTypeGroups: foodTypeGroups.foodTypeGroups
});

export default withAuth0(connect(mapStateToProps, {
    push,
    getFoodType,
    getFoodTypeGroups,
    deleteFoodType,
    updateFoodType,
    updateFoodTypeGroups,
    resetFoodTypeSuccessState
})(EditFoodItem));
