import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Table, Alert, Button, Col, Row, Container} from 'react-bootstrap'
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getAllFoodTypesWithChildren,
    resetFoodTypeSuccessState
} from '../actions/foodTypes';
import FoodItemTableRow from "../components/foodItemTableRow";
import Spinner from "../components/spinner";

class Moo1 extends Component {
    constructor() {
        super();
        this.state = {
            foodItemDeleteSuccess: false,
            foodItemAddSuccess: false
        };
    }

    async componentDidMount() {
        console.log("wtf is happening?")
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getAllFoodTypesWithChildren(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.deleteFoodTypeSuccess && prevState.foodItemDeleteSuccess === false) {
            this.setState({ foodItemDeleteSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }

        if (this.props.addFoodTypeSuccess && prevState.foodItemAddSuccess === false) {
            this.setState({ foodItemAddSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    addFoodItem () {
        this.props.push(`/foodItems/add`);
    }

    editFoodItem (id) {
        console.log(id);
        this.props.push(`/foodItems/edit/${id}`);
    }

    showResults() {
        let foodTypes = (<div>nothing</div>);
        if (this.props.isFetching) {
            foodTypes = (<Spinner/>);
        } else if (this.props.allFoodTypes) {
            foodTypes = (
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>FoodGroups</th>
                        <th>kj/100g</th>
                        <th>kj/serve</th>
                        <th>Date added</th>
                        <th>Date updated</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.props.allFoodTypes.map((foodType) => (
                        <FoodItemTableRow
                            FoodItem={foodType}
                            key={foodType.foodItemsId}
                            editFoodItem={() => this.editFoodItem(foodType.foodItemsId)}
                        />)) }
                    </tbody>
                </Table>
            )

        }
        return foodTypes;
    }

    async hideMessages() {
        this.setState({
            foodItemDeleteSuccess: false,
            foodItemAddSuccess: false,
        });
        this.props.resetFoodTypeSuccessState();
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getAllFoodTypesWithChildren(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    render() {
        console.log(this.props);
        let deleteSuccess = <div/>;
        if (this.state.foodItemDeleteSuccess) {
            deleteSuccess = <Alert  variant='success' >Delete Success</Alert>;
        }

        let addSuccess = <div/>;
        if (this.state.foodItemAddSuccess) {
            addSuccess = <Alert  variant='success' >Add Success</Alert>;
        }

        return (
            <div>
                <Container>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                FoodItems
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ padding: '0.75em', borderTop: '1px solid #dee2e6' }}>
                                <Button
                                    onClick={e => {this.addFoodItem()}}
                                >
                                    Add new FoodItem
                                </Button>
                            </div>
                            {this.showResults()}
                            <div style={{ padding: '0.75em', borderTop: '1px solid #dee2e6' }}>
                                <Button
                                    onClick={e => {this.addFoodItem()}}
                                >
                                    Add new FoodItem
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '1em' }}>
                        <Col>
                            {deleteSuccess}
                            {addSuccess}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Moo1.propTypes = {
    getAllFoodTypesWithChildren: PropTypes.func.isRequired,
    resetFoodTypeSuccessState: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}

const mapStateToProps = ({ allFoodTypes, foodType }, ownProps) => ({
    allFoodTypes: allFoodTypes.allFoodTypesChildren,
    isFetching: allFoodTypes.isFetchingAllFoodTypesChildren,
    deleteFoodTypeSuccess: foodType.deleteFoodTypeSuccess,
    addFoodTypeSuccess: foodType.addFoodTypeSuccess
});

export default withAuth0(connect(mapStateToProps, {
    push,
    getAllFoodTypesWithChildren,
    resetFoodTypeSuccessState

})(Moo1));
