import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
    Form,
    Container,
    Row,
    Col,
    Button,
} from 'react-bootstrap';
import DropdownOption from "./DropdownOption";

class EditMealForm extends Component {
    constructor(props) {
        console.log(props);

        // Load saved state or a default state when page loaded
        super(props);

        this.state = {
            id: props.meal.id,
            name: props.meal.name,
            totalWeight: props.meal.totalWeight,
            foodTypeId: props.meal.foodTypeId,
            createdAt: props.meal.createdAt,
            updatedAt: props.meal.updatedAt,
            containerId: -1
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // This is to make sure the updatedAt indicates the latest update.
        const meal = this.props.meal;
        if (meal.updatedAt !== this.state.updatedAt) this.setState({updatedAt: meal.updatedAt})
    }

    updateMeal() {
        const localState = this.state;
        let containerWeight = {};
        if (localState.ContainerId != -1) {
            console.log('hit');
            containerWeight = this.props.allContainers.filter(container => {
                return container.id === localState.ContainerId;
            });
        }
        console.log(containerWeight);
        if (containerWeight[0]) {
            localState.totalWeight = localState.totalWeight - containerWeight[0].totalWeight;
        }
        if (localState.totalWeight < 0) {
            localState.totalWeight = 0;
        }
        console.log(localState);

        this.props.updateMeal(localState.id, localState);
    }

    deleteMeal(id) {
        console.log('delete' + id)
        this.props.deleteMeal(id)
    }

    cancelEdit() {
        this.props.cancelEdit()
    }

    render() {
        console.log(this.state);
        return (
            <Container>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.updateMeal();
                    }}
                >
                    <Form.Group as={Row} controlId="name" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Name
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.name}
                                onChange={e => {
                                    this.setState({ name: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="description" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Total Weight
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.totalWeight}
                                onChange={e => {
                                    this.setState({ totalWeight: parseInt(e.target.value) });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Row style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Group as={Col} sm={{ span: 5, offset: 2 }} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="createdAt">
                            <Form.Label>Created at</Form.Label>
                            <Form.Control
                                type="text"
                                value={this.state.createdAt.substring(0, 19)}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={{ span: 5}} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="updatedAt">
                            <Form.Label>Updated at</Form.Label>
                            <Form.Control
                                type="text"
                                // On update this damn string always gets extra accuracy added, debugging the C# API shows no added accuracy, have no idea :(
                                value={this.state.updatedAt.substring(0, 19)}
                                disabled
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group
                        as={Row}
                        controlId="container"
                        style={{ margin: '0.2em', paddingTop: '0.5em' }}
                    >
                        <Form.Label column sm={2}>
                            Container
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                as="select"
                                onChange={e => {
                                    const localState = this.state;
                                    localState.ContainerId = parseInt(e.target.value);
                                    console.log(parseInt(e.target.value));
                                    this.setState(localState);
                                }}
                                value={this.state.ContainerId}
                            >
                                <DropdownOption key={-1} value={-1} label="NoContainer" />
                                {this.props.allContainers.map(
                                    (container) =>
                                        <DropdownOption
                                            key={container.id}
                                            value={container.id}
                                            label={container.name}
                                        />
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} style={{ margin: '0.2em', paddingTop: '1em' }}>
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit">Update</Button>
                            <Button
                                variant="danger"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.deleteMeal(this.state.id);
                                    console.log('delete');
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="secondary"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.cancelEdit();
                                    console.log('return');
                                }}
                            >
                                Return
                            </Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

EditMealForm.propTypes = {
    dayEvent: PropTypes.object.isRequired,
    deleteDayEvent: PropTypes.func.isRequired,
    updateDayEvent: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
}

export default (EditMealForm);