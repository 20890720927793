import React, { Component } from 'react';
import PropTypes, {object} from 'prop-types'

import {
    Form,
    Container,
    Row,
    Col,
    Button,
} from 'react-bootstrap';


import DropdownOption from './DropdownOption';

import {ReactBootstrapSlider} from "react-bootstrap-slider";

class AddMealConsumptionDataForm extends Component {
    constructor(props) {
        console.log(props);

        // Load saved state or a default state when page loaded
        super(props);

        this.state = {
            WeightMg: null,
            FoodItemId: null,
            Note: '',
            // This means no FoodGroup is selected.
            FoodGroupId: -1,
            ContainerId: -1,
        };
    }

    addConsumptionData(foodItemId) {
        const localState = this.state;
        let containerWeight = {};
        if (localState.ContainerId != -1) {
            console.log('hit');
            containerWeight = this.props.allContainers.filter(container => {
                return container.id === localState.ContainerId;
            });
        }
        console.log(containerWeight);
        if (containerWeight[0]) {
            localState.WeightMg = localState.WeightMg - containerWeight[0].totalWeight;
        }
        if (localState.WeightMg < 0) {
            localState.WeightMg = 0;
        }
        localState.FoodItemId = parseInt(foodItemId);
        // The id for Container is added but the API ignores it :)
        this.props.addConsumptionData(localState);
    }

    cancelAdd() {
        this.props.cancelAdd()
    }

    render() {
        console.log(this.state);


        return (
            <Container>
                <Form
                    onSubmit={(e) => {
                        const foodTypeId = e.target[2].value;
                        e.preventDefault();
                        this.addConsumptionData(foodTypeId);
                    }}
                >
                    <Form.Group
                        as={Row}
                        controlId="kjPerHundredGrams"
                        key={ this.state.ServesEnabled }
                        style={{ margin: '0.2em', paddingTop: '0.5em', display: this.state.ServesEnabled ? 'hidden' : ''}}
                    >
                        <Form.Label column sm={4}>
                            Weight (gm)
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.WeightMg}
                                onChange={e => {
                                    // TODO add some checking around types!!!
                                    this.setState({ WeightMg: parseInt(e.target.value) });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}  controlId="foodGroup" style={{ margin: '0.2em', paddingTop: '0.5em' }}>

                        <Form.Label column sm={4}>
                            Food Group
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="select"
                                onChange={e => {
                                    const localState = this.state;
                                    localState.FoodGroupId = parseInt(e.target.value);
                                    console.log(parseInt(e.target.value));
                                    this.setState(localState);
                                }}
                                value={this.state.FoodGroupId}
                            >
                                <DropdownOption key={-1} value={-1} label="" />
                                {this.props.foodTypeGroups.map(
                                    (foodTypeGroup) =>
                                        <DropdownOption
                                            key={foodTypeGroup.foodTypeGroupId}
                                            value={foodTypeGroup.foodTypeGroupId}
                                            label={foodTypeGroup.name}
                                        />
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}  controlId="foodItem" style={{ margin: '0.2em', paddingTop: '0.5em' }}>

                        <Form.Label column sm={4}>
                            Food Type
                        </Form.Label>
                        <Col sm={8}>
                            {/* This onChange won't work because the selection is updated in a render cycle. */}
                            {/* This data will be collected on submit dynamically.  Yes it is gross :( */}
                            <Form.Control
                                as="select"
                                value={this.state.FoodItemId}
                                onChange={e => {
                                    const localState = this.state;
                                    localState.FoodItemId = parseInt(e.target.value);
                                    this.setState(localState);
                                }}
                                required
                            >
                                {this.props.allFoodTypes.filter((foodType) => {
                                    if (this.state.FoodGroupId === -1) {
                                        return foodType;
                                    } else {
                                        return foodType.foodTypeGroups.some((foodTypeGroup) => {
                                            return this.state.FoodGroupId === foodTypeGroup.foodTypeGroupId;
                                        });
                                    }
                                }).map(
                                    (foodType) =>
                                        <DropdownOption
                                            key={foodType.foodItemsId}
                                            value={foodType.foodItemsId}
                                            label={foodType.name}
                                        />
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="note" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={4}>
                            Note
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="textarea"
                                rows="5"
                                defaultValue={this.state.Note}
                                onChange={e => {
                                    this.setState({ Note: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}  controlId="container" style={{ margin: '0.2em', paddingTop: '0.5em' }}>

                        <Form.Label column sm={4}>
                            Container
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="select"
                                onChange={e => {
                                    const localState = this.state;
                                    localState.ContainerId = parseInt(e.target.value);
                                    console.log(parseInt(e.target.value));
                                    this.setState(localState);
                                }}
                                value={this.state.ContainerId}
                            >
                                <DropdownOption key={-1} value={-1} label="NoContainer" />
                                {this.props.allContainers.map(
                                    (container) =>
                                        <DropdownOption
                                            key={container.id}
                                            value={container.id}
                                            label={container.name}
                                        />
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} style={{ margin: '0.2em', paddingTop: '1em' }}>
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit">Add</Button>
                            <Button
                                variant="secondary"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.cancelAdd();
                                    console.log('return');
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

AddMealConsumptionDataForm.propTypes = {
    addConsumptionData: PropTypes.func.isRequired,
    allDayEvents: PropTypes.arrayOf(object).isRequired,
    allFoodTypes: PropTypes.arrayOf(object).isRequired,
    foodTypeGroups: PropTypes.arrayOf(object).isRequired
}

export default (AddMealConsumptionDataForm);