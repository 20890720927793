import React, {Component} from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getMeal,
    updateMeal,
    deleteMeal,
    resetMealSuccessState
} from '../actions/meals';


import {
    getAllContainers
} from "../actions/containers";

import {
    Container,
    Row,
    Col, Alert
} from "react-bootstrap";
import Spinner from "../components/spinner"
import EditMealForm from "../components/editMealForm";


class EditMeal extends Component {
    constructor(props) {
        super(props);
        // default state
        this.state = {
            mealUpdateSuccess: false,
        };
    }

    async componentDidMount(): void{
        console.log(this.props);
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getMeal(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getAllContainers(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);

    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.updateMealSuccess && prevState.mealUpdateSuccess === false) {
            this.setState({ mealUpdateSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    async deleteMeal(id) {
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.deleteMeal(await getAccessTokenSilently(), id);
        this.props.push(`/meals`);
    }

    async updateMeal(id, state) {
        console.log('update');
        console.log(state);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.updateMeal(await getAccessTokenSilently(), id, state, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    cancelEdit() {
        console.log('cancel');
        this.props.push(`/meal/${this.props.match.params.id}`);
    }

    showForm() {
        if (this.props.isFetchingMeal ||
            this.props.isFetchingAllContainers) {
            return (<Spinner/>);
        } else if (
            this.props.meal &&
            this.props.allContainers
        ) {
            return (
                <div>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Edit Meal #{this.props.meal.id}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EditMealForm
                                meal = {this.props.meal}
                                allContainers = {this.props.allContainers}
                                deleteMeal={(id) => this.deleteMeal(id)}
                                updateMeal={(id, state) => this.updateMeal(id, state)}
                                cancelEdit = {() => this.cancelEdit()}
                            />
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (<div>error</div>);
        }

    }

    async hideMessages() {
        this.setState({
            mealUpdateSuccess: false,
        });
        this.props.resetMealSuccessState();
        // Hmmmm, maybe instead we redirect to meal page and it shows message?
        // const {getAccessTokenSilently} = this.props.auth0;
        // this.props.getKjConsumptionData(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    render() {
        console.log("butfuck");
        console.log(this.props);
        let editSuccess = <div/>;
        if (this.state.mealUpdateSuccess) {
            editSuccess = <Alert variant='success' >Update Success</Alert>;
        }
        return (
            <Container>
                { this.showForm() }
                <Row style={{ paddingTop: '1em' }}>
                    <Col>
                        { editSuccess }
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ meal, allContainers }, ownProps) => ({
    meal: meal.meal,
    allContainers: allContainers.allContainers,
    isFetchingAllContainers: allContainers.isFetching,
    isFetchingMeal: meal.isFetchingMeal,
    isUpdatingMeal: meal.isUpdating,
    updateMealSuccess: meal.updateMealSuccess,

});

export default withAuth0(connect(mapStateToProps, {
    push,
    getMeal,
    getAllContainers,
    updateMeal,
    deleteMeal,
    resetMealSuccessState
})(EditMeal));
