import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import {Button, Col, Row, Table} from "react-bootstrap";
import Spinner from "./spinner";
import ConsumptionDataTableRow from "./consumptionDataTableRow";

class DayDataView extends Component {
    constructor(props) {
        console.log(props);
        // Load saved state or a default state when page loaded
        super(props);

        this.state = {
            date: this.props.date
        };
    }

    showResults() {
        let foodTypes = (<div>nothing</div>);
        if (this.props.isFetching) {
            foodTypes = (<Spinner/>);
        } else if (
            this.props.allConsumptionData &&
            this.props.allFoodTypes &&
            this.props.allDayEvents &&
            this.props.allKjConsumptionData
        ) {
            foodTypes = (
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>DateTime</th>
                        <th>FoodName</th>
                        <th>DayEvent</th>
                        <th>WeightMg</th>
                        <th>Serves</th>
                        <th>Note</th>
                        <th>Kj</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.props.allConsumptionData.map((consumptionData) => (
                        <ConsumptionDataTableRow
                            ConsumptionData={consumptionData}
                            key={consumptionData.id}
                            FoodType={this.props.allFoodTypes.filter(foodType => { return foodType.foodItemsId === consumptionData.foodItemId})}
                            DayEvent={this.props.allDayEvents.filter(dayEvent => { return dayEvent.id === consumptionData.dayEventId})}
                            kjData={this.props.allKjConsumptionData.filter(kjData => { return kjData.id === consumptionData.id})}
                            editConsumptionData={() => this.props.editConsumptionData(consumptionData.id)}
                            deleteConsumptionData={() => this.props.deleteConsumptionData(consumptionData.id)}
                        />)) }
                    </tbody>
                </Table>
            )

        }
        return foodTypes;
    }

    render() {
        console.log(this.state);
        console.log("dado1");
        console.log(this.props.allKjConsumptionData)
        console.log("dado2");
        let dateSelector = (<div>nothing</div>);
        const rangetest = [];
        if (this.props.isFetching) {
            dateSelector = (<Spinner/>);
        } else if (this.props.consumptionDaysWithData) {
            this.props.consumptionDaysWithData.forEach((date) => {
                rangetest.push(new Date(date));
            })
            const highlightWithRanges = [
                {
                    "react-datepicker__day--highlighted react-datepicker__day--today": [
                        ...rangetest
                    ],
                },
            ];

            console.log(highlightWithRanges)
            dateSelector = <DatePicker
                selected={this.state.date}
                highlightDates={highlightWithRanges}
                onChange={date => {
                    this.setState({date: date});
                    this.props.retrieveDateData(date);
                }} />
        }

        return (
            <div>
                <Row>
                    <Col>
                        <div style={{ padding: '1em', float: 'left', borderTop: '1px solid #dee2e6' }}>
                            {dateSelector}
                        </div>
                        <div style={{ padding: '0.75em', float: 'left', borderTop: '1px solid #dee2e6' }}>
                            <Button
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    let date = new Date()
                                    this.setState({date: date});
                                    this.props.retrieveDateData(date);
                                }}
                            >
                                Reset Date
                            </Button>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.showResults()}
                    </Col>
                </Row>
            </div>);
    };
}


export default (DayDataView);