import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Button} from 'react-bootstrap'

const FoodItemTableRow = ({ FoodItem, editFoodItem }) =>
    <tr style={{ fontSize: '1em', padding: '0.25em' }}>
        <td style={{ color: '#18605A' }}>
            {FoodItem.foodItemsId}
        </td>
        <td>
            {FoodItem.name}
        </td>
        <td>
            {FoodItem.description}
        </td>
        <td>
            {FoodItem.foodTypeGroups.map(foodTypeGroup => (
                <Badge
                    pill
                    variant="primary"
                    key={ foodTypeGroup.foodTypeGroupId}
                    style={{ fontSize: '1em', marginRight: '1em', marginTop: '0.5em' }}
                >
                        { foodTypeGroup.name }
                </Badge>
            ))}
        </td>
        <td>
            {FoodItem.kjPerHundredGrams}
        </td>
        <td>
            {FoodItem.kjPerServe}
        </td>
        <td>
            {FoodItem.createdAt}
        </td>
        <td>
            {FoodItem.updatedAt}
        </td>
        <td>
            <Button onClick={editFoodItem}>Edit</Button>
        </td>
    </tr>;

FoodItemTableRow.propTypes = {
    FoodItem: PropTypes.object.isRequired,
    editFoodItem: PropTypes.func.isRequired,
};

export default FoodItemTableRow;
