import {
    GET_MEAL_DATA_REQUEST,
    GET_MEAL_DATA_RECEIVE,
    GET_MEAL_DATA_ERROR,

    ADD_MEAL_DATA_REQUEST,
    ADD_MEAL_DATA_RECEIVE,
    ADD_MEAL_DATA_ERROR,

    DELETE_MEAL_DATA_REQUEST,
    DELETE_MEAL_DATA_RECEIVE,
    DELETE_MEAL_DATA_ERROR,

    UPDATE_MEAL_DATA_REQUEST,
    UPDATE_MEAL_DATA_RECEIVE,
    UPDATE_MEAL_DATA_ERROR,

    GET_MEAL_DATA_BY_MEALID_REQUEST,
    GET_MEAL_DATA_BY_MEALID_RECEIVE,
    GET_MEAL_DATA_BY_MEALID_ERROR,

    GET_MEAL_DATA_KJ_BY_MEALID_REQUEST,
    GET_MEAL_DATA_KJ_BY_MEALID_RECEIVE,
    GET_MEAL_DATA_KJ_BY_MEALID_ERROR,

    GET_KJ_MEAL_DATA_REQUEST,
    GET_KJ_MEAL_DATA_RECEIVE,
    GET_KJ_MEAL_DATA_ERROR,

    GET_MEAL_KJ_RECEIVE,
    GET_MEAL_KJ_ERROR,

    MEAL_DATA_CONFIRM_RESET
} from '../constants/ActionTypes';


// Reload variable only needed because following another components state indirectly!!!
const initialState = {
    isFetchingMealDataById: false,
    mealDataById: null,
    reloadMealDataById: false,
    errorMealDataById: null,

    isFetchingMealDataKjById: false,
    mealDataKjById: null,
    reloadMealDataKjById: false,
    errorMealDataKjById: null,

    isFetchingMealData: false,
    mealData: null,
    errorMealData: null,

    isAdding: false,
    addMealDataSuccess: false,
    errorAddMealDataSuccess: null,

    isUpdating:false,
    updateMealDataSuccess: false,
    errorUpdateMealDataSuccess: null,

    isFetchingKjMealData:false,
    kjMealData: null,
    reloadKjMealData: false,
    errorKjMealData: null,


    isFetchingMealDataSummary: false,
    mealDataSummary: null,
    reloadMealDataSummary: false,
    errorMealDataSummary: null,

    isDeleting: null,
    deleteMealDataSuccess: false,
    errorDeleteMealDataSuccess: false,
};


const mealData = (state = initialState, { type, payload, error }) => {
    switch (type) {

        case GET_KJ_MEAL_DATA_REQUEST:
            return {
                ...state,
                isFetchingKjMealData: true,
            };
        case GET_KJ_MEAL_DATA_RECEIVE:
            return {
                ...state,
                isFetchingKjMealData: false,
                reloadKjMealData: false,
                kjMealData: payload,
            };
        case GET_KJ_MEAL_DATA_ERROR:
            return {
                ...state,
                isFetchingKjMealData: false,
                kjMealData: null,
                reloadKjMealData: false,
                errorKjMealData: error,
            };

        case UPDATE_MEAL_DATA_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case UPDATE_MEAL_DATA_RECEIVE:
            return {
                ...state,
                isUpdating: false,
                mealData: payload,
                updateMealDataSuccess: true,
            };
        case UPDATE_MEAL_DATA_ERROR:
            return {
                ...state,
                isUpdating: false,
                mealData: null,
                errorUpdateMealDataSuccess: error,
            };

        case GET_MEAL_DATA_REQUEST:
            return {
                ...state,
                isFetchingMealData: true,
            };
        case GET_MEAL_DATA_RECEIVE:
            return {
                ...state,
                isFetchingMealData: false,
                mealData: payload,
            };
        case GET_MEAL_DATA_ERROR:
            return {
                ...state,
                isFetchingMealData: false,
                mealData: null,
                errorMealData: error,
            };

        case ADD_MEAL_DATA_REQUEST:
            console.log('ADD_MEAL_DATA_REQUEST');
            return {
                ...state,
                isAdding: true,
            };
        case ADD_MEAL_DATA_RECEIVE:
            console.log('ADD_MEAL_DATA_RECEIVE2');
            console.log({
                ...state,
                isAdding: false,
                addMealDataSuccess: true,
            })
            console.log('ADD_MEAL_DATA_RECEIVE3');
            return {
                ...state,
                isAdding: false,
                addMealDataSuccess: true,
            };
        case ADD_MEAL_DATA_ERROR:
            return {
                ...state,
                isAdding: false,
                addMealDataSuccess: false,
                errorAddMealDataSuccess: error,
            };

        case DELETE_MEAL_DATA_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_MEAL_DATA_RECEIVE:
            return {
                ...state,
                isDeleting: false,
                deleteMealDataSuccess: true,
            };
        case DELETE_MEAL_DATA_ERROR:
            return {
                ...state,
                isDeleting: false,
                meal: null,
                errorDeleteMealDataSuccess: error,
            };

        case GET_MEAL_DATA_BY_MEALID_REQUEST:
            return {
                ...state,
                isFetchingMealDataById: true,
            };
        case GET_MEAL_DATA_BY_MEALID_RECEIVE:
            return {
                ...state,
                isFetchingMealDataById: false,
                reloadMealDataById: false,
                mealDataById: payload,
            };
        case GET_MEAL_DATA_BY_MEALID_ERROR:
            return {
                ...state,
                isFetchingMealDataById: false,
                reloadMealDataById: false,
                mealDataById: null,
                errorMealDataById: error,
            };

        case GET_MEAL_DATA_KJ_BY_MEALID_REQUEST:
            return {
                ...state,
                isFetchingMealDataKjById: true,
            };
        case GET_MEAL_DATA_KJ_BY_MEALID_RECEIVE:
            return {
                ...state,
                isFetchingMealDataKjById: false,
                reloadMealDataKjById: false,
                mealDataKjById: payload,
            };
        case GET_MEAL_DATA_KJ_BY_MEALID_ERROR:
            return {
                ...state,
                isFetchingMealDataKjById: false,
                reloadMealDataKjById: false,
                mealDataKjById: null,
                errorMealDataKjById: error,
            };

        // This is to avoid lop.
        case GET_MEAL_KJ_RECEIVE:
        case GET_MEAL_KJ_ERROR:
            return {
                ...state,
                reloadMealKjSummary: false
            }

        case MEAL_DATA_CONFIRM_RESET:
            return {
                ...state,
                addMealDataSuccess: false,
                deleteMealDataSuccess: false,
                updateMealDataSuccess: false,
                reloadMealKjSummary: true,  //This reloads the kj summary for meals
                reloadKjMealData: true,
                reloadMealDataById: true,
                reloadMealDataKjById: true,
            };

        default:
            return state;
    }
};

export default mealData;
