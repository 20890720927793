import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap'

const DayEventTableRow = ({ DayEvent, editDayEvent }) =>
    <tr style={{ fontSize: '1em', padding: '0.25em' }}>
        <td style={{ color: '#18605A' }}>
            {DayEvent.id}
        </td>
        <td>
            {DayEvent.name}
        </td>
        <td>
            {DayEvent.description}
        </td>
        <td>
            {DayEvent.createdAt}
        </td>
        <td>
            {DayEvent.updatedAt}
        </td>
        <td>
            <Button onClick={editDayEvent}>Edit</Button>
        </td>
    </tr>;

DayEventTableRow.propTypes = {
    DayEvent: PropTypes.object.isRequired,
    editDayEvent: PropTypes.func.isRequired,
};

export default DayEventTableRow;
