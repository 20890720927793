import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Table, Alert, Button, Col, Row, Container} from 'react-bootstrap'
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { withAuth0 } from '@auth0/auth0-react';
import Spinner from "../components/spinner";

import MealTableRow from "../components/mealTableRow";
import AddConsumptionData from "./AddConsumptionData";
import MealDataTableRow from "../components/mealDataTableRow";

import {
    getMeal,
    getMealKj,
    updateMeal,
    resyncMeal,
    resetMealSuccessState
} from '../actions/meals';

import {
    getFoodType,
    getAllFoodTypesWithOutChildren,
    resetFoodTypeSuccessState,
} from '../actions/foodTypes';

import {
    getMealDataByMealId,
    getMealDataKjByMealId,
    deleteMealData,
    resetMealDataSuccessState,
} from "../actions/mealsData";

class Meal extends Component {
    constructor() {
        super();
        this.state = {
            mealDataDeleteSuccess: false,
            mealUpdateSuccess: false,
            foodTypeAddSuccess: false,
            mealResyncSuccess: false
        };
    }

    async componentDidMount() {
        console.log("wtf is happening?")
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getMeal(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getMealKj(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getAllFoodTypesWithOutChildren(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getMealDataByMealId(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getMealDataKjByMealId(await getAccessTokenSilently(), this.props.match.params.id);
    }

    async componentDidUpdate(prevProps, prevState, nextContent) {
        const {getAccessTokenSilently} = this.props.auth0;
        if (this.props.deleteMealDataSuccess && prevState.mealDataDeleteSuccess === false) {
            this.setState({ mealDataDeleteSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }

        // meal is only set true if meal needs to reload
        if (this.props.updateMealSuccess && prevState.mealUpdateSuccess === false) {
            this.setState({ mealUpdateSuccess: true });
            setTimeout(() => { this.hideMessages(true); }, 2000);
        }

        if (this.props.addFoodTypeSuccess && prevState.foodTypeAddSuccess === false) {
            this.setState({ foodTypeAddSuccess: true });
            setTimeout(() => { this.hideMessages(true); }, 2000);
        }

        if (this.props.resyncMealSuccess && prevState.mealResyncSuccess === false) {
            this.setState({ mealResyncSuccess: true });
            setTimeout(() => { this.hideMessages(true); }, 2000);
        }

        // reloadMeal
        if (this.props.reloadMeal === true && this.props.isFetchingMeal === false) {
            this.props.getMeal(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        }

        if (this.props.reloadMealKjSummary === true && this.props.isFetchingMealKj === false) {
            this.props.getMealKj(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        }

        if (this.props.reloadMealDataById === true && this.props.isFetchingMealDataById === false) {
            this.props.getMealDataByMealId(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        }

        if (this.props.reloadMealDataKjById === true && this.props.isFetchingMealDataKjById === false) {
            this.props.getMealDataKjByMealId(await getAccessTokenSilently(), this.props.match.params.id);
        }
    }


    editMeal (id) {
        console.log(id);
        this.props.push(`/meals/edit/${id}`);
    }

    editMealData (id) {
        console.log(id);
        this.props.push(`/mealData/edit/${id}`);
    }

    async deleteMealData (id) {
        console.log(id);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.deleteMealData(await getAccessTokenSilently(), id);

    }

    async syncMeal (id) {
        console.log(id);
        this.props.push(`/meal/migrate/${id}`);
    }

    async resyncMeal (id) {
        console.log(id);
        // Doing resync in API is easiest.
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.resyncMeal(await getAccessTokenSilently(), id);
    }

    round(num, decimalPlaces = 0) {
        var p = Math.pow(10, decimalPlaces);
        var n = (num * p) * (1 + Number.EPSILON);
        return Math.round(n) / p;
    }

    async desyncMeal (id) {
        console.log(id);
        let state = this.props.meal;
        state.foodTypeId = 0;
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.updateMeal(await getAccessTokenSilently(), id, state, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    returnToMeals () {
        this.props.push(`/meals`);
    }

    showResults() {
        let kj = (<div>loading</div>);
        if (this.props.isFetchingMealKj) {
            kj = (<Spinner/>);
        } else if (this.props.mealKj) {
            kj = `${this.props.mealKj.energyTotal}kj`;
        }

        let mealDataTableBody = (<div>loading</div>);
        if (this.props.isFetchingMealDataById || this.props.isFetchingAllFoodTypes || this.props.isFetchingMealDataKjById) {
            mealDataTableBody = (<Spinner/>);
        } else if (this.props.mealDataById && this.props.mealDataKjById) {
            mealDataTableBody = (<tbody>
            {this.props.mealDataById.map((mealData) => (
                <MealDataTableRow
                    MealData={mealData}
                    key={mealData.id}
                    FoodType={this.props.allFoodTypes.filter(foodType => {
                        return foodType.foodItemsId === mealData.foodItemId
                    })}
                    kjData={this.props.mealDataKjById.filter(kjData => {
                        return kjData.id === mealData.id
                    })}
                    editMealData={() => this.editMealData(mealData.id)}
                    deleteMealData={() => this.deleteMealData(mealData.id)}
                />))}
            </tbody>);
            }

        let syncButton = <div/>;
        let desync = <div/>
        if (this.props.meal && this.props.mealKj) {
            if (this.props.meal.totalWeight > 0 && this.props.mealKj.energyTotal > 0) {
                if (this.props.meal.foodTypeId === null || this.props.meal.foodTypeId === 0) {
                    syncButton = (<Button
                        variant="success"
                        style={{marginLeft: '1em', marginTop: '1em'}}
                        onClick={e => {
                            this.syncMeal(this.props.meal.id);
                        }}
                    >
                        Sync to FoodType
                    </Button>);

                } else {
                    syncButton = (<Button
                        variant="success"
                        style={{marginLeft: '1em', marginTop: '1em'}}
                        onClick={e => {
                            this.resyncMeal(this.props.meal.id);
                        }}
                    >
                        {`Resync to FoodType ${this.props.meal.foodTypeId}`}
                    </Button>);
                    desync = <Button
                        variant="warning"
                        style={{marginLeft: '1em', marginTop: '1em'}}
                        onClick={e => {
                            this.desyncMeal(this.props.meal.id);
                        }}
                    >
                        Desync FoodType
                    </Button>
                }
            }
        }

        let meal = (<div>nothing</div>);
        if (this.props.isFetchingMeal) {
            meal = (<Spinner/>);
        } else if (this.props.meal) {
            meal = (<div>
                    <Row style={{paddingTop: '2em'}}>
                        <Col>
                            <h2>
                                {this.props.meal.name} {kj}
                            </h2>
                        </Col>
                    </Row>
                    <Table responsive>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>TotalWeight</th>
                            <th>Date added</th>
                            <th>Date updated</th>
                            <th>Actions</th>
                        </tr>
                        <MealTableRow
                            meal={this.props.meal}
                            key={this.props.meal.id}
                            buttonFunc={() => this.editMeal(this.props.meal.id)}
                            buttonText={'Edit'}
                        />
                    </Table>
                    <Row>
                    <Button
                        variant="secondary"
                        style={{ marginLeft: '1em', marginTop: '1em' }}
                        onClick={e => {
                            this.returnToMeals();
                            console.log('return');
                        }}
                    >
                        Return
                    </Button>
                    {syncButton}
                    {desync}
                    </Row>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Add Items
                            </h2>
                        </Col>
                    </Row>
                    <AddConsumptionData isMeal={true} mealId={this.props.meal.id}/>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Meal Items (Total {kj})
                            </h2>
                        </Col>
                    </Row>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>DateTime</th>
                            <th>FoodName</th>
                            <th>WeightMg</th>
                            <th>Note</th>
                            <th>Kj</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        {mealDataTableBody}
                    </Table>
                </div>
            )

        }
        return meal;
    }

    async hideMessages(meal = false) {
        this.setState({
            mealDataDeleteSuccess: false,
            mealUpdateSuccess: false,
            foodTypeAddSuccess: false,
            mealResyncSuccess: false
        });
        // We only reset the mealsometimes
        if (meal) {
            this.props.resetMealSuccessState();
        }
        this.props.resetMealDataSuccessState();
        this.props.resetFoodTypeSuccessState();

        // Yes this is dirty.  If we don't reload the FootTypeData it may bugout if the user goes to that screen
    }

    render() {
        console.log(this.props);
        let deleteSuccess = <div/>;
        if (this.state.mealDataDeleteSuccess) {
            deleteSuccess = <Alert  variant='success' >Delete Success</Alert>;
        }

        let addFoodTypeSuccess = <div/>;
        if (this.state.foodTypeAddSuccess) {
            addFoodTypeSuccess = <Alert  variant='success' >Add FoodType Success</Alert>;
        }


        let mealResyncSuccess = <div/>;
        if (this.state.mealResyncSuccess) {
            mealResyncSuccess = <Alert  variant='success' >Resync FoodType Success</Alert>;
        }

        let updateSuccess = <div/>;
        if (this.state.mealUpdateSuccess) {
            updateSuccess = <Alert variant='success' >Update Success</Alert>;
        }

        // I tried to split showResults() in two halfs?  But pointless.
        return (
            <div>
                <Container>
                    <Row style={{ paddingTop: '1em' }}>
                        <Col>
                            {deleteSuccess}
                            {addFoodTypeSuccess}
                            {mealResyncSuccess}
                            {updateSuccess}
                        </Col>
                    </Row>
                    {this.showResults()}
                    <Row style={{ paddingTop: '1em' }}>
                        <Col>
                            {deleteSuccess}
                            {addFoodTypeSuccess}
                            {mealResyncSuccess}
                            {updateSuccess}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Meal.propTypes = {
    getMeal: PropTypes.func.isRequired,
    getMealKj: PropTypes.func.isRequired,
    updateMeal: PropTypes.func.isRequired,
    deleteMealData: PropTypes.func.isRequired,
    getAllFoodTypesWithOutChildren: PropTypes.func.isRequired,
    getFoodType: PropTypes.func.isRequired,
    resyncMeal: PropTypes.func.isRequired,
    getMealDataByMealId: PropTypes.func.isRequired,
    getMealDataKjByMealId: PropTypes.func.isRequired,

    resetMealDataSuccessState: PropTypes.func.isRequired,
    resetMealSuccessState: PropTypes.func.isRequired,
    resetFoodTypeSuccessState: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}

const mapStateToProps = ({ meal, mealData, allFoodTypes, foodType }, ownProps) => ({
    meal: meal.meal,
    mealKj: meal.mealKj,
    mealDataById: mealData.mealDataById,
    mealDataKjById: mealData.mealDataKjById,
    allFoodTypes: allFoodTypes.allFoodTypes,

    isFetchingMeal: meal.isFetchingMeal,
    isFetchingMealKj: meal.isFetchingMealKj,
    isFetchingMealDataById: mealData.isFetchingMealDataById,
    isFetchingMealDataKjById: mealData.isFetchingMealDataKjById,
    isFetchingAllFoodTypes: allFoodTypes.isFetchingAllFoodTypes,

    deleteMealDataSuccess: mealData.deleteMealDataSuccess,
    updateMealSuccess: meal.updateMealSuccess,
    addFoodTypeSuccess: foodType.addFoodTypeSuccess,
    resyncMealSuccess: meal.resyncMealSuccess,
    reloadMeal: meal.reloadMeal,
    reloadMealKjSummary: mealData.reloadMealKjSummary, //This will trigger retrieve meal KJ again
    reloadMealDataById: mealData.reloadMealDataById,
    reloadMealDataKjById: mealData.reloadMealDataKjById
});

export default withAuth0(connect(mapStateToProps, {
    getMeal,
    getMealKj,
    updateMeal,
    deleteMealData,
    getAllFoodTypesWithOutChildren,
    getFoodType,
    resyncMeal,
    getMealDataByMealId,
    getMealDataKjByMealId,
    resetMealDataSuccessState,
    resetMealSuccessState,
    resetFoodTypeSuccessState,
    push
})(Meal));
