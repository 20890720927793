import {
    FOOD_TYPE_GROUPS_REQUEST,
    FOOD_TYPE_GROUPS_RECEIVE,
    FOOD_TYPE_GROUPS_ERROR
} from "../constants/ActionTypes";

const initialState = {
    isFetching: false,
    foodTypeGroups: null,
    error: null,
};


const foodTypeGroups = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case FOOD_TYPE_GROUPS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case FOOD_TYPE_GROUPS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                foodTypeGroups: payload,
                error: null
            };
        case FOOD_TYPE_GROUPS_ERROR:
            return {
                ...state,
                isFetching: false,
                foodTypeGroups: null,
                error,
            };
        default:
            return state;
    }
};

export default foodTypeGroups;
