import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
    Form,
    Container,
    Row,
    Col,
    Button,
} from 'react-bootstrap';

class EditFoodTypeGroupForm extends Component {
    constructor(props) {
        console.log(props);

        // Load saved state or a default state when page loaded
        super(props);

        this.state = {
            foodTypeGroupId: props.foodTypeGroup.foodTypeGroupId,
            name: props.foodTypeGroup.name,
            description: props.foodTypeGroup.description,
            createdAt: props.foodTypeGroup.createdAt,
            updatedAt: props.foodTypeGroup.updatedAt
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // This is to make sure the updatedAt indicates the latest update.
        const foodTypeGroup = this.props.foodTypeGroup;
        if (foodTypeGroup.updatedAt !== this.state.updatedAt) this.setState({updatedAt: foodTypeGroup.updatedAt})
    }

    updateFoodTypeGroup() {
        this.props.updateFoodTypeGroup(this.state.foodTypeGroupId, this.state);
    }

    deleteFoodTypeGroup(foodTypeGroupId) {
        console.log('delete' + foodTypeGroupId)
        this.props.deleteFoodTypeGroup(foodTypeGroupId)
    }

    cancelEdit() {
        this.props.cancelEdit()
    }

    render() {
        console.log(this.state);
        return (
            <Container>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.updateFoodTypeGroup();
                    }}
                >
                    <Form.Group as={Row} controlId="name" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Name
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.name}
                                onChange={e => {
                                    this.setState({ name: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="description" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Description
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.description}
                                onChange={e => {
                                    this.setState({ description: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Row style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Group as={Col} sm={{ span: 5, offset: 2 }} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="createdAt">
                            <Form.Label>Created at</Form.Label>
                            <Form.Control
                                type="text"
                                value={this.state.createdAt.substring(0, 19)}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={{ span: 5}} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="updatedAt">
                            <Form.Label>Updated at</Form.Label>
                            <Form.Control
                                type="text"
                                // On update this damn string always gets extra accuracy added, debugging the C# API shows no added accuracy, have no idea :(
                                value={this.state.updatedAt.substring(0, 19)}
                                disabled
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group as={Row} style={{ margin: '0.2em', paddingTop: '1em' }}>
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit">Update</Button>
                            <Button
                                variant="danger"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.deleteFoodTypeGroup(this.state.foodTypeGroupId);
                                    console.log('delete');
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="secondary"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.cancelEdit();
                                    console.log('return');
                                }}
                            >
                                Return
                            </Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

EditFoodTypeGroupForm.propTypes = {
    foodTypeGroup: PropTypes.object.isRequired,
    deleteFoodTypeGroup: PropTypes.func.isRequired,
    updateFoodTypeGroup: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
}

export default (EditFoodTypeGroupForm);