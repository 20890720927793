import React, {Component} from 'react';
import { connect } from 'react-redux';
import EditEventTypeForm from '../components/editEventTypeForm';
import { push } from 'connected-react-router';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getDayEvent,
    deleteDayEvent,
    updateDayEvent,
    resetDayEventSuccessState
} from '../actions/dayEvents';

import {
    Container,
    Row,
    Col, Alert
} from "react-bootstrap";
import PropTypes from "prop-types";
import Spinner from "../components/spinner"

class EditDayEvent extends Component {
    constructor(props) {
        super(props);
        // default state
        this.state = {
            dayEventUpdateSuccess: false,
        };
    }

    async componentDidMount(): void{
        console.log(this.props);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.getDayEvent(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        console.log(this.props.dayEvent);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.updateDayEventSuccess && prevState.dayEventUpdateSuccess === false) {
            this.setState({ dayEventUpdateSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    async deleteDayEvent(id) {
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.deleteDayEvent(await getAccessTokenSilently(), id);
        this.props.push(`/dayEvents`);
    }

    async updateDayEvent(id, state) {
        console.log('update');
        console.log(state);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.updateDayEvent(await getAccessTokenSilently(), id, state);
    }

    cancelEdit() {
        console.log('cancel');
        this.props.push(`/dayEvents`);
    }

    showForm() {
        if (this.props.isFetching) {
            return (<Spinner/>);
        } else if (this.props.dayEvent) {
            return (
                <div>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Edit EventType #{this.props.dayEvent.id}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EditEventTypeForm
                                dayEvent={this.props.dayEvent}
                                deleteDayEvent={(id) => this.deleteDayEvent(id)}
                                updateDayEvent={(id, state) => this.updateDayEvent(id, state)}
                                cancelEdit = {() => this.cancelEdit()}
                            />
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (<div>error</div>);
        }

    }

    hideMessages() {
        this.setState({
            dayEventUpdateSuccess: false,
        });
        this.props.resetDayEventSuccessState();
    }

    render() {
        let editSuccess = <div/>;
        if (this.state.dayEventUpdateSuccess) {
            editSuccess = <Alert variant='success' >Update Success</Alert>;
        }
        return (
            <Container>
                { this.showForm() }
                <Row style={{ paddingTop: '1em' }}>
                    <Col>
                        { editSuccess }
                    </Col>
                </Row>
            </Container>
        );
    }
}

EditDayEvent.propTypes = {
    getDayEvent: PropTypes.func.isRequired,
    deleteDayEvent: PropTypes.func.isRequired,
    updateDayEvent: PropTypes.func.isRequired,
    resetDayEventSuccessState: PropTypes.func.isRequired
}

const mapStateToProps = ({ dayEvent }, ownProps) => ({
    dayEvent: dayEvent.dayEvent,
    isFetching: dayEvent.isFetching,
    updateDayEventSuccess: dayEvent.updateDayEventSuccess
});

export default withAuth0(connect(mapStateToProps, {
    push,
    getDayEvent,
    deleteDayEvent,
    updateDayEvent,
    resetDayEventSuccessState,
})(EditDayEvent));
