export const ALL_FOOD_TYPES_REQUEST = 'ALL_FOOD_TYPES_REQUEST';
export const ALL_FOOD_TYPES_RECEIVE = 'ALL_FOOD_TYPES_RECEIVE';
export const ALL_FOOD_TYPES_ERROR = 'ALL_FOOD_TYPES_ERROR';

export const ALL_FOOD_TYPES_CHILDREN_REQUEST = 'ALL_FOOD_TYPES_CHILDREN_REQUEST';
export const ALL_FOOD_TYPES_CHILDREN_RECEIVE = 'ALL_FOOD_TYPES_CHILDREN_RECEIVE';
export const ALL_FOOD_TYPES_CHILDREN_ERROR = 'ALL_FOOD_TYPES_CHILDREN_ERROR';

export const ALL_MEALS_REQUEST = 'ALL_MEALS_REQUEST';
export const ALL_MEALS_RECEIVE = 'ALL_MEALS_RECEIVE';
export const ALL_MEALS_ERROR = 'ALL_MEALS_ERROR';

export const GET_MEAL_DATA_REQUEST = 'GET_MEAL_DATA_REQUEST';
export const GET_MEAL_DATA_RECEIVE = 'GET_MEAL_DATA_RECEIVE';
export const GET_MEAL_DATA_ERROR = 'GET_MEAL_DATA_ERROR';

export const GET_KJ_MEAL_DATA_REQUEST = 'GET_KJ_MEAL_DATA_REQUEST';
export const GET_KJ_MEAL_DATA_RECEIVE = 'GET_KJ_MEAL_DATA_RECEIVE';
export const GET_KJ_MEAL_DATA_ERROR = 'GET_KJ_MEAL_DATA_ERROR';

export const UPDATE_MEAL_DATA_REQUEST = 'UPDATE_MEAL_DATA_REQUEST';
export const UPDATE_MEAL_DATA_RECEIVE = 'UPDATE_MEAL_DATA_RECEIVE';
export const UPDATE_MEAL_DATA_ERROR = 'UPDATE_MEAL_DATA_ERROR';

export const ADD_MEAL_REQUEST = 'ADD_MEAL_REQUEST';
export const ADD_MEAL_RECEIVE = 'ADD_MEAL_RECEIVE';
export const ADD_MEAL_ERROR = 'ADD_MEAL_ERROR';

export const DELETE_MEAL_DATA_REQUEST = 'DELETE_MEAL_DATA_REQUEST';
export const DELETE_MEAL_DATA_RECEIVE = 'DELETE_MEAL_DATA_RECEIVE';
export const DELETE_MEAL_DATA_ERROR = 'DELETE_MEAL_DATA_ERROR';

export const UPDATE_MEAL_REQUEST = 'UPDATE_MEAL_REQUEST';
export const UPDATE_MEAL_RECEIVE = 'UPDATE_MEAL_RECEIVE';
export const UPDATE_MEAL_ERROR = 'UPDATE_MEAL_ERROR';

export const DELETE_MEAL_REQUEST = 'DELETE_MEAL_REQUEST';
export const DELETE_MEAL_RECEIVE = 'DELETE_MEAL_RECEIVE';
export const DELETE_MEAL_ERROR = 'DELETE_MEAL_ERROR';

export const GET_MEAL_REQUEST = 'GET_MEAL_REQUEST';
export const GET_MEAL_RECEIVE = 'GET_MEAL_RECEIVE';
export const GET_MEAL_ERROR = 'GET_MEAL_ERROR';

export const GET_MEAL_KJ_REQUEST = 'GET_MEAL_KJ_REQUEST';
export const GET_MEAL_KJ_RECEIVE = 'GET_MEAL_KJ_RECEIVE';
export const GET_MEAL_KJ_ERROR = 'GET_MEAL_KJ_ERROR';


export const RESYNC_MEAL_REQUEST = 'RESYNC_MEAL_REQUEST';
export const RESYNC_MEAL_RECEIVE = 'RESYNC_MEAL_RECEIVE';
export const RESYNC_MEAL_ERROR = 'RESYNC_MEAL_ERROR';

export const MEAL_CONFIRM_RESET = 'MEAL_CONFIRM_RESET';

export const ADD_MEAL_DATA_REQUEST = 'ADD_MEAL_DATA_REQUEST';
export const ADD_MEAL_DATA_RECEIVE = 'ADD_MEAL_DATA_RECEIVE';
export const ADD_MEAL_DATA_ERROR = 'ADD_MEAL_DATA_ERROR';

export const GET_MEAL_DATA_BY_MEALID_REQUEST = 'GET_MEAL_DATA_BY_MEALID_REQUEST';
export const GET_MEAL_DATA_BY_MEALID_RECEIVE = 'GET_MEAL_DATA_BY_MEALID_RECEIVE';
export const GET_MEAL_DATA_BY_MEALID_ERROR = 'GET_MEAL_DATA_BY_MEALID_ERROR';

export const GET_MEAL_DATA_KJ_BY_MEALID_REQUEST = 'GET_MEAL_DATA_KJ_BY_MEALID_REQUEST';
export const GET_MEAL_DATA_KJ_BY_MEALID_RECEIVE = 'GET_MEAL_DATA_KJ_BY_MEALID_RECEIVE';
export const GET_MEAL_DATA_KJ_BY_MEALID_ERROR = 'GET_MEAL_DATA_KJ_BY_MEALID_ERROR';

export const MEAL_DATA_CONFIRM_RESET = 'MEAL_DATA_CONFIRM_RESET';

export const FOOD_TYPE_REQUEST = 'FOOD_TYPE_REQUEST';
export const FOOD_TYPE_RECEIVE = 'FOOD_TYPE_RECEIVE';
export const FOOD_TYPE_ERROR = 'FOOD_TYPE_ERROR';

export const DELETE_FOOD_TYPE_REQUEST = 'DELETE_FOOD_TYPE_REQUEST';
export const DELETE_FOOD_TYPE_RECEIVE = 'DELETE_FOOD_TYPE_RECEIVE';
export const DELETE_FOOD_TYPE_ERROR = 'DELETE_FOOD_TYPE_ERROR';

export const UPDATE_FOOD_TYPE_REQUEST = 'UPDATE_FOOD_TYPE_REQUEST';
export const UPDATE_FOOD_TYPE_RECEIVE = 'UPDATE_FOOD_TYPE_RECEIVE';
export const UPDATE_FOOD_TYPE_ERROR = 'UPDATE_FOOD_TYPE_ERROR';

export const UPDATE_FOOD_TYPE_GROUPS_REQUEST = 'UPDATE_FOOD_TYPE_GROUPS_REQUEST';
export const UPDATE_FOOD_TYPE_GROUPS_RECEIVE = 'UPDATE_FOOD_TYPE_GROUPS_RECEIVE';
export const UPDATE_FOOD_TYPE_GROUPS_ERROR = 'UPDATE_FOOD_TYPE_GROUPS_ERROR';

export const ADD_FOOD_TYPE_REQUEST = 'ADD_FOOD_TYPE_REQUEST';
export const ADD_FOOD_TYPE_RECEIVE = 'ADD_FOOD_TYPE_RECEIVE';
export const ADD_FOOD_TYPE_ERROR = 'ADD_FOOD_TYPE_ERROR';

export const FOOD_TYPE_CONFIRM_RESET = 'FOOD_TYPE_CONFIRM_RESET'

export const FOOD_TYPE_GROUPS_REQUEST = 'FOOD_TYPE_GROUPS_REQUEST';
export const FOOD_TYPE_GROUPS_RECEIVE = 'FOOD_TYPE_GROUPS_RECEIVE';
export const FOOD_TYPE_GROUPS_ERROR = 'FOOD_TYPE_GROUPS_ERROR';

export const FOOD_TYPE_GROUP_REQUEST = 'FOOD_TYPE_GROUP_REQUEST';
export const FOOD_TYPE_GROUP_RECEIVE = 'FOOD_TYPE_GROUP_RECEIVE';
export const FOOD_TYPE_GROUP_ERROR = 'FOOD_TYPE_GROUP_ERROR';

export const DELETE_FOOD_TYPE_GROUP_REQUEST = 'DELETE_FOOD_TYPE_GROUP_REQUEST';
export const DELETE_FOOD_TYPE_GROUP_RECEIVE = 'DELETE_FOOD_TYPE_GROUP_RECEIVE';
export const DELETE_FOOD_TYPE_GROUP_ERROR = 'DELETE_FOOD_TYPE_GROUP_ERROR';

export const UPDATE_FOOD_TYPE_GROUP_REQUEST = 'UPDATE_FOOD_TYPE_GROUP_REQUEST';
export const UPDATE_FOOD_TYPE_GROUP_RECEIVE = 'UPDATE_FOOD_TYPE_GROUP_RECEIVE';
export const UPDATE_FOOD_TYPE_GROUP_ERROR = 'UPDATE_FOOD_TYPE_GROUP_ERROR';

export const ADD_FOOD_TYPE_GROUP_REQUEST = 'ADD_FOOD_TYPE_GROUP_REQUEST';
export const ADD_FOOD_TYPE_GROUP_RECEIVE = 'ADD_FOOD_TYPE_GROUP_RECEIVE';
export const ADD_FOOD_TYPE_GROUP_ERROR = 'ADD_FOOD_TYPE_GROUP_ERROR';

export const FOOD_TYPE_GROUP_CONFIRM_RESET = 'FOOD_TYPE_GROUP_CONFIRM_RESET';


export const ALL_DAY_EVENTS_REQUEST = 'ALL_DAY_EVENTS_REQUEST';
export const ALL_DAY_EVENTS_RECEIVE = 'ALL_DAY_EVENTS_RECEIVE';
export const ALL_DAY_EVENTS_ERROR = 'ALL_DAY_EVENTS_ERROR';


export const DAY_EVENT_REQUEST = 'DAY_EVENT_REQUEST';
export const DAY_EVENT_RECEIVE = 'DAY_EVENT_RECEIVE';
export const DAY_EVENT_ERROR = 'DAY_EVENT_ERROR';

export const DELETE_DAY_EVENT_REQUEST = 'DELETE_DAY_EVENT_REQUEST';
export const DELETE_DAY_EVENT_RECEIVE = 'DELETE_DAY_EVENT_RECEIVE';
export const DELETE_DAY_EVENT_ERROR = 'DELETE_DAY_EVENT_ERROR';

export const UPDATE_DAY_EVENT_REQUEST = 'UPDATE_DAY_EVENT_REQUEST';
export const UPDATE_DAY_EVENT_RECEIVE = 'UPDATE_DAY_EVENT_RECEIVE';
export const UPDATE_DAY_EVENT_ERROR = 'UPDATE_DAY_EVENT_ERROR';

export const ADD_DAY_EVENT_REQUEST = 'ADD_DAY_EVENT_REQUEST';
export const ADD_DAY_EVENT_RECEIVE = 'ADD_DAY_EVENT_RECEIVE';
export const ADD_DAY_EVENT_ERROR = 'ADD_DAY_EVENT_ERROR';

export const DAY_EVENT_CONFIRM_RESET = 'DAY_EVENT_CONFIRM_RESET';


export const ALL_CONSUMPTION_DATA_REQUEST = 'ALL_CONSUMPTION_DATA_REQUEST';
export const ALL_CONSUMPTION_DATA_RECEIVE = 'ALL_CONSUMPTION_DATA_RECEIVE';
export const ALL_CONSUMPTION_DATA_ERROR = 'ALL_CONSUMPTION_DATA_ERROR';


export const ALL_KJ_CONSUMPTION_DATA_REQUEST = 'ALL_KJ_CONSUMPTION_DATA_REQUEST';
export const ALL_KJ_CONSUMPTION_DATA_RECEIVE = 'ALL_KJ_CONSUMPTION_DATA_RECEIVE';
export const ALL_KJ_CONSUMPTION_DATA_ERROR = 'ALL_KJ_CONSUMPTION_DATA_ERROR';

export const GET_KJ_CONSUMPTION_DATA_REQUEST = 'GET_KJ_CONSUMPTION_DATA_REQUEST';
export const GET_KJ_CONSUMPTION_DATA_RECEIVE = 'GET_KJ_CONSUMPTION_DATA_RECEIVE';
export const GET_KJ_CONSUMPTION_DATA_ERROR = 'GET_KJ_CONSUMPTION_DATA_ERROR';


export const GET_CONSUMPTION_DATA_REQUEST = 'GET_CONSUMPTION_DATA_REQUEST';
export const GET_CONSUMPTION_DATA_RECEIVE = 'GET_CONSUMPTION_DATA_RECEIVE';
export const GET_CONSUMPTION_DATA_ERROR = 'GET_CONSUMPTION_DATA_ERROR';

export const ADD_CONSUMPTION_DATA_REQUEST = 'ADD_CONSUMPTION_DATA_REQUEST';
export const ADD_CONSUMPTION_DATA_RECEIVE = 'ADD_CONSUMPTION_DATA_RECEIVE';
export const ADD_CONSUMPTION_DATA_ERROR = 'ADD_CONSUMPTION_DATA_ERROR';

export const DELETE_CONSUMPTION_DATA_REQUEST = 'DELETE_CONSUMPTION_DATA_REQUEST';
export const DELETE_CONSUMPTION_DATA_RECEIVE = 'DELETE_CONSUMPTION_DATA_RECEIVE';
export const DELETE_CONSUMPTION_DATA_ERROR = 'DELETE_CONSUMPTION_DATA_ERROR';

export const UPDATE_CONSUMPTION_DATA_REQUEST = 'UPDATE_CONSUMPTION_DATA_REQUEST';
export const UPDATE_CONSUMPTION_DATA_RECEIVE = 'UPDATE_CONSUMPTION_DATA_RECEIVE';
export const UPDATE_CONSUMPTION_DATA_ERROR = 'UPDATE_CONSUMPTION_DATA_ERROR';

export const GET_CONSUMPTION_DATA_SUMMARY_REQUEST = 'GET_CONSUMPTION_DATA_SUMMARY_REQUEST';
export const GET_CONSUMPTION_DATA_SUMMARY_RECEIVE = 'GET_CONSUMPTION_DATA_SUMMARY_RECEIVE';
export const GET_CONSUMPTION_DATA_SUMMARY_ERROR = 'GET_CONSUMPTION_DATA_SUMMARY_ERROR';

export const GET_CONSUMPTION_DATA_KJ_SUMMARY_REQUEST = 'GET_CONSUMPTION_DATA_KJ_SUMMARY_REQUEST';
export const GET_CONSUMPTION_DATA_KJ_SUMMARY_RECEIVE = 'GET_CONSUMPTION_DATA_KJ_SUMMARY_RECEIVE';
export const GET_CONSUMPTION_DATA_KJ_SUMMARY_ERROR = 'GET_CONSUMPTION_DATA_KJ_SUMMARY_ERROR';

export const GET_CONSUMPTION_DAYS_WITH_DATA_REQUEST = 'GET_CONSUMPTION_DAYS_WITH_DATA_REQUEST';
export const GET_CONSUMPTION_DAYS_WITH_DATA_RECEIVE = 'GET_CONSUMPTION_DAYS_WITH_DATA_RECEIVE';
export const GET_CONSUMPTION_DAYS_WITH_DATA_ERROR = 'GET_CONSUMPTION_DAYS_WITH_DATA_ERROR';

export const CONSUMPTION_DATA_CONFIRM_RESET = 'CONSUMPTION_DATA_CONFIRM_RESET'


export const ALL_CONTAINERS_REQUEST  = 'ALL_CONTAINERS_REQUEST';
export const ALL_CONTAINERS_RECEIVE  = 'ALL_CONTAINERS_RECEIVE';
export const ALL_CONTAINERS_ERROR  = 'ALL_CONTAINERS_ERROR';
export const GET_CONTAINER_REQUEST  = 'GET_CONTAINER_REQUEST';
export const GET_CONTAINER_RECEIVE  = 'GET_CONTAINER_RECEIVE';
export const GET_CONTAINER_ERROR  = 'GET_CONTAINER_ERROR';
export const DELETE_CONTAINER_REQUEST  = 'DELETE_CONTAINER_REQUEST';
export const DELETE_CONTAINER_RECEIVE  = 'DELETE_CONTAINER_RECEIVE';
export const DELETE_CONTAINER_ERROR  = 'DELETE_CONTAINER_ERROR';
export const UPDATE_CONTAINER_REQUEST  = 'UPDATE_CONTAINER_REQUEST';
export const UPDATE_CONTAINER_RECEIVE  = 'UPDATE_CONTAINER_RECEIVE';
export const UPDATE_CONTAINER_ERROR  = 'UPDATE_CONTAINER_ERROR';
export const ADD_CONTAINER_REQUEST  = 'ADD_CONTAINER_REQUEST';
export const ADD_CONTAINER_RECEIVE  = 'ADD_CONTAINER_RECEIVE';
export const ADD_CONTAINER_ERROR  = 'ADD_CONTAINER_ERROR';

export const CONTAINER_CONFIRM_RESET  = 'CONTAINER_CONFIRM_RESET';


export const SET_DAY_BROWSER_DATE = 'SET_DAY_BROWSER_DATE';

export const GET_USER_SETTINGS_REQUEST = 'GET_USER_SETTINGS_REQUEST';
export const GET_USER_SETTINGS_RECEIVE = 'GET_USER_SETTINGS_RECEIVE';
export const GET_USER_SETTINGS_ERROR = 'GET_USER_SETTINGS_ERROR';

export const UPDATE_USER_SETTINGS_REQUEST = 'UPDATE_USER_SETTINGS_REQUEST';
export const UPDATE_USER_SETTINGS_RECEIVE = 'UPDATE_USER_SETTINGS_RECEIVE';
export const UPDATE_USER_SETTINGS_ERROR = 'UPDATE_USER_SETTINGS_ERROR';

export const USER_SETTINGS_CONFIRM_RESET = 'USER_SETTINGS_CONFIRM_RESET';