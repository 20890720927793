import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap'

const MealTableRow = ({ meal, buttonFunc, buttonText }) =>
    <tr style={{fontSize: '1em', padding: '0.25em'}}>
        <td style={{color: '#18605A'}}>
            {meal.id}
        </td>
        <td>
            {meal.name}
        </td>
        <td>
            {meal.totalWeight}
        </td>
        <td>
            {meal.createdAt}
        </td>
        <td>
            {meal.updatedAt}
        </td>
        <td>
            <Button onClick={buttonFunc}>{buttonText}</Button>
        </td>
    </tr>;

MealTableRow.propTypes = {
    meal: PropTypes.object.isRequired,
    mapFoodItem: PropTypes.func.isRequired,
};

export default MealTableRow;
