import {
    SET_DAY_BROWSER_DATE
} from '../constants/ActionTypes';

const initialState = {
    date: new Date(),
};


export default (state = initialState, { type, date }) => {
    switch (type) {
        case SET_DAY_BROWSER_DATE:
            return {
                ...state,
                date: date,
            };
        default:
            return state;
    }
};
