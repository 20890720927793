import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Table, Alert, Button, Col, Row, Container} from 'react-bootstrap'
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getFoodTypeGroups,
    resetFoodTypeGroupSuccessState,
} from '../actions/foodTypeGroup';
import Spinner from "../components/spinner";
import FoodTypeGroupTableRow from "../components/foodTypeGroupTableRow";

class FoodTypeGroups extends Component {
    constructor() {
        super();
        this.state = {
            foodTypeGroupDeleteSuccess: false,
            foodTypeGroupAddSuccess: false
        };
    }

    async componentDidMount() {
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getFoodTypeGroups(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.deleteFoodTyoeGroupSuccess && prevState.foodTypeGroupDeleteSuccess === false) {
            this.setState({ foodTypeGroupDeleteSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }

        if (this.props.addFoodTypeGroupSuccess && prevState.foodTypeGroupAddSuccess === false) {
            this.setState({ foodTypeGroupAddSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    addFoodTypeGroup () {
        this.props.push(`/foodTypeGroups/add`);
    }

    editFoodTypeGroup (id) {
        console.log(id);
        this.props.push(`/foodTypeGroups/edit/${id}`);
    }

    showResults() {
        let foodTypeGroups = (<div>nothing</div>);
        if (this.props.isFetching) {
            foodTypeGroups = (<Spinner/>);
        } else if (this.props.foodTypeGroups) {
            foodTypeGroups = (
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Date added</th>
                        <th>Date updated</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.props.foodTypeGroups.map((foodTypeGroup) => (
                        <FoodTypeGroupTableRow
                            FoodTypeGroup={foodTypeGroup}
                            key={foodTypeGroup.foodTypeGroupId}
                            editFoodTypeGroup={() => this.editFoodTypeGroup(foodTypeGroup.foodTypeGroupId)}
                        />)) }
                    </tbody>
                </Table>
            )

        }
        return foodTypeGroups;
    }

    async hideMessages() {
        this.setState({
            foodTypeGroupDeleteSuccess: false,
            foodTypeGroupAddSuccess: false
        });
        this.props.resetFoodTypeGroupSuccessState();
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getFoodTypeGroups(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    render() {
        let deleteSuccess = <div/>;
        if (this.state.foodTypeGroupDeleteSuccess) {
            deleteSuccess = <Alert  variant='success' >Delete Success</Alert>;
        }

        let addSuccess = <div/>;
        if (this.state.foodTypeGroupAddSuccess) {
            addSuccess = <Alert  variant='success' >Add Success</Alert>;
        }

        return (
            <div>
                <Container>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Food Type Groups
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.showResults()}
                            <div style={{ padding: '0.75em', borderTop: '1px solid #dee2e6' }}>
                                <Button
                                    onClick={e => {this.addFoodTypeGroup()}}
                                >
                                    Add new FoodTypeGroup
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '1em' }}>
                        <Col>
                            {deleteSuccess}
                            {addSuccess}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

FoodTypeGroups.propTypes = {
    getFoodTypeGroups: PropTypes.func.isRequired,
    resetFoodTypeGroupSuccessState: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}

const mapStateToProps = ({ foodTypeGroups, foodTypeGroup }, ownProps) => ({
    foodTypeGroups: foodTypeGroups.foodTypeGroups,
    isFetching: foodTypeGroups.isFetching,
    deleteFoodTypeGroupSuccess: foodTypeGroup.deleteFoodTypeGroupSuccess,
    addFoodTypeGroupSuccess: foodTypeGroup.addFoodTypeGroupSuccess
});

export default withAuth0(connect(mapStateToProps, {
    getFoodTypeGroups,
    resetFoodTypeGroupSuccessState,
    push,
})(FoodTypeGroups));
