import { createAction } from 'redux-api-middleware';

import {
    GET_MEAL_DATA_REQUEST,
    GET_MEAL_DATA_RECEIVE,
    GET_MEAL_DATA_ERROR,

    ADD_MEAL_DATA_REQUEST,
    ADD_MEAL_DATA_RECEIVE,
    ADD_MEAL_DATA_ERROR,

    DELETE_MEAL_DATA_REQUEST,
    DELETE_MEAL_DATA_RECEIVE,
    DELETE_MEAL_DATA_ERROR,

    UPDATE_MEAL_DATA_REQUEST,
    UPDATE_MEAL_DATA_RECEIVE,
    UPDATE_MEAL_DATA_ERROR,

    GET_MEAL_DATA_BY_MEALID_REQUEST,
    GET_MEAL_DATA_BY_MEALID_RECEIVE,
    GET_MEAL_DATA_BY_MEALID_ERROR,

    GET_MEAL_DATA_KJ_BY_MEALID_REQUEST,
    GET_MEAL_DATA_KJ_BY_MEALID_RECEIVE,
    GET_MEAL_DATA_KJ_BY_MEALID_ERROR,

    GET_KJ_MEAL_DATA_REQUEST,
    GET_KJ_MEAL_DATA_RECEIVE,
    GET_KJ_MEAL_DATA_ERROR,

    MEAL_DATA_CONFIRM_RESET,
} from '../constants/ActionTypes';



export const getMealData = (token, id, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/MealData/${id}?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_MEAL_DATA_REQUEST,
        GET_MEAL_DATA_RECEIVE,
        GET_MEAL_DATA_ERROR
    ]
});

export const addMealData = (token, data) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/MealData`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        ADD_MEAL_DATA_REQUEST,
        ADD_MEAL_DATA_RECEIVE,
        ADD_MEAL_DATA_ERROR
    ]
});


export const updateMealData = (token, id, data, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/MealData/${id}?timezone=${timezone}`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        UPDATE_MEAL_DATA_REQUEST,
        UPDATE_MEAL_DATA_RECEIVE,
        UPDATE_MEAL_DATA_ERROR
    ]
})


export const getMealDataByMealId = (token, id, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/MealData?mealId=${id}&timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_MEAL_DATA_BY_MEALID_REQUEST,
        GET_MEAL_DATA_BY_MEALID_RECEIVE,
        GET_MEAL_DATA_BY_MEALID_ERROR
    ]
});

export const getMealDataKjByMealId = (token, id) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/MealData/kjComponent?mealId=${id}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_MEAL_DATA_KJ_BY_MEALID_REQUEST,
        GET_MEAL_DATA_KJ_BY_MEALID_RECEIVE,
        GET_MEAL_DATA_KJ_BY_MEALID_ERROR
    ]
});

export const getKjMealData = (token, id, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/MealData/${id}/kjData?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_KJ_MEAL_DATA_REQUEST,
        GET_KJ_MEAL_DATA_RECEIVE,
        GET_KJ_MEAL_DATA_ERROR
    ]
});

export const deleteMealData = (token, id) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/MealData/${id}`,
    method: 'DELETE',
    headers: {
        'Authorization': `Bearer ${token}`
    },
    types: [
        DELETE_MEAL_DATA_REQUEST,
        DELETE_MEAL_DATA_RECEIVE,
        DELETE_MEAL_DATA_ERROR,
    ]
});


export const resetMealDataSuccessState = () => ({
    type: MEAL_DATA_CONFIRM_RESET,
});


