import { createAction } from 'redux-api-middleware';

import {
    FOOD_TYPE_GROUPS_REQUEST,
    FOOD_TYPE_GROUPS_RECEIVE,
    FOOD_TYPE_GROUPS_ERROR,

    FOOD_TYPE_GROUP_REQUEST,
    FOOD_TYPE_GROUP_RECEIVE,
    FOOD_TYPE_GROUP_ERROR,

    DELETE_FOOD_TYPE_GROUP_REQUEST,
    DELETE_FOOD_TYPE_GROUP_RECEIVE,
    DELETE_FOOD_TYPE_GROUP_ERROR,

    UPDATE_FOOD_TYPE_GROUP_REQUEST,
    UPDATE_FOOD_TYPE_GROUP_RECEIVE,
    UPDATE_FOOD_TYPE_GROUP_ERROR,

    ADD_FOOD_TYPE_GROUP_REQUEST,
    ADD_FOOD_TYPE_GROUP_RECEIVE,
    ADD_FOOD_TYPE_GROUP_ERROR,

    FOOD_TYPE_GROUP_CONFIRM_RESET,
} from '../constants/ActionTypes';

//TODO refactor all getAll methods to be plural and remove all.
export const getFoodTypeGroups = (token, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/FoodTypeGroup?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        FOOD_TYPE_GROUPS_REQUEST,
        FOOD_TYPE_GROUPS_RECEIVE,
        FOOD_TYPE_GROUPS_ERROR
    ]
})

export const getFoodTypeGroup = (token, id, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/FoodTypeGroup/${id}?children=true&timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        FOOD_TYPE_GROUP_REQUEST,
        FOOD_TYPE_GROUP_RECEIVE,
        FOOD_TYPE_GROUP_ERROR
    ]
})

export const deleteFoodTypeGroup = (token, id) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/FoodTypeGroup/${id}`,
    method: 'DELETE',
    headers: {
        'Authorization': `Bearer ${token}`
    },
    types: [
        DELETE_FOOD_TYPE_GROUP_REQUEST,
        DELETE_FOOD_TYPE_GROUP_RECEIVE,
        DELETE_FOOD_TYPE_GROUP_ERROR,
    ]
});

export const updateFoodTypeGroup = (token, id, data) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/FoodTypeGroup/${id}`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        UPDATE_FOOD_TYPE_GROUP_REQUEST,
        UPDATE_FOOD_TYPE_GROUP_RECEIVE,
        UPDATE_FOOD_TYPE_GROUP_ERROR,
    ]
})

export const addFoodTypeGroup = (token, data) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/FoodTypeGroup`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        ADD_FOOD_TYPE_GROUP_REQUEST,
        ADD_FOOD_TYPE_GROUP_RECEIVE,
        ADD_FOOD_TYPE_GROUP_ERROR,
    ]
})


export const resetFoodTypeGroupSuccessState = () => ({
    type: FOOD_TYPE_GROUP_CONFIRM_RESET,
});