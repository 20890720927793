import React, {Component} from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withAuth0 } from "@auth0/auth0-react";

import {
    addFoodTypeGroup,
} from '../actions/foodTypeGroup';

import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import PropTypes from "prop-types";
import AddFoodTypeGroupForm from "../components/addFoodTypeGroupForm";


class AddFoodTypeGroup extends Component {

    async addFoodTypeGroup(state) {
        const {getAccessTokenSilently} = this.props.auth0;

        console.log('add');
        console.log(state);
        this.props.addFoodTypeGroup(await getAccessTokenSilently(), state);
        this.props.push(`/foodTypeGroups`);
    }

    cancelAdd() {
        console.log('cancel');
        this.props.push(`/foodTypeGroups`);
    }

    render() {
        return (
            <div>
                <Container>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Add Food Type Group
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AddFoodTypeGroupForm
                                addFoodTypeGroup={(state) => this.addFoodTypeGroup(state)}
                                cancelAdd = {() => this.cancelAdd()}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

AddFoodTypeGroup.propTypes = {
    addFoodTypeGroup: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({
});

export default withAuth0(connect(mapStateToProps, {
    push,
    addFoodTypeGroup,
})(AddFoodTypeGroup));
