import React from 'react';
import { Col, Row } from 'react-bootstrap'
import { Chart } from 'react-charts'

export default function ConsumptionLineChart (props) {
    let dayStart = new Date();
    dayStart.setHours(0, 0, 0, 0);
    let endStart = new Date();
    endStart.setHours(23, 59, 59, 0);
    let graphPoints = [[dayStart, 0]];
    if (props.consumptionDataKjSummary) {
        for (let i = 0; i < props.consumptionDataKjSummary.length; i++) {
            graphPoints.push([new Date(props.consumptionDataKjSummary[i].dateTime), props.consumptionDataKjSummary[i].kjValue])
        }
    }
    graphPoints.push([endStart, 0]);

    let data = React.useMemo(
        () => [
            {
                label: 'dayData',
                data: graphPoints
            }
        ],
        []
    )

    let axes = React.useMemo(
        () => [
            {primary: true, type: 'time', position: 'bottom'},
            {type: 'linear', position: 'left'}
        ],
        []
    )
    return (
        <Row style={{paddingTop: '2em'}}>
            <Col>
                <div style={{
                    width: '100%',
                    height: '300px'
                }}>
                    <Chart key={props.key} data={data} axes={axes}/>
                </div>
            </Col>
        </Row>
    )
}
