import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Table, Alert, Button, Col, Row, Container} from 'react-bootstrap'
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getAllDayEvents,
    resetDayEventSuccessState,
} from '../actions/dayEvents';
import Spinner from "../components/spinner";
import DayEventTableRow from "../components/dayEventTableRow";

class DayEvent extends Component {
    constructor() {
        super();
        this.state = {
            dayEventDeleteSuccess: false,
            dayEventAddSuccess: false
        };
    }

    async componentDidMount() {
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getAllDayEvents(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.deleteDayEventSuccess && prevState.dayEventDeleteSuccess === false) {
            this.setState({ dayEventDeleteSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }

        if (this.props.addDayEventSuccess && prevState.dayEventAddSuccess === false) {
            this.setState({ dayEventAddSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    addDayEvent () {
        this.props.push(`/dayEvents/add`);
    }

    editDayEvent (id) {
        console.log(id);
        this.props.push(`/dayEvents/edit/${id}`);
    }

    showResults() {
        let dayEvents = (<div>nothing</div>);
        if (this.props.isFetching) {
            dayEvents = (<Spinner/>);
        } else if (this.props.allDayEvents) {
            dayEvents = (
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Date added</th>
                        <th>Date updated</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.props.allDayEvents.map((dayEvent) => (
                        <DayEventTableRow
                            DayEvent={dayEvent}
                            key={dayEvent.id}
                            editDayEvent={() => this.editDayEvent(dayEvent.id)}
                        />)) }
                    </tbody>
                </Table>
            )

        }
        return dayEvents;
    }

    async hideMessages() {
        this.setState({
            dayEventDeleteSuccess: false,
            dayEventAddSuccess: false
        });
        this.props.resetDayEventSuccessState();
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getAllDayEvents(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    render() {
        let deleteSuccess = <div/>;
        if (this.state.dayEventDeleteSuccess) {
            deleteSuccess = <Alert  variant='success' >Delete Success</Alert>;
        }

        let addSuccess = <div/>;
        if (this.state.dayEventAddSuccess) {
            addSuccess = <Alert  variant='success' >Add Success</Alert>;
        }

        return (
            <div>
                <Container>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Day Events
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.showResults()}
                            <div style={{ padding: '0.75em', borderTop: '1px solid #dee2e6' }}>
                                <Button
                                    onClick={e => {this.addDayEvent()}}
                                >
                                    Add new DayEvent
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '1em' }}>
                        <Col>
                            {deleteSuccess}
                            {addSuccess}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

DayEvent.propTypes = {
    getAllDayEvents: PropTypes.func.isRequired,
    resetDayEventSuccessState: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}

const mapStateToProps = ({ allDayEvents, dayEvent }, ownProps) => ({
    allDayEvents: allDayEvents.allDayEvents,
    isFetching: allDayEvents.isFetching,
    deleteDayEventSuccess: dayEvent.deleteDayEventSuccess,
    addDayEventSuccess: dayEvent.addDayEventSuccess
});

export default withAuth0(connect(mapStateToProps, {
    getAllDayEvents,
    resetDayEventSuccessState,
    push,
})(DayEvent));
