import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
    Form,
    Container,
    Row,
    Col,
    Button,
} from 'react-bootstrap';

class EditUserSettingsTypeForm extends Component {
    constructor(props) {
        console.log(props);

        // Load saved state or a default state when page loaded
        super(props);

        this.state = {
            kjGoal: props.userSettings.kjGoal,
            createdAt: props.userSettings.createdAt,
            updatedAt: props.userSettings.updatedAt,
            email: props.userSettings.email,
            userName: props.userSettings.userName,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // This is to make sure the updatedAt indicates the latest update.
        const userSettings = this.props.userSettings;
        if (userSettings.updatedAt !== this.state.updatedAt) this.setState({updatedAt: userSettings.updatedAt})
    }

    updateUserSettingsEvent() {
        this.props.updateUserSettings(this.state);
    }

    render() {
        console.log(this.state);
        return (
            <Container>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.updateUserSettingsEvent();
                    }}
                >
                    <Form.Group as={Row} controlId="name" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Kj Goal
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.kjGoal}
                                onChange={e => {
                                    this.setState({ kjGoal: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Row style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Group as={Col} sm={{ span: 5, offset: 2 }} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="createdAt">
                            <Form.Label>Created at</Form.Label>
                            <Form.Control
                                type="text"
                                value={this.state.createdAt.substring(0, 19)}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={{ span: 5}} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="updatedAt">
                            <Form.Label>Updated at</Form.Label>
                            <Form.Control
                                type="text"
                                // On update this damn string always gets extra accuracy added, debugging the C# API shows no added accuracy, have no idea :(
                                value={this.state.updatedAt.substring(0, 19)}
                                disabled
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group as={Row} style={{ margin: '0.2em', paddingTop: '1em' }}>
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit">Update</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

EditUserSettingsTypeForm.propTypes = {
    updateUserSettings: PropTypes.func.isRequired,
    userSettings: PropTypes.object.isRequired
}

export default (EditUserSettingsTypeForm);