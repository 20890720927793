import { createAction } from 'redux-api-middleware';

import {
    ALL_MEALS_REQUEST,
    ALL_MEALS_RECEIVE,
    ALL_MEALS_ERROR,
    ADD_MEAL_REQUEST,
    ADD_MEAL_ERROR,
    ADD_MEAL_RECEIVE,
    UPDATE_MEAL_REQUEST,
    UPDATE_MEAL_RECEIVE,
    UPDATE_MEAL_ERROR,
    DELETE_MEAL_REQUEST,
    DELETE_MEAL_RECEIVE,
    DELETE_MEAL_ERROR,
    GET_MEAL_REQUEST,
    GET_MEAL_RECEIVE,
    GET_MEAL_ERROR,
    GET_MEAL_KJ_REQUEST,
    GET_MEAL_KJ_RECEIVE,
    GET_MEAL_KJ_ERROR,
    RESYNC_MEAL_REQUEST,
    RESYNC_MEAL_RECEIVE,
    RESYNC_MEAL_ERROR,
    MEAL_CONFIRM_RESET,
} from '../constants/ActionTypes';

export const getAllMeals = (token, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Meal?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        ALL_MEALS_REQUEST,
        ALL_MEALS_RECEIVE,
        ALL_MEALS_ERROR,
    ]
})

export const addMeal = (token, data) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Meal`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        ADD_MEAL_REQUEST,
        ADD_MEAL_RECEIVE,
        ADD_MEAL_ERROR,
    ]
})


export const updateMeal = (token, id, data) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Meal/${id}`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        UPDATE_MEAL_REQUEST,
        UPDATE_MEAL_RECEIVE,
        UPDATE_MEAL_ERROR,
    ]
})

export const resyncMeal = (token, id) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Meal/${id}/Resync`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    // Needs to change these Types and Also add methods to API
    types: [
        RESYNC_MEAL_REQUEST,
        RESYNC_MEAL_RECEIVE,
        RESYNC_MEAL_ERROR,
    ]
})


export const deleteMeal = (token, id) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Meal/${id}`,
    method: 'DELETE',
    headers: {
        'Authorization': `Bearer ${token}`
    },
    types: [
        DELETE_MEAL_REQUEST,
        DELETE_MEAL_RECEIVE,
        DELETE_MEAL_ERROR,
    ]
});

export const getMeal = (token, id, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Meal/${id}?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_MEAL_REQUEST,
        GET_MEAL_RECEIVE,
        GET_MEAL_ERROR,
    ]
})

export const getMealKj = (token, id, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Meal/${id}/Kj?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_MEAL_KJ_REQUEST,
        GET_MEAL_KJ_RECEIVE,
        GET_MEAL_KJ_ERROR,
    ]
})

export const resetMealSuccessState = () => ({
    type: MEAL_CONFIRM_RESET,
});


