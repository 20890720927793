import {
    FOOD_TYPE_GROUP_REQUEST,
    FOOD_TYPE_GROUP_RECEIVE,
    FOOD_TYPE_GROUP_ERROR,

    DELETE_FOOD_TYPE_GROUP_REQUEST,
    DELETE_FOOD_TYPE_GROUP_RECEIVE,
    DELETE_FOOD_TYPE_GROUP_ERROR,

    UPDATE_FOOD_TYPE_GROUP_REQUEST,
    UPDATE_FOOD_TYPE_GROUP_RECEIVE,
    UPDATE_FOOD_TYPE_GROUP_ERROR,

    ADD_FOOD_TYPE_GROUP_REQUEST,
    ADD_FOOD_TYPE_GROUP_RECEIVE,
    ADD_FOOD_TYPE_GROUP_ERROR,

    FOOD_TYPE_GROUP_CONFIRM_RESET,
} from '../constants/ActionTypes';

const initialState = {
    foodTypeGroup: null,
    error: null,

    isFetching: false,
    isDeleting: null,
    isUpdating: null,
    isAdding: null,

    deleteFoodTypeGroupSuccess: false,
    updateFoodTypeGroupSuccess: false,
    addFoodTypeGroupSuccess: false,
};


const foodTypeGroup = (state = initialState, { type, payload, error }) => {
    console.log("McBummyBumBum1");
    console.log(type);
    console.log(payload);
    console.log(error);
    console.log("McBummyBumBum2");
    switch (type) {
        case UPDATE_FOOD_TYPE_GROUP_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case UPDATE_FOOD_TYPE_GROUP_RECEIVE:
            return {
                ...state,
                isUpdating: false,
                foodTypeGroup: payload,
                updateFoodTypeGroupSuccess: true,
            };
        case UPDATE_FOOD_TYPE_GROUP_ERROR:
            return {
                ...state,
                isUpdating: false,
                foodTypeGroup: null,
                error,
            };

        case ADD_FOOD_TYPE_GROUP_REQUEST:
            return {
                ...state,
                isAdding: true,
            };
        case ADD_FOOD_TYPE_GROUP_RECEIVE:
            return {
                ...state,
                isAdding: false,
                addFoodTypeGroupSuccess: true,
            };
        case ADD_FOOD_TYPE_GROUP_ERROR:
            return {
                ...state,
                isAdding: false,
                foodTypeGroup: null,
                error,
            };

        case FOOD_TYPE_GROUP_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case FOOD_TYPE_GROUP_RECEIVE:
            return {
                ...state,
                isFetching: false,
                foodTypeGroup: payload,
            };
        case FOOD_TYPE_GROUP_ERROR:
            return {
                ...state,
                isFetching: false,
                foodTypeGroup: null,
                error,
            };

        case DELETE_FOOD_TYPE_GROUP_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_FOOD_TYPE_GROUP_RECEIVE:
            return {
                ...state,
                isDeleting: false,
                deleteFoodTypeGroupSuccess: true,
            };
        case DELETE_FOOD_TYPE_GROUP_ERROR:
            return {
                ...state,
                isDeleting: false,
                foodTypeGroup: null,
                error,
            };

        case FOOD_TYPE_GROUP_CONFIRM_RESET:
            return {
                ...state,
                deleteFoodTypeGroupSuccess: false,
                addFoodTypeGroupSuccess: false,
                updateFoodTypeGroupSuccess: false
            };
        default:
            return state;
    }
};

export default foodTypeGroup;
