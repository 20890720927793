import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Table, Alert, Button, Col, Row, Container} from 'react-bootstrap'
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getAllContainers,
    resetContainerSuccessState,
} from '../actions/containers';
import Spinner from "../components/spinner";
import ContainerTableRow from "../components/containerTableRow";

class Containers extends Component {
    constructor() {
        super();
        this.state = {
            containerDeleteSuccess: false,
            containerAddSuccess: false
        };
    }

    async componentDidMount() {
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getAllContainers(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.deleteContainerSuccess && prevState.containerDeleteSuccess === false) {
            this.setState({ containerDeleteSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }

        if (this.props.addContainerSuccess && prevState.containerAddSuccess === false) {
            this.setState({ containerAddSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    addContainer () {
        this.props.push(`/containers/add`);
    }

    editContainer (id) {
        console.log(id);
        this.props.push(`/containers/edit/${id}`);
    }

    showResults() {
        let containers = (<div>nothing</div>);
        if (this.props.isFetching) {
            containers = (<Spinner/>);
        } else if (this.props.allContainers) {
            containers = (
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Weight</th>
                        <th>Description</th>
                        <th>Date added</th>
                        <th>Date updated</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.props.allContainers.map((container) => (
                        <ContainerTableRow
                            Container={container}
                            key={container.id}
                            editContainer={() => this.editContainer(container.id)}
                        />)) }
                    </tbody>
                </Table>
            )

        }
        return containers;
    }

    async hideMessages() {
        this.setState({
            containerDeleteSuccess: false,
            containerAddSuccess: false
        });
        this.props.resetContainerSuccessState();
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getAllContainers(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    render() {
        let deleteSuccess = <div/>;
        if (this.state.containerDeleteSuccess) {
            deleteSuccess = <Alert  variant='success' >Delete Success</Alert>;
        }

        let addSuccess = <div/>;
        if (this.state.containerAddSuccess) {
            addSuccess = <Alert  variant='success' >Add Success</Alert>;
        }

        return (
            <div>
                <Container>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Containers
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.showResults()}
                            <div style={{ padding: '0.75em', borderTop: '1px solid #dee2e6' }}>
                                <Button
                                    onClick={e => {this.addContainer()}}
                                >
                                    Add new Container
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '1em' }}>
                        <Col>
                            {deleteSuccess}
                            {addSuccess}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Containers.propTypes = {
    getAllContainers: PropTypes.func.isRequired,
    resetContainerSuccessState: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}

const mapStateToProps = ({ allContainers, container }, ownProps) => ({
    allContainers: allContainers.allContainers,
    isFetching: allContainers.isFetching,
    deleteContainerSuccess: container.deleteContainerSuccess,
    addContainerSuccess: container.addContainerSuccess
});

export default withAuth0(connect(mapStateToProps, {
    getAllContainers,
    resetContainerSuccessState,
    push,
})(Containers));
