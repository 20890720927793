import React from 'react';
import { Badge, Col, Row } from 'react-bootstrap'

const ConsumptionSummary = (props) => {
    // Seriously wtf javascript you baby.
    /** @namespace props.consumptionDataSummary.dayKjCurrentTotal **/
    /** @namespace props.consumptionDataSummary.dayKjRemaining **/
    /** @namespace props.consumptionDataSummary.dayKjGoal **/
    let dayKjCurrentTotal = "-",
        dayKjRemaining = "-",
        dayKjGoal = "-"
    if (props.consumptionDataSummary) {
        console.log("bums");
        dayKjCurrentTotal = props.consumptionDataSummary.dayKjCurrentTotal;
        dayKjRemaining = props.consumptionDataSummary.dayKjRemaining;
        dayKjGoal = props.consumptionDataSummary.dayKjGoal;
    }

    return (
    <Row>
        <Col sm={4} style={{ background: 'linear-gradient(0deg, #86f7a0 25%, rgba(255,255,255,1) 100%)'}}>
            <div style={{ textAlign: 'center', fontSize: '1.5em' }}>Consumed</div>
            <h1>
                <Badge pill style={{ width: '100%' }} variant="success">{ dayKjCurrentTotal } kj</Badge>
            </h1>
        </Col>
        <Col sm={4} style={{ background: 'linear-gradient(0deg, #f5848f 25%, rgba(255,255,255,1) 100%)' }}>
            <div style={{ textAlign: 'center', fontSize: '1.5em' }}>Remaining</div>
            <h1>
                <Badge pill style={{ width: '100%' }} variant="danger">{ dayKjRemaining } j</Badge>
            </h1>
        </Col>
        <Col sm={4} style={{ background: 'linear-gradient(0deg, #83b9f2 25%, rgba(255,255,255,1) 100%)' }}>
            <div style={{ textAlign: 'center', fontSize: '1.5em' }}>Goal Kj</div>
            <h1>
                <Badge pill style={{ width: '100%' }} variant="primary">{ dayKjGoal } kj</Badge>
            </h1>
        </Col>
    </Row>)};

export default ConsumptionSummary;