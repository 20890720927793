import React, {Component} from 'react';
import { connect } from 'react-redux';
import MealMigrateForm from '../components/mealMigrateForm';
import { push } from 'connected-react-router';
import { withAuth0 } from '@auth0/auth0-react';

import {
    addFoodTypeWithMealId,
} from '../actions/foodTypes';

import {
    getMeal,
    getMealKj,
} from '../actions/meals';

import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import PropTypes from "prop-types";
import Spinner from "../components/spinner";

class MealMigrate extends Component {

    async componentDidMount(): void{
        console.log(this.props);
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getMeal(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getMealKj(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);

        console.log(this.props.foodTypeGroups);
    }

    async migrate(state) {
        const {getAccessTokenSilently} = this.props.auth0;
        console.log('add');
        console.log(state);
        this.props.addFoodTypeWithMealId(await getAccessTokenSilently(), state, this.props.meal.id);
        this.props.push(`/meal/${this.props.meal.id}`);
    }

    cancelAdd() {
        console.log('cancel');
        this.props.push(`/meal/${this.props.meal.id}`);
    }

    showForm() {
        if ( this.props.isFetchingMeal || this.props.isFetchingMealKj) {
            return (<Spinner/>);
        } else if ( this.props.meal && this.props.mealKj) {
            return (<MealMigrateForm
                meal = { this.props.meal }
                mealKj = { this.props.mealKj }
                migrate={(state) => this.migrate(state)}
                cancelAdd = {() => this.cancelAdd()}
            />);
        } else {
            return (<div>error</div>);
        }
    }

    render() {
        return (
            <div>
                <Container>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Migrate Meal to FoodItem
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            { this.showForm() }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

MealMigrate.propTypes = {
    addFoodTypeWithMealId: PropTypes.func.isRequired,
}

const mapStateToProps = ({ meal }, ownProps) => ({
    meal: meal.meal,
    mealKj: meal.mealKj,

    isFetchingMeal: meal.isFetchingMeal,
    isFetchingMealKj: meal.isFetchingMealKj
});

export default withAuth0(connect(mapStateToProps, {
    push,
    getMeal,
    getMealKj,
    addFoodTypeWithMealId
})(MealMigrate));
