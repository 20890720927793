import {
    ALL_FOOD_TYPES_REQUEST,
    ALL_FOOD_TYPES_RECEIVE,
    ALL_FOOD_TYPES_ERROR,

    ALL_FOOD_TYPES_CHILDREN_REQUEST,
    ALL_FOOD_TYPES_CHILDREN_RECEIVE,
    ALL_FOOD_TYPES_CHILDREN_ERROR
} from '../constants/ActionTypes';

const initialState = {
    isFetchingAllFoodTypes: false,
    isFetchingAllFoodTypesChildren: false,
    allFoodTypes: null,
    allFoodTypesChildren: null,
    error: null,
};


export default (state = initialState, { type, payload, error }) => {
    switch (type) {
        case ALL_FOOD_TYPES_REQUEST:
            return {
                ...state,
                isFetchingAllFoodTypes: true,
            };
        case ALL_FOOD_TYPES_RECEIVE:
            return {
                ...state,
                isFetchingAllFoodTypes: false,
                allFoodTypes: payload,
                error: null
            };
        case ALL_FOOD_TYPES_ERROR:
            return {
                ...state,
                isFetchingAllFoodTypes: false,
                allFoodTypes: null,
                error,
            };

        case ALL_FOOD_TYPES_CHILDREN_REQUEST:
            return {
                ...state,
                isFetchingAllFoodTypesChildren: true,
            };
        case ALL_FOOD_TYPES_CHILDREN_RECEIVE:
            return {
                ...state,
                isFetchingAllFoodTypesChildren: false,
                allFoodTypesChildren: payload,
                error: null
            };
        case ALL_FOOD_TYPES_CHILDREN_ERROR:
            return {
                ...state,
                isFetchingAllFoodTypesChildren: false,
                allFoodTypesChildren: null,
                error,
            };

        default:
            return state;
    }
};
