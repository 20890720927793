import React, {Component} from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withAuth0 } from '@auth0/auth0-react';

import {
    getFoodTypeGroup,
    deleteFoodTypeGroup,
    updateFoodTypeGroup,
    resetFoodTypeGroupSuccessState
} from '../actions/foodTypeGroup';

import {
    Container,
    Row,
    Col, Alert
} from "react-bootstrap";
import PropTypes from "prop-types";
import Spinner from "../components/spinner"
import EditFoodTypeGroupForm from "../components/editFoodTypeGroupForm";

class EditFoodTypeGroup extends Component {
    constructor(props) {
        super(props);
        // default state
        this.state = {
            foodTypeGroupUpdateSuccess: false,
        };
    }

    async componentDidMount(): void{
        console.log(this.props);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.getFoodTypeGroup(await getAccessTokenSilently(), this.props.match.params.foodTypeGroupId, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.updateFoodTypeGroupSuccess && prevState.foodTypeGroupUpdateSuccess === false) {
            this.setState({ foodTypeGroupUpdateSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    async deleteFoodTypeGroup(foodTypeGroupId) {
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.deleteFoodTypeGroup(await getAccessTokenSilently(), foodTypeGroupId);
        this.props.push(`/foodTypeGroups`);
    }

    async updateFoodTypeGroup(foodTypeGroupId, state) {
        console.log('update');
        console.log(state);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.updateFoodTypeGroup(await getAccessTokenSilently(), foodTypeGroupId, state);
    }

    cancelEdit() {
        console.log('cancel');
        this.props.push(`/foodTypeGroups`);
    }

    showForm() {
        if (this.props.isFetching) {
            return (<Spinner/>);
        } else if (this.props.foodTypeGroup) {
            return (
                <div>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Edit FoodTypeGroup #{this.props.foodTypeGroup.foodTypeGroupId}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EditFoodTypeGroupForm
                                foodTypeGroup={this.props.foodTypeGroup}
                                deleteFoodTypeGroup={(foodTypeGroupId) => this.deleteFoodTypeGroup(foodTypeGroupId)}
                                updateFoodTypeGroup={(foodTypeGroupId, state) => this.updateFoodTypeGroup(foodTypeGroupId, state)}
                                cancelEdit = {() => this.cancelEdit()}
                            />
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (<div>error</div>);
        }

    }

    hideMessages() {
        this.setState({
            foodTypeGroupUpdateSuccess: false,
        });
        this.props.resetFoodTypeGroupSuccessState();
    }

    render() {
        let editSuccess = <div/>;
        if (this.state.foodTypeGroupUpdateSuccess) {
            editSuccess = <Alert variant='success' >Update Success</Alert>;
        }
        return (
            <Container>
                { this.showForm() }
                <Row style={{ paddingTop: '1em' }}>
                    <Col>
                        { editSuccess }
                    </Col>
                </Row>
            </Container>
        );
    }
}

EditFoodTypeGroup.propTypes = {
    getFoodTypeGroup: PropTypes.func.isRequired,
    deleteFoodTypeGroup: PropTypes.func.isRequired,
    updateFoodTypeGroup: PropTypes.func.isRequired,
    resetFoodTypeGroupSuccessState: PropTypes.func.isRequired
}

const mapStateToProps = ({ foodTypeGroup }, ownProps) => ({
    foodTypeGroup: foodTypeGroup.foodTypeGroup,
    isFetching: foodTypeGroup.isFetching,
    updateFoodTypeGroupSuccess: foodTypeGroup.updateFoodTypeGroupSuccess
});

export default withAuth0(connect(mapStateToProps, {
    push,
    getFoodTypeGroup,
    deleteFoodTypeGroup,
    updateFoodTypeGroup,
    resetFoodTypeGroupSuccessState
})(EditFoodTypeGroup));
