import {
    ALL_CONTAINERS_REQUEST,
    ALL_CONTAINERS_RECEIVE,
    ALL_CONTAINERS_ERROR,
} from '../constants/ActionTypes';

const initialState = {
    isFetching: false,
    allContainers: null,
    error: null,
};

export default (state = initialState, { type, payload, error }) => {
    console.log("Doinka");
    switch (type) {
        case ALL_CONTAINERS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case ALL_CONTAINERS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                allContainers: payload,
            };
        case ALL_CONTAINERS_ERROR:
            return {
                ...state,
                isFetching: false,
                error,
            };
        default:
            return state;
    }
};
