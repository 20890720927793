import React, {Component} from 'react';
import { connect } from 'react-redux';
import DayDataView from "../components/dayDataView";
import {Alert, Col, Container, Row} from "react-bootstrap";
import { push } from 'connected-react-router';
import {withAuth0} from "@auth0/auth0-react";

import {
    getAllConsumptionData,
    getAllKjConsumptionData,
    getConsumptionDatesWithData,
    deleteConsumptionData,
    resetConsumptionDataSuccessState,
} from '../actions/consumptionData';

import {
    getAllFoodTypesWithOutChildren,
} from '../actions/foodTypes';

import {
    getAllDayEvents,
} from '../actions/dayEvents';

import {
    setDayBrowserDate
} from '../actions/dayBroswer'


class DayLogs extends Component {
    constructor() {
        super();
        this.state = {
            consumptionDataDeleteSuccess: false,
        };
    }

    async componentDidMount() {
        console.log('componentDidMount');
        console.log(this.props.date);
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getConsumptionDatesWithData(await getAccessTokenSilently(), 180, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`)
        this.props.getAllFoodTypesWithOutChildren(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getAllDayEvents(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        await this.retrieveDateData(this.props.date);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        console.log('componentDidUpdate')
        if (this.props.deleteConsumptionDataSuccess && prevState.consumptionDataDeleteSuccess === false) {
            console.log('setting timeout');
            this.setState({ consumptionDataDeleteSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    getDateString(date) {
        function pad(n) {
            return n < 10 ? "0"+n : n;
        }
        return date.getFullYear()+"-"+pad(date.getMonth()+1)+"-"+pad(date.getDate());
    }

    async retrieveDateData (date) {
        console.log('retrieveDateData');
        console.log(date);
        this.props.setDayBrowserDate(date);
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getAllConsumptionData(await getAccessTokenSilently(), this.getDateString(date), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getAllKjConsumptionData(await getAccessTokenSilently(), this.getDateString(date), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    async deleteConsumptionData (id) {
        console.log('deleteConsumptionData');
        console.log(id);
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.deleteConsumptionData(await getAccessTokenSilently(), id);
    }

    editConsumptionData (id) {
        console.log("editConsumptionData");
        console.log(id);
        this.props.push(`/consumptionData/edit/${id}`);
    }

    async hideMessages() {
        console.log('hideMessagesTwo');
        this.setState({
            consumptionDataDeleteSuccess: false,
        });
        this.props.resetConsumptionDataSuccessState();
        await this.retrieveDateData(this.props.date)
    }

    render() {
        let deleteSuccess = <div/>;
        if (this.state.consumptionDataDeleteSuccess) {
            console.log('show delete success');
            deleteSuccess = <Alert  variant='success' >Delete Success</Alert>;
        }

        //  Combine all fetching to pass a single value to component
        let isFetching = false;
        if (this.props.isFetchingAllConsumptionData === true ||
            this.props.isFetchingAllFoodTypes === true ||
            this.props.isFetchingAllDayEvents === true ||
            this.props.isFetchingConsumptionDaysWithData === true ||
            this.props.isFetchingKjConsumptionData === true){
            isFetching = true;
        }


        return (<div>
            <Container>
                <Row style={{ paddingTop: '2em' }}>
                    <Col>
                        <h2>
                            DayLogs
                        </h2>
                    </Col>
                </Row>
                <DayDataView
                    date = { this.props.date }
                    retrieveDateData = {(date) => this.retrieveDateData(date)}
                    isFetching = { isFetching }
                    consumptionDaysWithData = {this.props.consumptionDaysWithData}
                    allConsumptionData = { this.props.allConsumptionData }
                    allKjConsumptionData = { this.props.allKjConsumptionData }
                    allFoodTypes = { this.props.allFoodTypes }
                    allDayEvents = { this.props.allDayEvents }
                    editConsumptionData={(id) => this.editConsumptionData(id)}
                    deleteConsumptionData = {(id) => this.deleteConsumptionData(id)}
                />
                <Row style={{ paddingTop: '1em' }}>
                    <Col>
                        {deleteSuccess}
                    </Col>
                </Row>
            </Container>
        </div>);
    }
}

const mapStateToProps = ({ allConsumptionData, consumptionData, allFoodTypes, allDayEvents, dayBrowser }, ownProps) => ({
    isFetchingAllConsumptionData: allConsumptionData.isFetching,
    isFetchingKjConsumptionData: allConsumptionData.isFetchingKjConsumptionData,
    isFetchingConsumptionDaysWithData: consumptionData.isFetchingConsumptionDaysWithData,
    allConsumptionData: allConsumptionData.allConsumptionData,
    allKjConsumptionData: allConsumptionData.allKjConsumptionData,
    consumptionDaysWithData: consumptionData.consumptionDaysWithData,
    deleteConsumptionDataSuccess: consumptionData.deleteConsumptionDataSuccess,
    allFoodTypes: allFoodTypes.allFoodTypes,
    allDayEvents: allDayEvents.allDayEvents,
    isFetchingAllFoodTypes: allFoodTypes.isFetchingAllFoodTypes,
    isFetchingAllDayEvents: allDayEvents.isFetching,
    date: dayBrowser.date
});

export default withAuth0(connect(mapStateToProps, {
    getAllConsumptionData,
    getAllKjConsumptionData,
    getConsumptionDatesWithData,
    getAllDayEvents,
    deleteConsumptionData,
    resetConsumptionDataSuccessState,
    getAllFoodTypesWithOutChildren,
    setDayBrowserDate,
    push
})(DayLogs));