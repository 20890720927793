import {
    GET_CONTAINER_REQUEST,
    GET_CONTAINER_RECEIVE,
    GET_CONTAINER_ERROR,
    DELETE_CONTAINER_REQUEST,
    DELETE_CONTAINER_RECEIVE,
    DELETE_CONTAINER_ERROR,
    UPDATE_CONTAINER_REQUEST,
    UPDATE_CONTAINER_RECEIVE,
    UPDATE_CONTAINER_ERROR,
    ADD_CONTAINER_REQUEST,
    ADD_CONTAINER_RECEIVE,
    ADD_CONTAINER_ERROR,

    CONTAINER_CONFIRM_RESET,
} from '../constants/ActionTypes';

const initialState = {
    container: null,
    error: null,

    isFetching: false,
    isDeleting: null,
    isUpdating: null,
    isAdding: null,

    deleteContainerSuccess: false,
    updateContainerSuccess: false,
    addContainerSuccess: false,
};


const container = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case UPDATE_CONTAINER_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case UPDATE_CONTAINER_RECEIVE:
            return {
                ...state,
                isUpdating: false,
                container: payload,
                updateContainerSuccess: true,
            };
        case UPDATE_CONTAINER_ERROR:
            return {
                ...state,
                isUpdating: false,
                container: null,
                error,
            };

        case ADD_CONTAINER_REQUEST:
            return {
                ...state,
                isAdding: true,
            };
        case ADD_CONTAINER_RECEIVE:
            return {
                ...state,
                isAdding: false,
                addContainerSuccess: true,
            };
        case ADD_CONTAINER_ERROR:
            return {
                ...state,
                isAdding: false,
                container: null,
                error,
            };

        case GET_CONTAINER_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case GET_CONTAINER_RECEIVE:
            return {
                ...state,
                isFetching: false,
                container: payload,
            };
        case GET_CONTAINER_ERROR:
            return {
                ...state,
                isFetching: false,
                container: null,
                error,
            };

        case DELETE_CONTAINER_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_CONTAINER_RECEIVE:
            return {
                ...state,
                isDeleting: false,
                deleteContainerSuccess: true,
            };
        case DELETE_CONTAINER_ERROR:
            return {
                ...state,
                isDeleting: false,
                container: null,
                error,
            };

        case CONTAINER_CONFIRM_RESET:
            return {
                ...state,
                deleteContainerSuccess: false,
                addContainerSuccess: false,
                updateContainerSuccess: false
            };
        default:
            return state;
    }
};

export default container;
