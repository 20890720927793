import {
    ALL_DAY_EVENTS_REQUEST,
    ALL_DAY_EVENTS_RECEIVE,
    ALL_DAY_EVENTS_ERROR,
} from '../constants/ActionTypes';

const initialState = {
    isFetching: false,
    allDayEvents: null,
    error: null,
};

export default (state = initialState, { type, payload, error }) => {
    console.log("Doinka");
    switch (type) {
        case ALL_DAY_EVENTS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case ALL_DAY_EVENTS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                allDayEvents: payload,
            };
        case ALL_DAY_EVENTS_ERROR:
            return {
                ...state,
                isFetching: false,
                error,
            };
        default:
            return state;
    }
};
