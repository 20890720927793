import {
    GET_USER_SETTINGS_REQUEST,
    GET_USER_SETTINGS_RECEIVE,
    GET_USER_SETTINGS_ERROR,
    UPDATE_USER_SETTINGS_REQUEST,
    UPDATE_USER_SETTINGS_RECEIVE,
    UPDATE_USER_SETTINGS_ERROR,
    USER_SETTINGS_CONFIRM_RESET
} from '../constants/ActionTypes';

const initialState = {
    userSettings: null,
    error: null,

    isFetching: false,
    isUpdating: null,

    updateDayEventSuccess: false,
};


const userSettings = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case UPDATE_USER_SETTINGS_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case UPDATE_USER_SETTINGS_RECEIVE:
            return {
                ...state,
                isUpdating: false,
                userSettings: payload,
                updateUserSettingsSuccess: true,
            };
        case UPDATE_USER_SETTINGS_ERROR:
            return {
                ...state,
                isUpdating: false,
                userSettings: null,
                error,
            };

        case GET_USER_SETTINGS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case GET_USER_SETTINGS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                userSettings: payload,
            };
        case GET_USER_SETTINGS_ERROR:
            return {
                ...state,
                isFetching: false,
                userSettings: null,
                error,
            };

        case USER_SETTINGS_CONFIRM_RESET:
            return {
                ...state,
                updateUserSettingsSuccess: false
            };
        default:
            return state;
    }
};

export default userSettings;
