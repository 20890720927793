import React from 'react';
import PropTypes from 'prop-types'

const DropdownOption = (props) =>
    <option
        value={props.value}
    >
        {props.label}
    </option>;

DropdownOption.propTypes = {
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
};

export default DropdownOption;