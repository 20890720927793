import { createAction } from 'redux-api-middleware';

import {
    GET_USER_SETTINGS_REQUEST,
    GET_USER_SETTINGS_RECEIVE,
    GET_USER_SETTINGS_ERROR,
    UPDATE_USER_SETTINGS_REQUEST,
    UPDATE_USER_SETTINGS_RECEIVE,
    UPDATE_USER_SETTINGS_ERROR,
    USER_SETTINGS_CONFIRM_RESET
} from '../constants/ActionTypes';

export const getUserSettings = (token) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/UserSettings`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_USER_SETTINGS_REQUEST,
        GET_USER_SETTINGS_RECEIVE,
        GET_USER_SETTINGS_ERROR,
    ]
});

export const updateUserSettings = (token, data) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/UserSettings`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        UPDATE_USER_SETTINGS_REQUEST,
        UPDATE_USER_SETTINGS_RECEIVE,
        UPDATE_USER_SETTINGS_ERROR,
    ]
});

export const resetUserSettingsSuccessState = () => ({
    type: USER_SETTINGS_CONFIRM_RESET,
});