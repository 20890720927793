import { createAction } from 'redux-api-middleware';

import {
    ALL_CONSUMPTION_DATA_REQUEST,
    ALL_CONSUMPTION_DATA_RECEIVE,
    ALL_CONSUMPTION_DATA_ERROR,

    ALL_KJ_CONSUMPTION_DATA_REQUEST,
    ALL_KJ_CONSUMPTION_DATA_RECEIVE,
    ALL_KJ_CONSUMPTION_DATA_ERROR,

    GET_KJ_CONSUMPTION_DATA_REQUEST,
    GET_KJ_CONSUMPTION_DATA_RECEIVE,
    GET_KJ_CONSUMPTION_DATA_ERROR,

    GET_CONSUMPTION_DATA_REQUEST,
    GET_CONSUMPTION_DATA_RECEIVE,
    GET_CONSUMPTION_DATA_ERROR,

    ADD_CONSUMPTION_DATA_REQUEST,
    ADD_CONSUMPTION_DATA_RECEIVE,
    ADD_CONSUMPTION_DATA_ERROR,

    DELETE_CONSUMPTION_DATA_REQUEST,
    DELETE_CONSUMPTION_DATA_RECEIVE,
    DELETE_CONSUMPTION_DATA_ERROR,

    UPDATE_CONSUMPTION_DATA_REQUEST,
    UPDATE_CONSUMPTION_DATA_RECEIVE,
    UPDATE_CONSUMPTION_DATA_ERROR,

    GET_CONSUMPTION_DATA_SUMMARY_REQUEST,
    GET_CONSUMPTION_DATA_SUMMARY_RECEIVE,
    GET_CONSUMPTION_DATA_SUMMARY_ERROR,

    GET_CONSUMPTION_DATA_KJ_SUMMARY_REQUEST,
    GET_CONSUMPTION_DATA_KJ_SUMMARY_RECEIVE,
    GET_CONSUMPTION_DATA_KJ_SUMMARY_ERROR,

    GET_CONSUMPTION_DAYS_WITH_DATA_REQUEST,
    GET_CONSUMPTION_DAYS_WITH_DATA_RECEIVE,
    GET_CONSUMPTION_DAYS_WITH_DATA_ERROR,

    CONSUMPTION_DATA_CONFIRM_RESET,
} from '../constants/ActionTypes';



export const getAllConsumptionData = (token, date, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Consumption?date=${date}&timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        ALL_CONSUMPTION_DATA_REQUEST,
        ALL_CONSUMPTION_DATA_RECEIVE,
        ALL_CONSUMPTION_DATA_ERROR
    ]
});

export const getAllKjConsumptionData = (token, date, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Consumption/kjComponent?date=${date}&timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        ALL_KJ_CONSUMPTION_DATA_REQUEST,
        ALL_KJ_CONSUMPTION_DATA_RECEIVE,
        ALL_KJ_CONSUMPTION_DATA_ERROR
    ]
});

export const getKjConsumptionData = (token, id, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Consumption/${id}/kjData?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_KJ_CONSUMPTION_DATA_REQUEST,
        GET_KJ_CONSUMPTION_DATA_RECEIVE,
        GET_KJ_CONSUMPTION_DATA_ERROR
    ]
});

export const getConsumptionData = (token, id, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Consumption/${id}?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_CONSUMPTION_DATA_REQUEST,
        GET_CONSUMPTION_DATA_RECEIVE,
        GET_CONSUMPTION_DATA_ERROR
    ]
});

export const addConsumptionData = (token, data) => createAction({
        endpoint: `${process.env.REACT_APP_API}api/Consumption`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
        types: [
            ADD_CONSUMPTION_DATA_REQUEST,
            ADD_CONSUMPTION_DATA_RECEIVE,
            ADD_CONSUMPTION_DATA_ERROR
        ]
    });

export const deleteConsumptionData = (token, id) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Consumption/${id}`,
    method: 'DELETE',
    headers: {
        'Authorization': `Bearer ${token}`
    },
    types: [
        DELETE_CONSUMPTION_DATA_REQUEST,
        DELETE_CONSUMPTION_DATA_RECEIVE,
        DELETE_CONSUMPTION_DATA_ERROR
    ]
});

export const updateConsumptionData = (token, id, data, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Consumption/${id}?timezone=${timezone}`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        UPDATE_CONSUMPTION_DATA_REQUEST,
        UPDATE_CONSUMPTION_DATA_RECEIVE,
        UPDATE_CONSUMPTION_DATA_ERROR
    ]
})

export const getConsumptionDataSummary = (token, date, timezone) => createAction({
        endpoint: `${process.env.REACT_APP_API}api/Consumption/summary?date=${date}&timezone=${timezone}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        types: [
            GET_CONSUMPTION_DATA_SUMMARY_REQUEST,
            GET_CONSUMPTION_DATA_SUMMARY_RECEIVE,
            GET_CONSUMPTION_DATA_SUMMARY_ERROR
        ]
    })

export const getConsumptionDataKjSummary = (token, date,timezone) => createAction({
        endpoint: `${process.env.REACT_APP_API}api/Consumption/kjdata?date=${date}&timezone=${timezone}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        types: [
            GET_CONSUMPTION_DATA_KJ_SUMMARY_REQUEST,
            GET_CONSUMPTION_DATA_KJ_SUMMARY_RECEIVE,
            GET_CONSUMPTION_DATA_KJ_SUMMARY_ERROR
        ]
    })

export const getConsumptionDatesWithData = (token, daysBack, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Consumption/datesWithData?daysBack=${daysBack}&timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_CONSUMPTION_DAYS_WITH_DATA_REQUEST,
        GET_CONSUMPTION_DAYS_WITH_DATA_RECEIVE,
        GET_CONSUMPTION_DAYS_WITH_DATA_ERROR
    ]
})


export const resetConsumptionDataSuccessState = () => ({
    type: CONSUMPTION_DATA_CONFIRM_RESET,
});