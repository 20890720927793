import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
    Form,
    Container,
    Row,
    Col,
    Button
} from 'react-bootstrap';

class MealMigrateForm extends Component {
    constructor(props) {
        console.log("derpa");
        console.log(props);

        // Load saved state or a default state when page loaded
        super(props);

        let date = new Date();
        date = date.toLocaleDateString('en-GB').split('/').reverse().join('');
        let kjPerHundredGrams = props.mealKj.energyTotal / props.meal.totalWeight * 100;


        kjPerHundredGrams = this.round(kjPerHundredGrams, 2);


        this.state = {
            name: `${this.props.meal.name} ${date}`,
            description: '',
            kjPerHundredGrams: kjPerHundredGrams,
            kjPerServe: 0,
            foodTypeGroupIds: []
        };

        if (process.env.REACT_APP_AUDIENCE) {
            this.state.foodTypeGroupIds.push(parseInt(process.env.REACT_APP_MEAL_GROUP_ID));
        }
    }

    round(num, decimalPlaces = 0) {
        var p = Math.pow(10, decimalPlaces);
        var n = (num * p) * (1 + Number.EPSILON);
        return Math.round(n) / p;
    }

    migrate() {
        const tempState = this.state;
        tempState.foodTypeGroupIds = tempState.foodTypeGroupIds.map((i) => Number(i));
        this.props.migrate(this.state);
    }


    cancelAdd() {
        this.props.cancelAdd()
    }

    render() {
        console.log(this.state);
        return (
            <Container>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.migrate();
                    }}
                >
                    <Form.Group as={Row} controlId="name" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Name
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.name}
                                onChange={e => {
                                    this.setState({ name: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="description" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Description
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.description}
                                onChange={e => {
                                    this.setState({ description: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Row style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Group as={Col} sm={{ span: 5, offset: 2 }} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="createdAt">
                            <Form.Label>Total KJ</Form.Label>
                            <Form.Control
                                type="text"
                                value={this.props.mealKj.energyTotal}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={{ span: 5}} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="updatedAt">
                            <Form.Label>Total Weight</Form.Label>
                            <Form.Control
                                type="text"
                                // On update this damn string always gets extra accuracy added, debugging the C# API shows no added accuracy, have no idea :(
                                value={this.props.meal.totalWeight}
                                disabled
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group as={Row} style={{ margin: '0.2em', paddingBottom: '2em' }}>
                        <Col sm={{span: 10, offset: 2}}>
                            <span style={{ color: 'red', fontWeight: 'bold' }}> Note</span>: Total KJ or Total Weight must be edited on Meal Page before Migration.
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="kjPerHundredGrams" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            kg/100gm
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.kjPerHundredGrams}
                                onChange={e => {
                                    // TODO add some checking around types!!!
                                    this.setState({ kjPerHundredGrams: parseInt(e.target.value) });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} style={{ margin: '0.2em', paddingTop: '1em' }}>
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button variant="success" type="submit">Migrate</Button>
                            <Button
                                variant="secondary"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.cancelAdd();
                                    console.log('return');
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

MealMigrateForm.propTypes = {
    migrate: PropTypes.func.isRequired,
    cancelAdd: PropTypes.func.isRequired,
}

export default (MealMigrateForm);