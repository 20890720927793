import React, {Component} from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withAuth0 } from '@auth0/auth0-react';
import EditConsumptionDataForm from "../components/editConsumptionDataForm";

import {
    getAllDayEvents,
} from '../actions/dayEvents';

import {
    getAllFoodTypesWithOutChildren,
} from '../actions/foodTypes';

import {
    getKjConsumptionData,
    getConsumptionData,
    updateConsumptionData,
    deleteConsumptionData,
    resetConsumptionDataSuccessState
} from '../actions/consumptionData';

import {
    Container,
    Row,
    Col, Alert
} from "react-bootstrap";
import Spinner from "../components/spinner"


class EditConsumptionData extends Component {
    constructor(props) {
        super(props);
        // default state
        this.state = {
            consumptionDataUpdateSuccess: false,
        };
    }

    async componentDidMount(): void{
        console.log(this.props);
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getConsumptionData(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getKjConsumptionData(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getAllDayEvents(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getAllFoodTypesWithOutChildren(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        console.log(this.props.dayEvent);
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        if (this.props.updateConsumptionDataSuccess && prevState.consumptionDataUpdateSuccess === false) {
            this.setState({ consumptionDataUpdateSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    async deleteConsumptionData(id) {
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.deleteConsumptionData(await getAccessTokenSilently(), id);
        this.props.push(`/dayLogs`);
    }

    async updateConsumptionData(id, state) {
        console.log('update');
        console.log(state);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.updateConsumptionData(await getAccessTokenSilently(), id, state, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    cancelEdit() {
        console.log('cancel');
        this.props.push(`/dayLogs`);
    }

    showForm() {
        if (this.props.isFetchingConsumptionData || this.props.isFetchingAllDayEvents || this.props.isFetchingAllFoodTypes ) {
            return (<Spinner/>);
        } else if (this.props.consumptionData) {
            return (
                <div>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Edit ConsumptionData #{this.props.consumptionData.id}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EditConsumptionDataForm
                                consumptionData={this.props.consumptionData}
                                kjConsumptionData={this.props.kjConsumptionData}
                                isFetchingKjConsumptionData={this.props.isFetchingKjConsumptionData}
                                deleteConsumptionData={(id) => this.deleteConsumptionData(id)}
                                updateConsumptionData={(id, state) => this.updateConsumptionData(id, state)}
                                cancelEdit = {() => this.cancelEdit()}
                                allFoodTypes = {this.props.allFoodTypes}
                                allDayEvents = {this.props.allDayEvents}
                            />
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (<div>error</div>);
        }

    }

    async hideMessages() {
        console.log("hideMessagesThree");
        this.setState({
            consumptionDataUpdateSuccess: false,
        });
        this.props.resetConsumptionDataSuccessState();
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getKjConsumptionData(await getAccessTokenSilently(), this.props.match.params.id, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    render() {
        console.log("butfuck");
        console.log(this.props);
        let editSuccess = <div/>;
        if (this.state.consumptionDataUpdateSuccess) {
            editSuccess = <Alert variant='success' >Update Success</Alert>;
        }
        return (
            <Container>
                { this.showForm() }
                <Row style={{ paddingTop: '1em' }}>
                    <Col>
                        { editSuccess }
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ consumptionData, allDayEvents, allFoodTypes  }, ownProps) => ({
    allDayEvents: allDayEvents.allDayEvents,
    isFetchingAllDayEvents: allDayEvents.isFetching,
    allFoodTypes: allFoodTypes.allFoodTypes,
    isFetchingAllFoodTypes: allFoodTypes.isFetchingAllFoodTypes,

    kjConsumptionData: consumptionData.kjConsumptionData,
    isFetchingKjConsumptionData: consumptionData.isFetchingKjConsumptionData,
    consumptionData: consumptionData.consumptionData,
    isFetchingConsumptionData: consumptionData.isFetchingConsumptionData,
    updateConsumptionDataSuccess: consumptionData.updateConsumptionDataSuccess
});

export default withAuth0(connect(mapStateToProps, {
    push,
    getAllDayEvents,
    getAllFoodTypesWithOutChildren,
    getKjConsumptionData,
    getConsumptionData,
    updateConsumptionData,
    deleteConsumptionData,
    resetConsumptionDataSuccessState
})(EditConsumptionData));
