import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Container } from 'react-bootstrap'
import AddConsumptionData from './AddConsumptionData';
import { getConsumptionDataSummary, getConsumptionDataKjSummary } from "../actions/consumptionData";
import  ConsumptionSummary from "../components/consumptionSummary";
import  Spinner from "../components/spinner";
import ConsumptionLineChart from "../components/consumptionLineChart";
import ConsumptionColumnChart from "../components/consumptionColumnChart"
import { withAuth0 } from '@auth0/auth0-react';

class Home extends Component {
    constructor() {
        super();
        this.state = {
            consumptionDataAddSuccess: false
        };
    }

    getDateString() {
        let date = new Date();
        function pad(n) {
            return n < 10 ? "0"+n : n;
        }
        return date.getFullYear()+"-"+pad(date.getMonth()+1)+"-"+pad(date.getDate());
    }

    async componentDidMount() {
        console.log("Home props")
        console.log(this.props)
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getConsumptionDataSummary(await getAccessTokenSilently(), this.getDateString(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getConsumptionDataKjSummary(await getAccessTokenSilently(), this.getDateString(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    async componentDidUpdate(prevProps, prevState, nextContent) {
        const {getAccessTokenSilently} = this.props.auth0;
        console.log('souuuuuup lolz');
        if (this.props.reloadConsumptionDataSummary === true && this.props.isFetchingConsumptionDataSummary === false) {
            this.props.getConsumptionDataSummary(await getAccessTokenSilently(), this.getDateString(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        }
        if (this.props.reloadConsumptionDataKjSummary === true && this.props.isFetchingConsumptionDataKjSummary === false) {
            this.props.getConsumptionDataKjSummary(await getAccessTokenSilently(), this.getDateString(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        }
    }

    render() {
        let consumptionDataSummary = <div />,
            consumptionColumnChart = <div />,
            fetchingConsumptionDataSummary = <div />;
        if (this.props.isFetchingConsumptionDataSummary) {
            consumptionDataSummary = <ConsumptionSummary/>
            fetchingConsumptionDataSummary = <Spinner />;
        } else if (this.props.consumptionDataSummary) {
            consumptionDataSummary = <ConsumptionSummary
                consumptionDataSummary={this.props.consumptionDataSummary}
            />
            console.log('YYYAAAAAARRRGGGGG');
            console.log(this.props);

            consumptionColumnChart = <ConsumptionColumnChart
                consumptionDataSummary={this.props.consumptionDataSummary}
            />
        }

        let consumptionDataLineChart = <div />,
            fetchingConsumptionDataLineChart = <div />;
        if (this.props.isFetchingConsumptionDataKjSummary) {
            fetchingConsumptionDataLineChart = <Spinner />;
        } else if (this.props.consumptionDataKjSummary) {
            consumptionDataLineChart = <ConsumptionLineChart
                key = {this.props.consumptionDataKjSummary.length}
                consumptionDataKjSummary={this.props.consumptionDataKjSummary}
            />
        }

        return (
        <Container style={{ paddingBottom: '5em' }}>
            <Row style={{ paddingTop: '2em' }}>
                <Col><h2>Day Stats</h2></Col>
            </Row>
            { consumptionDataSummary }
            { fetchingConsumptionDataSummary }
            <Row style={{ paddingTop: '2em' }}>
                <Col>
                    <h2>Add Consumption Data</h2>
                    <AddConsumptionData/>
                </Col>
            </Row>
            { consumptionColumnChart }
            { consumptionDataLineChart }
            { fetchingConsumptionDataLineChart }
        </Container>
        )
    }
}

const mapStateToProps = ({ consumptionData }, ownProps) => ({
    isFetchingConsumptionDataSummary: consumptionData.isFetchingConsumptionDataSummary,
    consumptionDataSummary: consumptionData.consumptionDataSummary,
    reloadConsumptionDataSummary: consumptionData.reloadConsumptionDataSummary,
    isFetchingConsumptionDataKjSummary: consumptionData.isFetchingConsumptionDataKjSummary,
    consumptionDataKjSummary: consumptionData.consumptionDataKjSummary,
    reloadConsumptionDataKjSummary: consumptionData.reloadConsumptionDataKjSummary,
});

export default withAuth0(connect(mapStateToProps, {
    getConsumptionDataSummary,
    getConsumptionDataKjSummary
})(Home));