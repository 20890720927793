import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
    Form,
    Container,
    Row,
    Col,
    Button,
} from 'react-bootstrap';

class AddDayEventForm extends Component {
    constructor(props) {
        console.log(props);

        // Load saved state or a default state when page loaded
        super(props);

        this.state = {
            name: '',
            description: '',
        };
    }

    addDayEvent() {
        this.props.addDayEvent(this.state);
    }


    cancelAdd() {
        this.props.cancelAdd()
    }

    render() {
        console.log(this.state);
        return (
            <Container>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.addDayEvent();
                    }}
                >
                    <Form.Group as={Row} controlId="name" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Name
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.name}
                                onChange={e => {
                                    this.setState({ name: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="description" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Description
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.description}
                                onChange={e => {
                                    this.setState({ description: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{ margin: '0.2em', paddingTop: '1em' }}>
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit">Add</Button>
                            <Button
                                variant="secondary"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.cancelAdd();
                                    console.log('return');
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

AddDayEventForm.propTypes = {
    addDayEvent: PropTypes.func.isRequired,
    cancelAdd: PropTypes.func.isRequired,
}

export default (AddDayEventForm);