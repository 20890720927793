import React, {Component} from 'react';
import { Col, Row } from 'react-bootstrap'
import { Chart } from 'react-charts'

export default function ConsumptionColumnChart (props) {
    console.log("props column");
    console.log(props);



    let graphPoints = [];
    let max = 0;
        if (props.consumptionDataSummary.dayEventSummaryData) {
            console.log(props.consumptionDataSummary.dayEventSummaryData.length);
            for (let i = 0; i < props.consumptionDataSummary.dayEventSummaryData.length; i++) {
                graphPoints.push({primary: props.consumptionDataSummary.dayEventSummaryData[i].name, secondary:props.consumptionDataSummary.dayEventSummaryData[i].energyTotal + 1})
                if (props.consumptionDataSummary.dayEventSummaryData[i].energyTotal > max) max = props.consumptionDataSummary.dayEventSummaryData[i].energyTotal;
            }
        }

        console.log('graphPoints');
    console.log(graphPoints);

    let data = React.useMemo(
        () => [
            {
                label: 'Series 1',
                data: graphPoints
            },
        ],
        []
    )

    const series = React.useMemo(
        () => ({
            type: 'bar'
        }),
        []
    )

    let axes = React.useMemo(
        () => [
            { primary: true, type: 'ordinal', position: 'bottom' },
            { position: 'left', type: 'linear', stacked: false, hardMin: 0, hardMax: (max + 100) }
        ],
        []
    )
    console.log('iiiiissss is a looopppp');
    return (
        <Row style={{paddingTop: '2em'}}>
            <Col>
                <div style={{
                    width: '100%',
                    height: '300px'
                }}>
                    <Chart key={props.key} data={data} series={series} axes={axes}/>
                </div>
            </Col>
        </Row>
    )
}
