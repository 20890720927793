import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap'

const ConsumptionDataTableRow = ({ ConsumptionData, FoodType, DayEvent, kjData, editConsumptionData, deleteConsumptionData }) => {
    console.log(ConsumptionData);
    console.log(FoodType);
    console.log(DayEvent);
    console.log(kjData);
    return (<tr style={{fontSize: '1em', padding: '0.25em'}}>
        <td style={{color: '#18605A'}}>
            {ConsumptionData.id}
        </td>
        <td>
            {ConsumptionData.dateTime}
        </td>
        <td>
            {FoodType[0].name}
        </td>
        <td>
            {DayEvent[0].name}
        </td>
        <td>
            {ConsumptionData.weightMg}
        </td>
        <td>
            {ConsumptionData.serves}
        </td>
        <td>
            {ConsumptionData.note}
        </td>
        <td>
            {kjData[0].kjValue}
        </td>
        <td>
            <Button onClick={ editConsumptionData } style={{ marginLeft: '.5em', marginTop: '0.25em' }} >Edit</Button>
            <Button variant="danger" onClick={deleteConsumptionData} style={{ marginLeft: '.5em', marginTop: '0.25em' }}>Delete</Button>
        </td>
    </tr>);
}

ConsumptionDataTableRow.propTypes = {
    ConsumptionData: PropTypes.object.isRequired,
    editConsumptionData: PropTypes.func.isRequired,
    deleteConsumptionData: PropTypes.func.isRequired,
};

export default ConsumptionDataTableRow;
