import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap'

const ContainerTableRow = ({ Container, editContainer }) =>
    <tr style={{fontSize: '1em', padding: '0.25em'}}>
        <td style={{color: '#18605A'}}>
            {Container.id}
        </td>
        <td>
            {Container.name}
        </td>
        <td>
            {Container.totalWeight}
        </td>
        <td>
            {Container.description}
        </td>
        <td>
            {Container.createdAt}
        </td>
        <td>
            {Container.updatedAt}
        </td>
        <td>
            <Button onClick={editContainer}>Edit</Button>
        </td>
    </tr>;

ContainerTableRow.propTypes = {
    Container: PropTypes.object.isRequired,
    editContainer: PropTypes.func.isRequired,
};

export default ContainerTableRow;
