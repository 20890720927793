import {
    DAY_EVENT_REQUEST,
    DAY_EVENT_RECEIVE,
    DAY_EVENT_ERROR,
    DELETE_DAY_EVENT_REQUEST,
    DELETE_DAY_EVENT_RECEIVE,
    DELETE_DAY_EVENT_ERROR,
    UPDATE_DAY_EVENT_REQUEST,
    UPDATE_DAY_EVENT_RECEIVE,
    UPDATE_DAY_EVENT_ERROR,
    ADD_DAY_EVENT_REQUEST,
    ADD_DAY_EVENT_RECEIVE,
    ADD_DAY_EVENT_ERROR,

    DAY_EVENT_CONFIRM_RESET,
} from '../constants/ActionTypes';

const initialState = {
    dayEvent: null,
    error: null,

    isFetching: false,
    isDeleting: null,
    isUpdating: null,
    isAdding: null,

    deleteDayEventSuccess: false,
    updateDayEventSuccess: false,
    addDayEventSuccess: false,
};


const dayEvent = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case UPDATE_DAY_EVENT_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case UPDATE_DAY_EVENT_RECEIVE:
            return {
                ...state,
                isUpdating: false,
                dayEvent: payload,
                updateDayEventSuccess: true,
            };
        case UPDATE_DAY_EVENT_ERROR:
            return {
                ...state,
                isUpdating: false,
                dayEvent: null,
                error,
            };

        case ADD_DAY_EVENT_REQUEST:
            return {
                ...state,
                isAdding: true,
            };
        case ADD_DAY_EVENT_RECEIVE:
            return {
                ...state,
                isAdding: false,
                addDayEventSuccess: true,
            };
        case ADD_DAY_EVENT_ERROR:
            return {
                ...state,
                isAdding: false,
                dayEvent: null,
                error,
            };

        case DAY_EVENT_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case DAY_EVENT_RECEIVE:
            return {
                ...state,
                isFetching: false,
                dayEvent: payload,
            };
        case DAY_EVENT_ERROR:
            return {
                ...state,
                isFetching: false,
                dayEvent: null,
                error,
            };

        case DELETE_DAY_EVENT_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_DAY_EVENT_RECEIVE:
            return {
                ...state,
                isDeleting: false,
                deleteDayEventSuccess: true,
            };
        case DELETE_DAY_EVENT_ERROR:
            return {
                ...state,
                isDeleting: false,
                dayEvent: null,
                error,
            };

        case DAY_EVENT_CONFIRM_RESET:
            return {
                ...state,
                deleteDayEventSuccess: false,
                addDayEventSuccess: false,
                updateDayEventSuccess: false
            };
        default:
            return state;
    }
};

export default dayEvent;
