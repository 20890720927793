import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
    Form,
    Container,
    Row,
    Col,
    Button,
    Badge
} from 'react-bootstrap';
import DropdownOption from "./DropdownOption";

class EditFoodTypeForm extends Component {
    constructor(props) {
        console.log("propity poopfuck")
        console.log(props);
        console.log("propity poopfuck donk")
        // Load saved state or a default state when page loaded
        super(props);

        let foodTypes = [];
        props.foodType.foodItemsFoodTypeGroups.forEach((foodType, index) => foodTypes[index] = foodType.foodTypeGroupId.toString());

        this.state = {
            foodItemsId: props.foodType.foodItemsId,
            name: props.foodType.name,
            description: props.foodType.description,
            kjPerHundredGrams: props.foodType.kjPerHundredGrams,
            kjPerServe: props.foodType.kjPerServe,
            foodTypeGroups: props.foodType.foodItemsFoodTypeGroups,
            createdAt: props.foodType.createdAt,
            updatedAt: props.foodType.updatedAt,

            allFoodTypeGroups: props.foodTypeGroups,
            foodGroupTypeIds: foodTypes
        };

        console.log("two propity poopfuck")
        console.log(this.state);
        console.log("two propity poopfuck donk")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // This is to make sure the updatedAt indicates the latest update.
        const foodType = this.props.foodType;
        if (foodType.updatedAt !== this.state.updatedAt) this.setState({updatedAt: foodType.updatedAt})
    }

    updateFoodItem() {
        this.props.updateFoodItem(this.state.foodItemsId, this.state);
    }

    deleteFoodItem(foodItemsId) {
        console.log('delete' + foodItemsId)
        this.props.deleteFoodItem(foodItemsId)
    }

    cancelEdit() {
        this.props.cancelEdit()
    }

    render() {
        console.log(this.state);
        return (
            <Container>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.updateFoodItem();
                    }}
                >
                    <Form.Group as={Row} controlId="name" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Name
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.name}
                                onChange={e => {
                                    this.setState({ name: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="description" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Description
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.description}
                                onChange={e => {
                                    this.setState({ description: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="kjPerHundredGrams" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            kj/100gm
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.kjPerHundredGrams}
                                onChange={e => {
                                    // TODO add some checking around types!!!
                                    this.setState({ kjPerHundredGrams: parseInt(e.target.value) });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="kjPerServe" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            kj/serve
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.kjPerServe}
                                onChange={e => {
                                    // TODO add some checking around types!!!
                                    this.setState({ kjPerServe: parseInt(e.target.value) });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Row style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Food Groups
                        </Form.Label>
                        <Col sm={5}>
                            { this.state.foodTypeGroups.map((foodTypeGroup) => (
                                <Badge
                                    pill
                                    variant="primary"
                                    key={ foodTypeGroup.foodTypeGroup.foodTypeGroupId}
                                    style={{ fontSize: '1em', marginRight: '1em', marginTop: '0.5em' }}
                                >
                                    { foodTypeGroup.foodTypeGroup.name }
                                </Badge>
                            ))}
                        </Col>
                        <Col sm={5} style={{ paddingTop: '1em' }}>
                            <Form.Control
                                as="select"
                                multiple
                                onChange={e => {
                                    const localState = this.state;
                                    localState.foodGroupTypeIds = [].slice.call(e.target.selectedOptions).map(item => item.value);
                                    console.log([].slice.call(e.target.selectedOptions).map(item => item.value));
                                    this.setState(localState);
                                }}
                                value={this.state.foodGroupTypeIds}
                            >
                                {this.state.allFoodTypeGroups.map(
                                    (allFoodTypeGroup) =>
                                        <DropdownOption
                                            key={allFoodTypeGroup.foodTypeGroupId}
                                            value={allFoodTypeGroup.foodTypeGroupId}
                                            label={allFoodTypeGroup.name}
                                        />
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2} />

                    </Form.Row>
                    <Form.Row style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Group as={Col} sm={{ span: 5, offset: 2 }} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="createdAt">
                            <Form.Label>Created at</Form.Label>
                            <Form.Control
                                type="text"
                                value={this.state.createdAt.substring(0, 19)}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={{ span: 5}} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="updatedAt">
                            <Form.Label>Updated at</Form.Label>
                            <Form.Control
                                type="text"
                                // On update this damn string always gets extra accuracy added, debugging the C# API shows no added accuracy, have no idea :(
                                value={this.state.updatedAt.substring(0, 19)}
                                disabled
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group as={Row} style={{ margin: '0.2em', paddingTop: '1em' }}>
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit">Update</Button>
                            <Button
                                variant="danger"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.deleteFoodItem(this.state.foodItemsId);
                                    console.log('delete');
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="secondary"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.cancelEdit();
                                    console.log('return');
                                }}
                            >
                                Return
                            </Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

EditFoodTypeForm.propTypes = {
    foodType: PropTypes.object.isRequired,
    deleteFoodItem: PropTypes.func.isRequired,
    updateFoodItem: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
}

export default (EditFoodTypeForm);