import {
    FOOD_TYPE_REQUEST,
    FOOD_TYPE_RECEIVE,
    FOOD_TYPE_ERROR,

    DELETE_FOOD_TYPE_REQUEST,
    DELETE_FOOD_TYPE_RECEIVE,
    DELETE_FOOD_TYPE_ERROR,

    UPDATE_FOOD_TYPE_REQUEST,
    UPDATE_FOOD_TYPE_RECEIVE,
    UPDATE_FOOD_TYPE_ERROR,

    UPDATE_FOOD_TYPE_GROUPS_REQUEST,
    UPDATE_FOOD_TYPE_GROUPS_RECEIVE,
    UPDATE_FOOD_TYPE_GROUPS_ERROR,

    ADD_FOOD_TYPE_REQUEST,
    ADD_FOOD_TYPE_RECEIVE,
    ADD_FOOD_TYPE_ERROR,

    FOOD_TYPE_CONFIRM_RESET
} from '../constants/ActionTypes';

const initialState = {
    foodType: null,
    error: null,

    isFetching: false,
    isDeleting: null,
    isUpdating: null,
    isUpdatingFoodTypeGroups: null,
    isAdding: null,

    deleteFoodTypeSuccess: false,
    updateFoodTypeSuccess: false,
    updateFoodTypeGroupsSuccess: false,
    addFoodTypeSuccess: false,
};


const foodType = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case UPDATE_FOOD_TYPE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case UPDATE_FOOD_TYPE_RECEIVE:
            return {
                ...state,
                isUpdating: false,
                foodType: payload,
                updateFoodTypeSuccess: true,
            };
        case UPDATE_FOOD_TYPE_ERROR:
            return {
                ...state,
                isUpdating: false,
                foodType: null,
                error,
            };

        case UPDATE_FOOD_TYPE_GROUPS_REQUEST:
            return {
                ...state,
                isUpdatingFoodTypeGroups: true,
            };
        case UPDATE_FOOD_TYPE_GROUPS_RECEIVE:
            return {
                ...state,
                isUpdatingFoodTypeGroups: false,
                updateFoodTypeGroupsSuccess: true,
            };
        case UPDATE_FOOD_TYPE_GROUPS_ERROR:
            return {
                ...state,
                isUpdatingFoodTypeGroups: false,
                error,
            };

        case ADD_FOOD_TYPE_REQUEST:
            return {
                ...state,
                isAdding: true,
            };
        case ADD_FOOD_TYPE_RECEIVE:
            return {
                ...state,
                isAdding: false,
                // foodType: payload,
                addFoodTypeSuccess: true,
            };
        case ADD_FOOD_TYPE_ERROR:
            return {
                ...state,
                isAdding: false,
                foodType: null,
                error,
            };

        case FOOD_TYPE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case FOOD_TYPE_RECEIVE:
            return {
                ...state,
                isFetching: false,
                foodType: payload,
            };
        case FOOD_TYPE_ERROR:
            return {
                ...state,
                isFetching: false,
                foodType: null,
                error,
            };

        case DELETE_FOOD_TYPE_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_FOOD_TYPE_RECEIVE:
            return {
                ...state,
                isDeleting: false,
                deleteFoodTypeSuccess: true,
            };
        case DELETE_FOOD_TYPE_ERROR:
            return {
                ...state,
                isDeleting: false,
                foodType: null,
                error,
            };

        case FOOD_TYPE_CONFIRM_RESET:
            return {
                ...state,
                deleteFoodTypeSuccess: false,
                addFoodTypeSuccess: false,
                updateFoodTypeSuccess: false
            };

        default:
            return state;
    }
};

export default foodType;
