import { createAction } from 'redux-api-middleware';

import {
    ALL_DAY_EVENTS_REQUEST,
    ALL_DAY_EVENTS_RECEIVE,
    ALL_DAY_EVENTS_ERROR,
    DAY_EVENT_REQUEST,
    DAY_EVENT_RECEIVE,
    DAY_EVENT_ERROR,
    DELETE_DAY_EVENT_REQUEST,
    DELETE_DAY_EVENT_RECEIVE,
    DELETE_DAY_EVENT_ERROR,
    UPDATE_DAY_EVENT_REQUEST,
    UPDATE_DAY_EVENT_RECEIVE,
    UPDATE_DAY_EVENT_ERROR,
    ADD_DAY_EVENT_REQUEST,
    ADD_DAY_EVENT_RECEIVE,
    ADD_DAY_EVENT_ERROR,

    DAY_EVENT_CONFIRM_RESET,
} from '../constants/ActionTypes';


export const getAllDayEvents = (token, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/DayEvents?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        ALL_DAY_EVENTS_REQUEST,
        ALL_DAY_EVENTS_RECEIVE,
        ALL_DAY_EVENTS_ERROR
    ]
})


export const getDayEvent = (token, id, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/DayEvents/${id}?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        DAY_EVENT_REQUEST,
        DAY_EVENT_RECEIVE,
        DAY_EVENT_ERROR
    ]
})

export const deleteDayEvent = (token, id) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/DayEvents/${id}`,
    method: 'DELETE',
    headers: {
        'Authorization': `Bearer ${token}`
    },
    types: [
        DELETE_DAY_EVENT_REQUEST,
        DELETE_DAY_EVENT_RECEIVE,
        DELETE_DAY_EVENT_ERROR,
    ]
});

export const updateDayEvent = (token, id, data) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/DayEvents/${id}`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        UPDATE_DAY_EVENT_REQUEST,
        UPDATE_DAY_EVENT_RECEIVE,
        UPDATE_DAY_EVENT_ERROR,
    ]
})

export const addDayEvent = (token, data) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/DayEvents`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        ADD_DAY_EVENT_REQUEST,
        ADD_DAY_EVENT_RECEIVE,
        ADD_DAY_EVENT_ERROR,
    ]
})


export const resetDayEventSuccessState = () => ({
    type: DAY_EVENT_CONFIRM_RESET,
});