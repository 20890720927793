import React, {Component} from 'react';
import { connect } from 'react-redux';
import AddFoodItemForm from '../components/addFoodTypeForm';
import { push } from 'connected-react-router';
import { withAuth0 } from '@auth0/auth0-react';

import {
    addFoodType,
} from '../actions/foodTypes';

import {
    getFoodTypeGroups
} from '../actions/foodTypeGroup';

import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import PropTypes from "prop-types";
import Spinner from "../components/spinner";

class AddFoodItem extends Component {

    async componentDidMount(): void{
        console.log(this.props);
        const {getAccessTokenSilently} = this.props.auth0;
        await this.props.getFoodTypeGroups(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        console.log(this.props.foodTypeGroups);
    }

    async addFoodItem(state) {
        const {getAccessTokenSilently} = this.props.auth0;
        console.log('add');
        console.log(state);
        this.props.addFoodType(await getAccessTokenSilently(), state);
        this.props.push(`/foodItems`);
    }

    cancelAdd() {
        console.log('cancel');
        this.props.push(`/foodItems`);
    }

    showForm() {
        if ( this.props.isFetchingFoodTypeGroups ) {
            return (<Spinner/>);
        } else if ( this.props.foodTypeGroups ) {
            return (<AddFoodItemForm
                foodTypeGroups = { this.props.foodTypeGroups }
                addFoodItem={(state) => this.addFoodItem(state)}
                cancelAdd = {() => this.cancelAdd()}
            />);
        } else {
            return (<div>error</div>);
        }
    }

    render() {
        return (
            <div>
                <Container>
                    <Row style={{ paddingTop: '2em' }}>
                        <Col>
                            <h2>
                                Add Food Item
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            { this.showForm() }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

AddFoodItem.propTypes = {
    addFoodType: PropTypes.func.isRequired,
    getFoodTypeGroups: PropTypes.func.isRequired
}

const mapStateToProps = ({ foodTypeGroups }, ownProps) => ({
    isFetchingFoodTypeGroups: foodTypeGroups.isFetching,
    foodTypeGroups: foodTypeGroups.foodTypeGroups
});

export default withAuth0(connect(mapStateToProps, {
    push,
    getFoodTypeGroups,
    addFoodType
})(AddFoodItem));
