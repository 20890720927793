import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap'

const MealDataTableRow = ({ MealData, FoodType, kjData, editMealData, deleteMealData }) => {
    console.log(MealData);
    // console.log(FoodType);
    console.log(kjData);
    return (<tr style={{fontSize: '1em', padding: '0.25em'}}>
        <td style={{color: '#18605A'}}>
            {MealData.id}
        </td>
        <td>
            {MealData.dateTime}
        </td>
        <td>
            {FoodType[0].name}
        </td>
        <td>
            {MealData.weightMg}
        </td>
        <td>
            {MealData.note}
        </td>
        <td>
            {kjData[0].kjValue}
        </td>
        <td>
            <Button
                onClick={ editMealData }
                style={{ marginLeft: '.5em', marginTop: '0.25em' }}
            >Edit</Button>
            <Button
                variant="danger"
                onClick={deleteMealData}
                style={{ marginLeft: '.5em', marginTop: '0.25em' }}
            >Delete</Button>
        </td>
    </tr>);
}

MealDataTableRow.propTypes = {
    MealData: PropTypes.object.isRequired,
    editMealData: PropTypes.func.isRequired,
    deleteMealData: PropTypes.func.isRequired,
};

export default MealDataTableRow;
