import React, {Component} from 'react';
import { connect } from 'react-redux';
import AddConsumptionDataForm from '../components/addConsumptionDataForm';
import AddMealConsumptionDataForm from '../components/addMealConsumptionDataForm'
import { push } from 'connected-react-router';
import {withAuth0} from "@auth0/auth0-react";

import {
    getAllDayEvents,
} from '../actions/dayEvents';

import {
    getAllFoodTypesWithChildren,
} from '../actions/foodTypes';

import {
    getFoodTypeGroups,
} from '../actions/foodTypeGroup';

import {
    addConsumptionData,
    resetConsumptionDataSuccessState
} from '../actions/consumptionData';

import {
    addMealData,
    resetMealDataSuccessState
} from "../actions/mealsData";

import {
    Container,
    Row,
    Col, Alert
} from "react-bootstrap";
import Spinner from '../components/spinner'
import {getAllContainers} from "../actions/containers";


class AddConsumptionData extends Component {
    constructor() {
        super();
        this.state = {
            consumptionDataAddSuccess: false,
            mealDataAddSuccess: false
        };
    }

    componentDidUpdate(prevProps, prevState, nextContent) {
        console.log('pre Should show mealDataSuccess')
        if (this.props.addConsumptionDataSuccess && prevState.consumptionDataAddSuccess === false) {
            this.setState({ consumptionDataAddSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
        if (this.props.addMealDataSuccess && prevState.mealDataAddSuccess === false) {
            console.log('Should show mealDataSuccess')
            this.setState({ mealDataAddSuccess: true });
            setTimeout(() => { this.hideMessages(); }, 2000);
        }
    }

    async componentDidMount() {
        const {getAccessTokenSilently} = this.props.auth0;
        this.props.getAllDayEvents(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getAllFoodTypesWithChildren(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getFoodTypeGroups(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
        this.props.getAllContainers(await getAccessTokenSilently(), `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    async addConsumptionData(state) {
        console.log('add');
        console.log(state);
        const {getAccessTokenSilently} = this.props.auth0;
        if (this.props.isMeal == true) {
            state.mealId = this.props.mealId;
            this.props.addMealData(await getAccessTokenSilently(), state);
        } else {
            this.props.addConsumptionData(await getAccessTokenSilently(), state);
        }
    }

    hideMessages() {
        console.log("hideMessagesOne");
        this.setState({
            consumptionDataAddSuccess: false,
            mealDataAddSuccess: false
        });
        this.props.resetConsumptionDataSuccessState();
        this.props.resetMealDataSuccessState();
    }

    render() {
        let addSuccess = <div/>;
        if (this.state.consumptionDataAddSuccess || this.state.mealDataAddSuccess) {
            addSuccess = <Alert variant='success' >Add Success</Alert>;
        }

        let form = (<div>nothing</div>);
        console.log(this.props.allDayEvents);
        if (
            this.props.isFetchingAllDayEvents ||
            this.props.isFetchingAllFoodTypes ||
            this.props.isFetchingFoodTypeGroups ||
            this.props.isFetchingAllContainers
        ) {
            form = (<Spinner/>);
        } else if (
            this.props.allDayEvents &&
            this.props.allFoodTypes &&
            this.props.foodTypeGroups
        ) {
            console.log('doinkevent');
            if (this.props.isMeal == true) {
                form = <AddMealConsumptionDataForm
                    addConsumptionData={(state) => this.addConsumptionData(state)}
                    allFoodTypes={this.props.allFoodTypes}
                    foodTypeGroups={this.props.foodTypeGroups}
                    allContainers={this.props.allContainers}
                />;
            } else {
                form = <AddConsumptionDataForm
                    addConsumptionData={(state) => this.addConsumptionData(state)}
                    allDayEvents={this.props.allDayEvents}
                    allFoodTypes={this.props.allFoodTypes}
                    foodTypeGroups={this.props.foodTypeGroups}
                    allContainers={this.props.allContainers}
                />;
            }
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            { form }
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '1em' }}>
                        <Col>
                            { addSuccess }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({
                             allDayEvents,
                             allFoodTypes,
                             consumptionData,
                             mealData,
                             foodTypeGroups,
                             allContainers,
                         }, ownProps) => ({
    allDayEvents: allDayEvents.allDayEvents,
    isFetchingAllDayEvents: allDayEvents.isFetching,
    allFoodTypes: allFoodTypes.allFoodTypesChildren,
    isFetchingAllFoodTypes: allFoodTypes.isFetchingAllFoodTypesChildren,
    allContainers: allContainers.allContainers,
    isFetchingAllContainers: allContainers.isFetching,
    foodTypeGroups: foodTypeGroups.foodTypeGroups,
    isFetchingFoodTypeGroups: foodTypeGroups.isFetching,
    addConsumptionDataSuccess: consumptionData.addConsumptionDataSuccess,
    addMealDataSuccess: mealData.addMealDataSuccess
});

export default withAuth0(connect(mapStateToProps, {
    push,
    getAllDayEvents,
    getAllFoodTypesWithChildren,
    getAllContainers,
    getFoodTypeGroups,
    addConsumptionData,
    addMealData,
    resetMealDataSuccessState,
    resetConsumptionDataSuccessState,
})(AddConsumptionData));
