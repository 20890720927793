import {
    GET_MEAL_REQUEST,
    GET_MEAL_RECEIVE,
    GET_MEAL_ERROR,
    GET_MEAL_KJ_REQUEST,
    GET_MEAL_KJ_RECEIVE,
    GET_MEAL_KJ_ERROR,
    ADD_MEAL_REQUEST,
    ADD_MEAL_RECEIVE,
    ADD_MEAL_ERROR,
    UPDATE_MEAL_REQUEST,
    UPDATE_MEAL_RECEIVE,
    UPDATE_MEAL_ERROR,
    DELETE_MEAL_REQUEST,
    DELETE_MEAL_RECEIVE,
    DELETE_MEAL_ERROR,
    RESYNC_MEAL_REQUEST,
    RESYNC_MEAL_RECEIVE,
    RESYNC_MEAL_ERROR,

    MEAL_CONFIRM_RESET,
} from '../constants/ActionTypes';

const initialState = {
    isFetchingMeal: false,
    meal: null,
    errorMeal: null,

    isFetchingMealKj: false,
    mealKj: null,
    errorMealKj: null,

    isAdding: false,
    addMealSuccess: false,
    errorAddMealSuccess: null,

    isUpdating: false,
    updateMealSuccess: false,
    errorUpdateMealSuccess: null,

    isResyncing: false,
    resyncMealSuccess: false,
    errorResyncMealSuccess: null,

    isDeleting: null,
    deleteMealSuccess: false,
    errorDeleteMealSuccess: false,

    reloadMeal: false,
};

const meal = (state = initialState, { type, payload, error }) => {
    console.log("Meal-Doinka2");
    switch (type) {
        case RESYNC_MEAL_REQUEST:
            return {
                ...state,
                isResyncing: true,
            };
        case RESYNC_MEAL_RECEIVE:
            return {
                ...state,
                isResyncing: false,
                resyncMealSuccess: true,
            };
        case RESYNC_MEAL_ERROR:
            return {
                ...state,
                isResyncing: false,
                errorResyncMealSuccess: error,
            };

        case UPDATE_MEAL_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case UPDATE_MEAL_RECEIVE:
            return {
                ...state,
                isUpdating: false,
                meal: payload,
                updateMealSuccess: true,
            };
        case UPDATE_MEAL_ERROR:
            return {
                ...state,
                isUpdating: false,
                meal: null,
                error,
            };

        case DELETE_MEAL_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_MEAL_RECEIVE:
            return {
                ...state,
                isDeleting: false,
                deleteMealSuccess: true,
            };
        case DELETE_MEAL_ERROR:
            return {
                ...state,
                isDeleting: false,
                meal: null,
                errorDeleteMealSuccess: error,
            };

        case ADD_MEAL_REQUEST:
            return {
                ...state,
                isAdding: true,
            };
        case ADD_MEAL_RECEIVE:
            return {
                ...state,
                isAdding: false,
                addMealSuccess: true,
            };
        case ADD_MEAL_ERROR:
            return {
                ...state,
                isAdding: false,
                meal: null,
                errorAddMealSuccess: error,
            };

        case GET_MEAL_REQUEST:
            return {
                ...state,
                isFetchingMeal: true,
            };
        case GET_MEAL_RECEIVE:
            return {
                ...state,
                isFetchingMeal: false,
                reloadMeal: false,
                meal: payload,
            };
        case GET_MEAL_ERROR:
            return {
                ...state,
                isFetchingMeal: false,
                reloadMeal: false,
                meal: null,
                errorMeal: error,
            };

        case GET_MEAL_KJ_REQUEST:
            return {
                ...state,
                isFetchingMealKj: true,
            };
        case GET_MEAL_KJ_RECEIVE:
            return {
                ...state,
                isFetchingMealKj: false,
                mealKj: payload,
            };
        case GET_MEAL_KJ_ERROR:
            return {
                ...state,
                isFetchingMealKj: false,
                mealKj: null,
                errorMealKj: error,
            };

        case MEAL_CONFIRM_RESET:
            return {
                ...state,
                addMealSuccess: false,
                updateMealSuccess: false,
                resyncMealSuccess: false,
                deleteMealSuccess: false,
                reloadMeal: true,
            };

        default:
            return state;
    }
};

export default meal;
