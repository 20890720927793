import { createAction } from 'redux-api-middleware';

import {
    ALL_CONTAINERS_REQUEST,
    ALL_CONTAINERS_RECEIVE,
    ALL_CONTAINERS_ERROR,
    GET_CONTAINER_REQUEST,
    GET_CONTAINER_RECEIVE,
    GET_CONTAINER_ERROR,
    DELETE_CONTAINER_REQUEST,
    DELETE_CONTAINER_RECEIVE,
    DELETE_CONTAINER_ERROR,
    UPDATE_CONTAINER_REQUEST,
    UPDATE_CONTAINER_RECEIVE,
    UPDATE_CONTAINER_ERROR,
    ADD_CONTAINER_REQUEST,
    ADD_CONTAINER_RECEIVE,
    ADD_CONTAINER_ERROR,

    CONTAINER_CONFIRM_RESET,
} from '../constants/ActionTypes';


export const getAllContainers = (token, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Containers?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        ALL_CONTAINERS_REQUEST,
        ALL_CONTAINERS_RECEIVE,
        ALL_CONTAINERS_ERROR
    ]
})


export const getContainer = (token, id, timezone) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Containers/${id}?timezone=${timezone}`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    types: [
        GET_CONTAINER_REQUEST,
        GET_CONTAINER_RECEIVE,
        GET_CONTAINER_ERROR
    ]
})

export const deleteContainer = (token, id) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Containers/${id}`,
    method: 'DELETE',
    headers: {
        'Authorization': `Bearer ${token}`
    },
    types: [
        DELETE_CONTAINER_REQUEST,
        DELETE_CONTAINER_RECEIVE,
        DELETE_CONTAINER_ERROR,
    ]
});

export const updateContainer = (token, id, data) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Containers/${id}`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        UPDATE_CONTAINER_REQUEST,
        UPDATE_CONTAINER_RECEIVE,
        UPDATE_CONTAINER_ERROR,
    ]
})

export const addContainer = (token, data) => createAction({
    endpoint: `${process.env.REACT_APP_API}api/Containers`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
    types: [
        ADD_CONTAINER_REQUEST,
        ADD_CONTAINER_RECEIVE,
        ADD_CONTAINER_ERROR,
    ]
})


export const resetContainerSuccessState = () => ({
    type: CONTAINER_CONFIRM_RESET,
});