import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
    Form,
    Container,
    Row,
    Col,
    Button,
} from 'react-bootstrap';
import DropdownOption from "./DropdownOption";

import $ from 'jquery';

import {ReactBootstrapSlider} from "react-bootstrap-slider";
import Spinner from "./spinner";

class EditMealDataForm extends Component {
    constructor(props) {
        console.log("mo");
        console.log(props);

        // Load saved state or a default state when page loaded
        super(props);

        let servesEnabled = 1;
        console.log('depa1');

        this.state = {
            id: props.mealData.id,
            dateTime: props.mealData.dateTime,
            foodItemId: props.mealData.foodItemId,
            note: props.mealData.note,
            weightMg: props.mealData.weightMg,
            mealId: props.mealData.mealId,

            allFoodTypes: props.allFoodTypes,
        };
    }

    updateMealData() {
        console.log('update' + this.state.id);
        console.log(this.state);
        let tempState = {};
        tempState.id = this.state.id;
        tempState.dateTime = this.state.dateTime;
        tempState.foodItemId = this.state.foodItemId;
        tempState.note = this.state.note;
        tempState.weightMg = this.state.weightMg;
        tempState.mealId = this.state.mealId;

        this.props.updateMealData(this.state.id, tempState);
    }

    deleteMealData(id) {
        console.log('delete' + id)
        this.props.deleteMealData(id)
    }

    cancelEdit() {
        this.props.cancelEdit()
    }

    viewSize() {
        return $('#sizer').find('div:visible').data('size');
    }

    render() {
        console.log(this.state);

        let kjData = <div>nothing</div>
        if (this.props.isFetchingKjMealData) {
            kjData = (<Spinner/>);
        } else if (this.props.kjMealData) {
            kjData = (<Form.Group as={Col} sm={{ span: 5}} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="updatedAt">
                <Form.Label>Kj Value</Form.Label>
                <Form.Control
                    type="text"
                    // On update this damn string always gets extra accuracy added, debugging the C# API shows no added accuracy, have no idea :(
                    value={this.props.kjMealData.kjValue}
                    disabled
                />
            </Form.Group>);
        }

        return (
            <Container>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.updateMealData();
                    }}
                >

                    <Form.Row style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Group as={Col} sm={{ span: 5, offset: 2 }} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="createdAt">
                            <Form.Label>DateTime</Form.Label>
                            <Form.Control
                                type="text"
                                value={this.state.dateTime.substring(0, 19)}
                                disabled
                            />
                        </Form.Group>
                        { kjData }
                    </Form.Row>

                    <Form.Group as={Row}  controlId="foodType" style={{ margin: '0.2em', paddingTop: '0.5em' }}>

                        <Form.Label column sm={2}>
                            Food Type
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                as="select"
                                onChange={e => {
                                    const localState = this.state;
                                    localState.foodItemId = parseInt(e.target.value);
                                    this.setState(localState);
                                }}
                                value={this.state.foodItemId}
                            >
                                {this.props.allFoodTypes.map(
                                    (foodItem) => <DropdownOption
                                            key={foodItem.foodItemsId}
                                            value={foodItem.foodItemsId}
                                            label={foodItem.name}
                                        />
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="weightMg" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Weight/mg
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                defaultValue={this.state.weightMg}
                                onChange={e => {
                                    // TODO add some checking around types!!!
                                    this.setState({ weightMg: parseInt(e.target.value) });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="note" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Note
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                as="textarea"
                                rows="5"
                                defaultValue={this.state.note}
                                onChange={e => {
                                    this.setState({ note: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} style={{ margin: '0.2em', paddingTop: '2em' }}>
                        <Col
                            lg={{ span: 4, offset: 2 }}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Button type="submit">Update</Button>
                            <Button
                                variant="danger"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.deleteMealData(this.state.id);
                                    console.log('delete');
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="secondary"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.cancelEdit();
                                    console.log('return');
                                }}
                            >
                                Return
                            </Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

EditMealDataForm.propTypes = {
    mealData: PropTypes.object.isRequired,
    deleteMealData: PropTypes.func.isRequired,
    updateMealData: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
}

export default (EditMealDataForm);