import React, { Component } from 'react';
import PropTypes from 'prop-types'

import DatePicker from "react-datepicker";

// <3 thanks for saving me from the horrors of UTC
import { format } from "date-fns";

import {
    Form,
    Container,
    Row,
    Col,
    Button,
} from 'react-bootstrap';
import DropdownOption from "./DropdownOption";

import $ from 'jquery';

import {ReactBootstrapSlider} from "react-bootstrap-slider";
import Spinner from "./spinner";

class EditConsumptionDataForm extends Component {
    constructor(props) {
        console.log("mo");
        console.log(props);

        // Load saved state or a default state when page loaded
        super(props);

        let servesEnabled = 1;
        console.log('depa1');
        console.log(props.consumptionData.serves);
        if (props.consumptionData.serves === null) {
            console.log('depa2');
            servesEnabled = 0;
        }

        this.state = {
            id: props.consumptionData.id,
            dateTime: props.consumptionData.dateTime,
            dayEventId: props.consumptionData.dayEventId,
            foodItemId: props.consumptionData.foodItemId,
            note: props.consumptionData.note,
            serves: props.consumptionData.serves,
            weightMg: props.consumptionData.weightMg,

            ServesEnabled: servesEnabled,
            allFoodTypes: props.allFoodTypes,
            allDayEvents: props.allDayEvents
        };
    }

    updateConsumptionData() {
        console.log('update' + this.state.id);
        console.log(this.state);
        let tempState = {};
        tempState.id = this.state.id;
        tempState.dateTime = format(this.state.dateTime, "yyyy-MM-dd HH:mm:ss");
        tempState.dayEventId = this.state.dayEventId;
        tempState.foodItemId = this.state.foodItemId;
        tempState.note = this.state.note;
        tempState.serves = this.state.serves;
        tempState.weightMg = this.state.weightMg;

        if (this.state.ServesEnabled === 0) {
            console.log('moo1');
            tempState.serves = null;
        } else {
            console.log('moo2');
            tempState.weightMg = null;
        }
        // Grrrr UTC unhappy
        this.props.updateConsumptionData(this.state.id, tempState, `${process.env.REACT_APP_DEFAULT_TIMEZONE}`);
    }

    deleteConsumptionData(id) {
        console.log('delete' + id)
        this.props.deleteConsumptionData(id)
    }

    cancelEdit() {
        this.props.cancelEdit()
    }

    viewSize() {
        return $('#sizer').find('div:visible').data('size');
    }

    render() {
        console.log(this.state);

        let kjData = <div>nothing</div>
        if (this.props.isFetchingKjConsumptionData) {
            kjData = (<Spinner/>);
        } else if (this.props.kjConsumptionData) {
            kjData = (<Form.Group as={Col} sm={{ span: 5}} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="updatedAt">
                <Form.Label>Kj Value</Form.Label>
                <Form.Control
                    type="text"
                    // On update this damn string always gets extra accuracy added, debugging the C# API shows no added accuracy, have no idea :(
                    value={this.props.kjConsumptionData.kjValue}
                    disabled
                />
            </Form.Group>);
        }

        return (
            <Container>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.updateConsumptionData();
                    }}
                >

                    <Form.Row style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Group as={Col} sm={{ span: 5, offset: 2 }} style={{ paddingLeft: '15px', paddingRight: '15px' }} controlId="createdAt">
                            <Form.Label>DateTime</Form.Label>
                            <DatePicker
                                className="form-control"
                                selected={new Date(this.state.dateTime)}
                                onChange={date => {
                                    console.log(date)
                                    const localState = this.state;
                                    localState.dateTime = date;
                                    this.setState(localState);
                                }}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeInputLabel="Time:"
                                showTimeInput
                            />
                        </Form.Group>
                        { kjData }
                    </Form.Row>

                    <Form.Group as={Row}  controlId="foodType" style={{ margin: '0.2em', paddingTop: '0.5em' }}>

                        <Form.Label column sm={2}>
                            Food Type
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                as="select"
                                onChange={e => {
                                    const localState = this.state;
                                    localState.foodItemId = parseInt(e.target.value);
                                    this.setState(localState);
                                }}
                                value={this.state.foodItemId}
                            >
                                {this.props.allFoodTypes.map(
                                    (foodItem) => <DropdownOption
                                            key={foodItem.foodItemsId}
                                            value={foodItem.foodItemsId}
                                            label={foodItem.name}
                                        />
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}  controlId="dayEvent" style={{ margin: '0.2em', paddingTop: '0.5em' }}>

                        <Form.Label column sm={2}>
                            Day Event
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                as="select"
                                onChange={e => {
                                    const localState = this.state;
                                    localState.dayEventId = parseInt(e.target.value);
                                    this.setState(localState);
                                }}
                                value={this.state.dayEventId}
                            >
                                {this.props.allDayEvents.map(
                                    (dayEvent) =>
                                        <DropdownOption
                                            key={dayEvent.id}
                                            value={dayEvent.id}
                                            label={dayEvent.name}
                                        />
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="ServesEnabled" style={{ margin: '0.2em', paddingTop: '1em' }}>
                        <Form.Label column sm={2} xs={0}/>
                        <Form.Label column sm={3} xs={4} style={{ textAlign: 'center' }}>
                            Weight (gm)
                        </Form.Label>
                        <Col sm={4} xs={4}>
                            <ReactBootstrapSlider
                                style={{ width: '100%' }}
                                value={this.state.ServesEnabled}
                                max={1}
                                step={1}
                                min={0}
                                tooltip="hide"
                                slideStop={e => {
                                    this.setState({ ServesEnabled: parseInt(e.target.value) });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                        <Form.Label column sm={3} xs={4} style={{ textAlign: 'center' }}>
                            Serves
                        </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} controlId="serves" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Serves
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                disabled = { !this.state.ServesEnabled }
                                type="text"
                                defaultValue={this.state.serves}
                                onChange={e => {
                                    // TODO add some checking around types!!!
                                    this.setState({ serves: parseInt(e.target.value) });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="weightMg" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Weight/mg
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                disabled = { !!this.state.ServesEnabled }
                                type="text"
                                defaultValue={this.state.weightMg}
                                onChange={e => {
                                    // TODO add some checking around types!!!
                                    this.setState({ weightMg: parseInt(e.target.value) });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="note" style={{ margin: '0.2em', paddingTop: '0.5em' }}>
                        <Form.Label column sm={2}>
                            Note
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                as="textarea"
                                rows="5"
                                defaultValue={this.state.note}
                                onChange={e => {
                                    this.setState({ note: e.target.value });
                                    console.log(e.target.value);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} style={{ margin: '0.2em', paddingTop: '2em' }}>
                        <Col
                            lg={{ span: 4, offset: 2 }}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Button type="submit">Update</Button>
                            <Button
                                variant="danger"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.deleteConsumptionData(this.state.id);
                                    console.log('delete');
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="secondary"
                                style={{ marginLeft: '1em' }}
                                onClick={e => {
                                    this.cancelEdit();
                                    console.log('return');
                                }}
                            >
                                Return
                            </Button>
                        </Col>
                        {/* This is pretty cool :)  Will detect and conditionally render the form style, won't detect changes in width however */}
                        <Col
                            lg={6}
                            md={12}
                            sm={12}
                            xs={12}

                            style={{ paddingTop: (this.viewSize() === "xs" || this.viewSize() === "sm" || this.viewSize() === "md") ? '2em' : '.25em' }}>
                            <span style={{ color: 'red', fontWeight: 'bold' }}> Note</span>: Disabled field will be discarded by server on update (Serves vs Weight/mg)
                        </Col>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

EditConsumptionDataForm.propTypes = {
    consumptionData: PropTypes.object.isRequired,
    deleteConsumptionData: PropTypes.func.isRequired,
    updateConsumptionData: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
}

export default (EditConsumptionDataForm);