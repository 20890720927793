import React from "react";
import { Route, Switch } from "react-router";
import { useAuth0 } from '@auth0/auth0-react';

import Header from '../components/header';
import Home from './Home';
import Moo1 from './Moo1';
import NotFound from '../components/not-found';
import EditFoodItem from "./EditFoodItem";
import AddFoodItem from "./AddFoodItem";
import DayLogs from "./DayLogs";
import DayEvents from "./DayEvents";
import Containers from "./Containers"
import AddDayEvent from "./AddDayEvent";
import EditDayEvent from "./EditDayEvent";

import AddContainer from "./AddContainer";
import EditContainer from "./EditContainer";
import EditConsumptionData from "./EditConsumptionData";
import FoodTypeGroups from "./FoodTypeGroups";
import {Col, Container, Row} from "react-bootstrap";
import AddFoodTypeGroup from "./AddFoodTypeGroup";
import EditFoodTypeGroup from "./EditFoodTypeGroup";
import UserSettings from "./UserSettings";
import Meals from "./Meals";
import Meal from "./Meal";
import AddMeal from "./AddMeal";
import EditMeal from "./EditMeal";
import EditMealData from "./EditMealData";
import MealMigrate from "./MealMigrate";

const App = () => {
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        logout,
    } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    console.log(user);

    let content = <div />
    if (isAuthenticated) {
        content = (<Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/foodItems/add" component={AddFoodItem}/>
            <Route path="/foodItems/edit/:id" component={EditFoodItem}/>
            <Route path="/foodItems" component={Moo1}/>
            <Route path="/meal/migrate/:id" component={MealMigrate}/>
            <Route path="/meal/:id" component={Meal}/>
            <Route path="/mealData/edit/:id" component={EditMealData}/>
            <Route path="/meals/add" component={AddMeal}/>
            <Route path="/meals/edit/:id" component={EditMeal}/>
            <Route path="/meals" component={Meals}/>

            <Route path="/containers/add" component={AddContainer}/>
            <Route path="/containers/edit/:id" component={EditContainer}/>
            <Route path="/containers" component={Containers}/>
            <Route path="/dayLogs" component={DayLogs}/>
            <Route path="/consumptionData/edit/:id" component={EditConsumptionData}/>
            <Route path="/dayEvents/add" component={AddDayEvent}/>
            <Route path="/dayEvents/edit/:id" component={EditDayEvent}/>
            <Route path="/dayEvents" component={DayEvents}/>
            <Route path="/foodTypeGroups/add" component={AddFoodTypeGroup}/>
            <Route path="/foodTypeGroups/edit/:foodTypeGroupId" component={EditFoodTypeGroup}/>
            <Route path="/foodTypeGroups" component={FoodTypeGroups}/>
            <Route path="/userSettings" component={UserSettings}/>
            <Route path="*" component={NotFound}/>
        </Switch>);
    } else {
        content = (<Container style={{ paddingBottom: '5em' }}>
            <Row style={{ paddingTop: '2em' }}>
                <Col>
                    <h3>Track eating with PONY!!</h3>
                    <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/logo512.png`}
                    height="auto"
                    className="d-inline-block align-top"
                    style={{ width: '100%', maxWidth: '512px' }}
                /></Col>
            </Row>
            <Row style={{ paddingTop: '2em' }}>
                <Col sm={12}>
                    <h2>Kj Pony Tech Specs / Updates</h2>
                    <div style={{ paddingTop: '2em' }}>
                        <ul>
                            <li>C# .Net API Backend</li>
                            <li>ReactJs / Redux Frontend</li>
                            <li>MySQL 8 Database</li>
                        </ul>
                    </div>
                </Col>

            </Row>
        </Container>)
    }

    return (
        <div>
            <Header
                logout={() => logout({ returnTo: window.location.origin })}
                login={ loginWithRedirect }
                loggedIn={ isAuthenticated }
                userImage={ user.picture }
            />
            { content }
        </div>);
}

export default App;